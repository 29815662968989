import { createAction } from 'deox';
import {
  MOBILE_SET_MOBILE_LIVECHAT,
  TOGGLE_BOTTOM_NAVIGATION,
  TOGGLE_MOBILE_MENU,
  TOGGLE_MOBILE_PROVIDER,
  TOGGLE_MOBILE_SEARCH,
} from './types';

export const toggleMobileMenu = createAction(TOGGLE_MOBILE_MENU);

export const toggleMobileSearch = createAction(TOGGLE_MOBILE_SEARCH);

export const toggleMobileProvider = createAction(TOGGLE_MOBILE_PROVIDER);

export const toggleMobileBottomNavigation = createAction(TOGGLE_BOTTOM_NAVIGATION);

export const setMobileLiveChatVisibility = createAction(MOBILE_SET_MOBILE_LIVECHAT, (res) => (params) => res(params));
