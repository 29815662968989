import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
  };
};
const ProviderBlock: FC<Props> = ({ settings }) => {
  const { styles } = settings;

  const { customI18n } = useSelector((state: RootState) => state.app);

  return (
    <Typography className="label providerBlock_wrapper" variant={'p'} style={styles}>
      {customI18n?.businessLegalInfo || 'BUSINESS_LEGAL_INFO'}
    </Typography>
  );
};
export default ProviderBlock;
