// TO DO remove when all media resurse move from storage to local
const path = process.env.REACT_APP_MEDIA_ASSETS?.startsWith('https')
  ? process.env.REACT_APP_MEDIA_ASSETS +
    (process as any).env.REACT_APP_WEBSITENAME.charAt(0).toLowerCase() +
    (process as any).env.REACT_APP_WEBSITENAME.slice(1)
  : process.env.REACT_APP_MEDIA_ASSETS;

const MediaManager = {
  getSrcFromMediaAssets: (src: string, section: string): string => {
    return path + section + src;
  },
  getSrcFromGeneralMediaAssets: (src: string, section: string): string => {
    return process.env.REACT_APP_GENERAL_MEDIA_ASSETS + section + src;
  },
  getSrcFromLocaleFolder: (src: string, section: string): string => {
    return '/images' + section + src;
  },
};
export { MediaManager };
