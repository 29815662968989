import cn from 'classnames';
import { FC } from 'react';
import './styles.scss';

type Props = {
  imgUrl: string;
  name: string;
  paymentItem: PaymentMethod;
  selectedPaymentID: any;
  handlePaymentTabClick: (payment: any) => void;
};
const TabItemTemplate2: FC<Props> = ({ imgUrl, name, paymentItem, selectedPaymentID, handlePaymentTabClick }) => {
  return (
    <div
      onClick={() => handlePaymentTabClick(paymentItem.id)}
      className={cn('tabs_section__item_2', {
        ['selected']: selectedPaymentID === paymentItem.id,
      })}
    >
      <div className="tab_inner_content">
        <img src={imgUrl} alt={name} className="tab_image" />
      </div>
      <div className="triangle"></div>
    </div>
  );
};
export default TabItemTemplate2;
