import footer_desktop from 'src/mock/footer_desktop.json';
import footer_mobile from 'src/mock/footer_mobile.json';
import footer_tablet from 'src/mock/footer_tablet.json';
import general from 'src/mock/general.json';
import header_desktop from 'src/mock/header_desktop.json';
import header_mobile from 'src/mock/header_mobile.json';
import header_tablet from 'src/mock/header_tablet.json';
import mobile_fixed_menu from 'src/mock/mobile_fixed_menu.json';
import navigation from 'src/mock/navigation.json';
import pages_desktop from 'src/mock/pages_desktop.json';
import pages_mobile from 'src/mock/pages_mobile.json';
import pages_tablet from 'src/mock/pages_tablet.json';
import palette from 'src/mock/palette.json';
import topCategories from 'src/mock/topCategories.json';
import user_settings from 'src/mock/user_settings.json';
import { AuthType, MobileAppType, SocialType } from 'src/types/res-dto/configs';
import { HttpClient } from './http';

const getHeaderConfigs = (device: string): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        switch (device) {
          case 'mobile':
            res(header_mobile);
            break;
          case 'tablet':
            res(header_tablet);
            break;
          default:
            res(header_desktop);
        }
      }, 0);
    });
  }
  if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
    return HttpClient.get(`/mock/header_${device}.json`, false);
  }

  return HttpClient.get({ path: '/lobbyV2/getHeaderConfigs', baseURL: process.env.REACT_APP_REMOTE as string }, false, {
    skinId: process.env.REACT_APP_STRUCTURE_ID,
    device: device,
  });
};

const getFooterConfigs = (device: string): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        switch (device) {
          case 'mobile':
            res(footer_mobile);
            break;
          case 'tablet':
            res(footer_tablet);
            break;
          default:
            res(footer_desktop);
        }
      }, 0);
    });
  }
  if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
    return HttpClient.get(`/mock/footer_${device}.json`, false);
  }

  return HttpClient.get({ path: '/lobbyV2/getFooterConfigs', baseURL: process.env.REACT_APP_REMOTE as string }, false, {
    skinId: process.env.REACT_APP_STRUCTURE_ID,
    device: device,
  });
};

const getPageConfigs = (device: string): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        switch (device) {
          case 'mobile':
            res(pages_mobile);
            break;
          case 'tablet':
            res(pages_tablet);
            break;
          default:
            res(pages_desktop);
        }
      }, 0);
    });
  }
  if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
    return HttpClient.get(`/mock/pages_${device}.json`, false);
  }

  return HttpClient.get({ path: '/lobbyV2/getPageConfigs', baseURL: process.env.REACT_APP_REMOTE as string }, false, {
    skinId: process.env.REACT_APP_STRUCTURE_ID,
  });
};

const getGeneralConfigs = (): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        res(general);
      }, 0);
    });
  }
  if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
    return HttpClient.get(`/mock/general.json`, false);
  }

  return HttpClient.get(
    { path: '/lobbyV2/getGeneralConfigs', baseURL: process.env.REACT_APP_REMOTE as string },
    false,
    {
      skinId: process.env.REACT_APP_STRUCTURE_ID,
    }
  );
};

const getMobileAppsData = (): Promise<MobileAppType[]> => {
  return HttpClient.get(`/mock/mobile/downloadApp.json`);
};

// not used now used socials from strapi
const getSocials = (): Promise<SocialType[]> => {
  if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
    return HttpClient.get(`/mock/social_icons.json`, false);
  }

  return HttpClient.get({ path: `/lobbyV2/getSocialIcons`, baseURL: process.env.REACT_APP_REMOTE as string }, false, {
    skinId: process.env.REACT_APP_STRUCTURE_ID,
  });
};

const getAuth = (): Promise<{ result: AuthType; success: boolean }> => {
  return HttpClient.get({ path: `/lobbyV2/getAuth`, baseURL: process.env.REACT_APP_REMOTE as string }, false, {
    skinId: process.env.REACT_APP_STRUCTURE_ID,
  });
};

const getMobileFixedMenuData = (): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        res(mobile_fixed_menu);
      }, 0);
    });
  }
  if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
    return HttpClient.get(`/mock/mobile_fixed_menu.json`, false);
  }

  return HttpClient.get(
    { path: `/lobbyV2/getMobileFixedMenu`, baseURL: process.env.REACT_APP_REMOTE as string },
    false,
    {
      skinId: process.env.REACT_APP_STRUCTURE_ID,
    }
  );
};

const getDepositConfigs = (): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        res(user_settings);
      }, 0);
    });
  }
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return HttpClient.get(`/mock/user_settings.json`, false);
  }

  return HttpClient.get({ path: '/lobbyV2/getUserSettings', baseURL: process.env.REACT_APP_REMOTE as string }, false, {
    skinId: process.env.REACT_APP_STRUCTURE_ID,
  });
};

const getNavigation = (): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        res(navigation);
      }, 0);
    });
  }
  return HttpClient.get(`/mock/navigation.json`, false);
};

const getPallete = (theme?: string): any => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        res(palette);
      }, 0);
    });
  }
  if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
    return HttpClient.get(`/mock/palette.json`, false);
  }

  return HttpClient.get(
    { path: '/lobbyV2/getPaletteConfigs', baseURL: process.env.REACT_APP_REMOTE as string },
    false,
    {
      skinId: process.env.REACT_APP_STRUCTURE_ID,
      theme: theme ?? 'dark',
    }
  );
};

// for Slotoyna
const getTopCategories = (): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_IMPORT) {
    return new Promise((res) => {
      setTimeout(() => {
        res(topCategories);
      }, 0);
    });
  }
  return HttpClient.get(`/mock/topCategories.json`, false);
};

const getSideBarNavigationConfigs = (device: string): Promise<any> => {
  if (process.env.REACT_APP_CONFIGS_MODE === 'MOCK') {
    return HttpClient.get(`/mock/sidebar_${device}.json`);
  }

  return HttpClient.get(
    { path: '/lobbyV2/sideBarNavigation', baseURL: process.env.REACT_APP_REMOTE as string },
    false,
    {
      skinId: process.env.REACT_APP_STRUCTURE_ID,
      device: device,
    }
  );
};

export {
  getSideBarNavigationConfigs,
  getSocials,
  getAuth,
  getDepositConfigs,
  getFooterConfigs,
  getGeneralConfigs,
  getHeaderConfigs,
  getPageConfigs,
  getMobileAppsData,
  getMobileFixedMenuData,
  getPallete,
  getNavigation,
  getTopCategories,
};
