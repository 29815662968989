import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { SvgIcon } from 'src/components/common/SvgIcon';
import { RootState } from 'src/types/store-types';
import { MediaManager } from '../../../manager/MediaManager';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    imageSrc: string;
    type: string;
    link: string;
    styles: Styles;
    website?: string;
  };
};
const Licence: FC<Props> = ({ settings }) => {
  const { styles, type, imageSrc, link, website } = settings;

  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const anjuanLicenceMap: any = {
    papi: (window as any).anj_adced492_6a7a_435f_af36_b9c13de86588,
    no2bet: (window as any).anj_b656a736_62d7_4794_bcbe_ed94d6d9a5b6,
    pr2bet: (window as any).anj_1aa17b11_67fb_415d_a5aa_65c4bda0c9bc,
    foursevenbet: (window as any).anj_4b17f86e_254a_4a19_8f91_48512c69fe4a,
    gameshowbet: (window as any).anj_68057a70_d015_4d0f_ac0b_fdf45a3cf461,
    psychobets: (window as any).anj_a17a2043_05e1_47a8_a51e_bce8e4411df9,
    heavymetal: (window as any).anj_a80d1bc9_3514_4420_b346_99852eab75e4,
    blinder: (window as any).anj_015653f5_c973_49b5_a37a_630d0c8a8a3b,
  };

  useEffect(() => {
    if (type === 'anjuan' && website) {
      anjuanLicenceMap[website]?.init();
    }
  }, [type, website]);

  const licenceComonent = useMemo(() => {
    if (type === 'iframe') {
      return <iframe title="licence" src={generalConfigs?.gambleLicence ?? ''} style={styles}></iframe>;
    }

    if (type === 'image') {
      return <img src={'/images/footer/licence.svg'} className="image_licence" />;
    }

    if (type === 'curacaoIcon') {
      return (
        <a style={styles} href={link} className="curacao_icon" aria-label="Learn more about Curacao">
          <img src={MediaManager.getSrcFromMediaAssets(imageSrc, '/')} alt="Curacao Icon" />
        </a>
      );
    }

    if (type === 'anjuan') {
      switch (website) {
        case 'papi':
          return (
            <div
              id="anj-adced492-6a7a-435f-af36-b9c13de86588"
              data-anj-seal-id="adced492-6a7a-435f-af36-b9c13de86588"
              data-anj-image-size="60"
              data-anj-image-type="basic-small"
            ></div>
          );
        case 'no2bet':
          return (
            <div
              id="anj-b656a736-62d7-4794-bcbe-ed94d6d9a5b6"
              data-anj-seal-id="b656a736-62d7-4794-bcbe-ed94d6d9a5b6"
              data-anj-image-size="60"
              data-anj-image-type="basic-small"
            ></div>
          );
        case 'pr2bet':
          return (
            <div
              id="anj-1aa17b11-67fb-415d-a5aa-65c4bda0c9bc"
              data-anj-seal-id="1aa17b11-67fb-415d-a5aa-65c4bda0c9bc"
              data-anj-image-size="60"
              data-anj-image-type="basic-small"
            ></div>
          );
        case 'foursevenbet':
          return (
            <div
              id="anj-4b17f86e-254a-4a19-8f91-48512c69fe4a"
              data-anj-seal-id="4b17f86e-254a-4a19-8f91-48512c69fe4a"
              data-anj-image-size="60"
              data-anj-image-type="basic-small"
            ></div>
          );
        case 'gameshowbet':
          return (
            <div
              id="anj-68057a70-d015-4d0f-ac0b-fdf45a3cf461"
              data-anj-seal-id="68057a70-d015-4d0f-ac0b-fdf45a3cf461"
              data-anj-image-size="60"
              data-anj-image-type="basic-small"
            ></div>
          );
        case 'psychobets':
          return (
            <div
              id="anj-a17a2043-05e1-47a8-a51e-bce8e4411df9"
              data-anj-seal-id="a17a2043-05e1-47a8-a51e-bce8e4411df9"
              data-anj-image-size="60"
              data-anj-image-type="basic-small"
            ></div>
          );
        case 'heavymetal':
          return (
            <div
              id="anj-a80d1bc9-3514-4420-b346-99852eab75e4"
              data-anj-seal-id="a80d1bc9-3514-4420-b346-99852eab75e4"
              data-anj-image-size="60"
              data-anj-image-type="basic-small"
            ></div>
          );
        case 'blinder':
          return (
            <div
              id="anj-015653f5-c973-49b5-a37a-630d0c8a8a3b"
              data-anj-seal-id="015653f5-c973-49b5-a37a-630d0c8a8a3b"
              data-anj-image-size="128"
              data-anj-image-type="basic-small"
            ></div>
          );
        default:
          return null;
      }
    }

    return (
      <a
        style={styles}
        href={generalConfigs?.gambleLicence ?? ''}
        target="_blank"
        aria-label="Learn more about Curacao"
      >
        <img src={imageSrc || '/images/license.png'} alt="Licence" />
      </a>
    );
  }, [settings]);

  return <div className={'licence_wrapper'}>{licenceComonent}</div>;
};
export default Licence;
