import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import './styles.scss';

type Props = {
  email?: string;
  dialogCase: string;
  isChangePassword?: boolean;
};
const ResetPasswordSuccess: FC<Props> = ({ dialogCase, email, isChangePassword }) => {
  const { t }: Translation = useTranslation();

  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    const dialogType = isChangePassword
      ? EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS
      : EnumDialogsKeys.RESET_PASSWORD_SUCCESS;
    _closeDialog({ dialogType });
  };

  return (
    <PopUpContainer
      isOpen
      handleClose={removeDialog}
      handleOK={removeDialog}
      hasOverlay={false}
      title={t(`resetPasswordSuccessDialog.${dialogCase}.title`)}
      description={t(`resetPasswordSuccessDialog.${dialogCase}.description`, { email })}
      type="default"
      zIndex={160}
    />
  );
};

export default ResetPasswordSuccess;
