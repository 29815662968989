import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setSelectedCategory } from 'src/store/app/actions';
import { useConnectedAction } from './use-connected-action';

const useGbMessages = (): void => {
  const navigate = useNavigate();

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  // const closeGameBox = (): void => {
  //   window.parent.postMessage({ type: 'CLOSE_GAME_BOX_GLOBAL_WIDGET' }, '*');
  //   window.parent.postMessage({ type: 'CLOSE_GAME_BOX' }, '*');
  // };

  const NAVIGATION_MAP: any = {
    spinAndGoStarted: () => {
      // closeGameBox();
      _setSelectedCategory('casinoTournament');
      navigate('/category/casinoTournament');
    },
    goToLobby: () => {
      // closeGameBox();
      _setSelectedCategory('home');
      navigate('/');
    },
    goToGos: () => {
      // closeGameBox();
      _setSelectedCategory('casinoTournament');
      navigate('/category/casinoTournament');
    },
  };
  useEffect(() => {
    (window as any).gdMessagesNavigateTo = (path: any) => {
      if (NAVIGATION_MAP[path]) {
        NAVIGATION_MAP[path]();
      }
    };
  }, []);
};

export default useGbMessages;
