import { useEffect, useState } from 'react';

type ScrollData = {
  onScroll: (node: any) => void;
  showStart: boolean;
  showEnd: boolean;
};
export const useScrollWithShadow = (node: any, dependencies: string[] = []): ScrollData => {
  const [showStart, setShowStart] = useState<boolean>(false);
  const [showEnd, setShowEnd] = useState<boolean>(false);

  const onScroll = (node: any): void => {
    const { scrollWidth = 0, scrollLeft = 0, offsetWidth = 0 } = node || {};

    setShowStart(scrollLeft > 0);
    setShowEnd(scrollLeft + offsetWidth + 1 < scrollWidth);
  };

  useEffect(() => {
    if (node) {
      onScroll(node);
    }
  }, [node, dependencies]);

  return { onScroll, showStart, showEnd };
};
