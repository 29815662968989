import { CatgoriesData, NavigationItem } from 'src/types/res-dto/categories-res';

export type configsState = {
  generalConfigs: any;
  pageConfigs: any;
  headerSettings: any;
  palette: string;
  categoriesData: CatgoriesData;
  navigation: NavigationItem[];
  tagsMetaInfo: [];
  pageTagsPreviews: [];
  bottomNavigation: any[];
  headerTemplate: string;
};

export const CONFIGS_SET_GENERAL_CONFIGS_REQ = 'configs/CONFIGS_SET_GENERAL_CONFIGS_REQ';
export const CONFIGS_SET_GENERAL_CONFIGS_SUCCESS = 'configs/CONFIGS_SET_GENERAL_CONFIGS_SUCCESS';
export const CONFIGS_SET_GENERAL_CONFIGS_FAIL = 'configs/CONFIGS_SET_GENERAL_CONFIGS_FAIL';

export const CONFIGS_SET_PAGE_CONFIGS_REQ = 'configs/CONFIGS_SET_PAGE_CONFIGS_REQ';
export const CONFIGS_SET_PAGE_CONFIGS_SUCCESS = 'configs/CONFIGS_SET_PAGE_CONFIGS_SUCCESS';
export const CONFIGS_SET_PAGE_CONFIGS_FAIL = 'configs/CONFIGS_SET_PAGE_CONFIGS_FAIL';

export const CONFIGS_SET_PALETTE_REQ = 'configs/CONFIGS_SET_PALETTE_REQ';
export const CONFIGS_SET_PALETTE_SUCCESS = 'configs/CONFIGS_SET_PALETTE_SUCCESS';
export const CONFIGS_SET_PALETTE_FAIL = 'configs/CONFIGS_SET_PALETTE_FAIL';

export const CONFIGS_SET_SETTINGS_REQ = 'configs/CONFIGS_SET_SETTINGS_REQ';
export const CONFIGS_SET_SETTINGS_SUCCESS = 'configs/CONFIGS_SET_SETTINGS_SUCCESS';
export const CONFIGS_SET_SETTINGS_FAIL = 'configs/CONFIGS_SET_SETTINGS_FAIL';

export const CONFIGS_SET_CATEGORIES_DATA_REQ = 'configs/CONFIGS_SET_CATEGORIES_DATA_REQ';
export const CONFIGS_SET_CATEGORIES_DATA_SUCCESS = 'configs/CONFIGS_SET_CATEGORIES_DATA_SUCCESS';
export const CONFIGS_SET_CATEGORIES_DATA_FAIL = 'configs/CONFIGS_SET_CATEGORIES_DATA_FAIL';

export const CONFIGS_SET_BOTTOM_NAVIGATION = 'configs/CONFIGS_SET_BOTTOM_NAVIGATION';
export const CONFIGS_SET_HEADER = 'configs/CONFIGS_SET_HEADER';
