import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { getSkinId } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useDebounce } from 'src/hooks/use-debounce';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { validatationDNIRequest } from 'src/services/authorizationApi';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import RadioInput from '../RadioInput';
import { Typography } from '../Typography';

const INIT_VALUES = { dni: '', idMainIssue: '', gender: 'male' };
const INIT_ERRORS = { dni: '', idMainIssue: '', gender: '' };

type IProps = {
  name: string;
  value: any;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required: boolean;
  idMainIssueValue: string;
  dniValue: string;
};
const DNI_Input: FC<IProps> = (props) => {
  // Hooks
  const { t }: Translation = useTranslation();
  const skinId = getSkinId();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // States
  const [values, setValues] = useState<any>(INIT_VALUES);
  const [errors, setErrors] = useState<any>(INIT_ERRORS);
  const [dniLoading, setDnaLoading] = useState<boolean>(false);

  const debouncedDni: any = useDebounce(values.dni, 500);
  const debouncedIdMainIssue: any = useDebounce(values.idMainIssue, 500);

  // Constants
  const dniRegExp = useMemo(() => {
    return props.regexp ? new RegExp(props.regexp) : new RegExp('^[0-9+]*$');
  }, [props.regexp]);

  // API Calls
  const validateDniReq = (payload: any): void => {
    setDnaLoading(true);
    validatationDNIRequest(payload)
      .then((res) => {
        if (res.success) {
          reSetGlobalError();
          props.onChange({ target: { name: 'dni', value: values } });
        } else {
          setGlobalError('dni', t('invalid_national_id'));
        }
      })
      .catch(() => {
        setGlobalError('dni', t('invalid_national_id'));
      })
      .finally(() => {
        setDnaLoading(false);
      });
  };

  // YUP Validation
  const validationSchema = Yup.object().shape({
    dni: Yup.string().required(t('requiredField')).matches(dniRegExp, t('invalid_dni')),
    idMainIssue: Yup.string().required(t('requiredField')),
    gender: Yup.string().required(t('requiredField')),
  });

  const handleValidate = async (key: string, value: string): Promise<void> => {
    try {
      await validationSchema.validateAt(key, { [key]: value });
      resetError(key);
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        setGlobalError(key, error.errors[0]);
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (props.value) {
      props.onChange({ target: { name: 'dni', value: '' } });
    }
    reSetGlobalError();
    setValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRadioChange = (e: any): void => {
    if (props.value) {
      props.onChange({ target: { name: 'dni', value: '' } });
    }
    reSetGlobalError();
    setValues((prev: any) => ({ ...prev, gender: e.target.value }));
    handleValidate('gender', e.target.value);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    handleValidate(e.target.name, e.target.value);
  };

  const setGlobalError = (key: string, message: string): void => {
    _setErrorReq({ ...signUpErrors, dni: message });
    setErrors((prev: any) => ({ ...prev, [key]: message }));
  };

  const reSetGlobalError = (): void => {
    setErrors(INIT_ERRORS);
    resetSignUpError('dni');
  };

  const resetError = (key: string): void => {
    const newErrors = errors;
    delete newErrors[key];
    setErrors(newErrors);
  };

  // Effects
  useEffect(() => {
    if (
      values.dni &&
      values.gender &&
      values.idMainIssue &&
      !Object.keys(errors).find((el: string) => errors[el]) &&
      !props.value
    ) {
      const payload = {
        nationalId: Number(values.dni),
        skinId: skinId,
        gender: values.gender === 'male' ? 'M' : 'F',
        idMainIssue: Number(values.idMainIssue),
        nationalIdType: 'DNI',
      };
      validateDniReq(payload);
    }
  }, [debouncedDni, debouncedIdMainIssue, values.gender]);

  useEffect(() => {
    if (props?.value && Object?.keys(props?.value)?.length) {
      setValues({ dni: props.value?.dni, gender: props.value?.gender, idMainIssue: props.value?.idMainIssue });
    }
  }, [props.value]);

  return (
    <>
      <Input
        error={errors.dni}
        {...props}
        value={values.dni}
        onChange={handleOnChange}
        onBlur={handleBlur}
        label="dni_input_label"
        icon={'spinner'}
        iconPosition="right"
        isLoading={dniLoading}
        disabled={dniLoading}
      />
      <div className="gender_select__wrapper" tabIndex={0}>
        <Typography className="label" variant="body5">
          {t('gender_label')} <span> *</span>
        </Typography>
        <RadioInput
          onChange={handleRadioChange}
          required
          data={['male', 'female']}
          value={values.gender}
          disabled={dniLoading}
        />
      </div>
      <Input
        name="idMainIssue"
        value={values.idMainIssue}
        error={errors.idMainIssue}
        required
        type="number"
        onChange={handleOnChange}
        onBlur={handleBlur}
        label="id_main_issue_input_label"
        disabled={dniLoading}
      />
    </>
  );
};
export default DNI_Input;
