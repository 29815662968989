import * as Yup from 'yup';

export const getChangePasswordFormValidation = (
  t: (arg: string) => string,
  passwordREGEXP: string
): Yup.ObjectSchema<Record<string, any>> => {
  const fieldRequiredError = t('allFieldsAreRequired');
  const lengthAndSymbolsError = t('passwordMustContainBothLettersAndDigits');
  const passwordsDontMatch = t('itDoesntMatchWithNewPassword');
  const newPasswordShouldBeDifferent = t('theNewPasswordShouldBeDifferent');

  return Yup.object().shape({
    oldPassword: Yup.string().required(fieldRequiredError),
    newPassword: Yup.string()
      .required(fieldRequiredError)
      .test('passwordCheck', lengthAndSymbolsError, (val = '') => new RegExp(passwordREGEXP).test(val))
      .notOneOf([Yup.ref('oldPassword')], newPasswordShouldBeDifferent),
    repeatPassword: Yup.string()
      .required(fieldRequiredError)
      .oneOf([Yup.ref('newPassword')], passwordsDontMatch),
  });
};
