import { createAction } from 'deox';
import {
  FIRST_DEPOSIT_TO_BONUS_FAILURE,
  FIRST_DEPOSIT_TO_BONUS_REQUEST,
  FIRST_DEPOSIT_TO_BONUS_SUCCESS,
  IFirstDepositToWallet,
} from './types';

export const firstDepositToBonus = {
  req: createAction(FIRST_DEPOSIT_TO_BONUS_REQUEST, (res) => (params: IFirstDepositToWallet) => res(params)),
  success: createAction(FIRST_DEPOSIT_TO_BONUS_SUCCESS),
  fail: createAction(FIRST_DEPOSIT_TO_BONUS_FAILURE),
};
