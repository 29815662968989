import { FC, SetStateAction, useEffect, useState } from 'react';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { getSocilaLinks } from 'src/services/dataApi';
import PersisterService from 'src/services/PersisterService';
import { SocialType } from 'src/types/res-dto/configs';
import './styles.scss';

type Props = {
  settings: {
    needLogin: false;
    priority: number;
    styles: Styles;
  };
  placement: 'header' | 'footer' | 'sidebar';
};
const SocialNetworks: FC<Props> = ({ settings, placement }) => {
  const { styles } = settings;

  const [socials, setSocials] = useState<SocialType[]>([]);

  const getSocialsData = async (): Promise<void> => {
    let res: SetStateAction<SocialType[]> | any = null;

    if (PersisterService.hasPersistValue('socialData')) {
      res = PersisterService.getPersistValue('socialData');
      setSocials(res);
    } else {
      res = await getSocilaLinks();

      if (!!res?.data?.length) {
        const _linksData = res.data.map((f: any) => {
          return {
            name: f?.attributes?.Name,
            link: f?.attributes?.Link,
            imageSrc:
              process.env.REACT_APP_FILE_STORAGE_ADDRESS + f?.attributes?.Icon?.data?.attributes?.url?.substring(1) ??
              `/images/icons/social-icons/${f?.attributes?.Name?.toLowercase()}.svg`,
          };
        });

        PersisterService.setPersistValue('socialData', _linksData);
        setSocials(_linksData);
      }
    }
  };

  const setTemplate = (icon: SocialType, i: number): JSX.Element => {
    if (icon.link) {
      return (
        <a key={`${icon}_${i}`} className="social_icon_wrapper" href={icon.link} target="_blank">
          <img src={icon.imageSrc} alt={icon.name} style={icon.styles} />
        </a>
      );
    }

    return (
      <span key={`${icon}_${i}`} className="social_icon_wrapper">
        <img src={icon.imageSrc} alt={icon.name} style={icon.styles} />
      </span>
    );
  };

  useEffect(() => {
    getSocialsData();
  }, []);

  usePostMesssage((v: any) => setSocials(v), 'socials');

  return (
    <div style={styles} className={`social_networks_container ${placement}`}>
      {socials?.map((icon: SocialType, i: number) => setTemplate(icon, i))}
    </div>
  );
};
export default SocialNetworks;
