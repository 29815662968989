import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { useConnectedAction } from './use-connected-action';

export const useResetSignUpError = (): ((key: string) => void) => {
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  const _setErrorReq = useConnectedAction(setError);

  return useCallback(
    (key: string) => {
      const newSignUpErrors = { ...signUpErrors };
      delete newSignUpErrors[key];
      _setErrorReq(newSignUpErrors);
    },
    [signUpErrors]
  );
};
