import { FC } from 'react';
import { Typography } from 'src/components/common/Typography';

type Props = {
  changeSelectedSubSection?: (subSection: string) => void;
  iconClass: string;
  selected: any;
  article: any;
};
const ArticleTitle: FC<Props> = ({ changeSelectedSubSection, iconClass, selected, article }) => {
  const selectArticle = (): void => {
    if (changeSelectedSubSection) {
      changeSelectedSubSection(article);
    }
  };

  return (
    <div className={`help-center__row help-center__row--${selected ? 'selected' : ''}`} onClick={selectArticle}>
      <span className={iconClass} />
      <Typography variant={'h5'} className="help-center__row__text">
        {article.title}
      </Typography>
    </div>
  );
};
export default ArticleTitle;
