import { call, put, takeLatest } from 'redux-saga/effects';
import { getFinalTourInfo } from 'src/services/dataApi';
import { openDialog } from '../dialog/actions';
import { EnumDialogsKeys } from '../dialog/types';
import { setFinalTourInfo } from './actions';
import { APP_SET_FINAL_TOUR_INFO_REQ } from './types';

// final tour info
function* setFinalTourInfoSaga(): any {
  try {
    const response = yield call(getFinalTourInfo);

    if (response.data?.length) {
      yield put(setFinalTourInfo.success());
      const shouldShowDialog = localStorage.getItem('dontShowAgainPopups');

      if (!shouldShowDialog || shouldShowDialog === 'false') {
        yield put(openDialog({ dialogType: EnumDialogsKeys.FINAL_TOUR_INFO, dialogProps: { data: response.data } }));
      }
    }
  } catch (error: any) {}
}

export function* watchApp(): any {
  yield takeLatest(APP_SET_FINAL_TOUR_INFO_REQ, setFinalTourInfoSaga);
}
