import { createAction } from 'deox';
import {
  CONFIGS_SET_BOTTOM_NAVIGATION,
  CONFIGS_SET_CATEGORIES_DATA_FAIL,
  CONFIGS_SET_CATEGORIES_DATA_REQ,
  CONFIGS_SET_CATEGORIES_DATA_SUCCESS,
  CONFIGS_SET_GENERAL_CONFIGS_FAIL,
  CONFIGS_SET_GENERAL_CONFIGS_REQ,
  CONFIGS_SET_GENERAL_CONFIGS_SUCCESS,
  CONFIGS_SET_HEADER,
  CONFIGS_SET_PAGE_CONFIGS_FAIL,
  CONFIGS_SET_PAGE_CONFIGS_REQ,
  CONFIGS_SET_PAGE_CONFIGS_SUCCESS,
  CONFIGS_SET_PALETTE_FAIL,
  CONFIGS_SET_PALETTE_REQ,
  CONFIGS_SET_PALETTE_SUCCESS,
  CONFIGS_SET_SETTINGS_FAIL,
  CONFIGS_SET_SETTINGS_REQ,
  CONFIGS_SET_SETTINGS_SUCCESS,
} from './types';

export const setGeneralConfigs = {
  req: createAction(CONFIGS_SET_GENERAL_CONFIGS_REQ),
  success: createAction(CONFIGS_SET_GENERAL_CONFIGS_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(CONFIGS_SET_GENERAL_CONFIGS_FAIL),
};

export const setPageConfigs = {
  req: createAction(CONFIGS_SET_PAGE_CONFIGS_REQ),
  success: createAction(CONFIGS_SET_PAGE_CONFIGS_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(CONFIGS_SET_PAGE_CONFIGS_FAIL),
};

export const setPalette = {
  req: createAction(CONFIGS_SET_PALETTE_REQ, (res) => (params) => res(params)),
  success: createAction(CONFIGS_SET_PALETTE_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(CONFIGS_SET_PALETTE_FAIL),
};

export const setConfigs = {
  req: createAction(CONFIGS_SET_SETTINGS_REQ),
  success: createAction(CONFIGS_SET_SETTINGS_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(CONFIGS_SET_SETTINGS_FAIL),
};

export const setCategoriesData = {
  req: createAction(CONFIGS_SET_CATEGORIES_DATA_REQ),
  success: createAction(CONFIGS_SET_CATEGORIES_DATA_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(CONFIGS_SET_CATEGORIES_DATA_FAIL),
};

export const setBottomNavigation = createAction(CONFIGS_SET_BOTTOM_NAVIGATION, (res) => (params) => res(params));

export const setHeaderConfigs = createAction(CONFIGS_SET_HEADER, (res) => (params) => res(params));
