import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { scrollToTop, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { MediaManager } from 'src/manager/MediaManager';
import { getTopCategories } from 'src/services/configsApi';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setSelectedGame, setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    image: string;
    styles: Styles;
    view: string;
    text: { [key: string]: string };
    classes: string;
  };
};
const TopCategories: FC<Props> = ({ settings }) => {
  const { t }: Translation = useTranslation();
  const navigate = useNavigate();

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { user } = useSelector((state: RootState) => state.user);

  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [view, setView] = useState<string>('');

  const changeNavigation = (navigation: NavigationItem) => () => {
    if (navigation.name === selectedCategory) return;

    if (navigation.needLogIn && !user) {
      setBodyOverflow('unset');
      _openAuthModal('login');
    } else {
      if (navigation.type === 'game') {
        _selectedGame({ id: navigation.gameId });
      } else {
        navigate(`/category/${navigation.name}`);

        _setSelectedCategory(navigation.name);
        _setSelectedTag(navigation.hasHomeTag ? 'home' : 'allGames');
        _setSelectedProvider(['all_providers']);
      }
    }

    setTimeout(() => {
      scrollToTop();
    }, 0);
  };

  const renderNavItem = (navItem: NavigationItem, i: number): JSX.Element => {
    return (
      <div
        onClick={changeNavigation(navItem)}
        className={cn('nav_item', { ['selected']: navItem.name === selectedCategory })}
        key={i}
      >
        {view.includes('withIcon') && navItem?.imageSource?.includes('.svg') ? (
          <SvgIcon src={MediaManager.getSrcFromMediaAssets(navItem?.imageSource as string, '/categoryIcons/')} />
        ) : (
          <img
            src={MediaManager.getSrcFromMediaAssets(navItem?.imageSource as string, '/categoryIcons/')}
            alt={navItem.name}
            className="img_icon"
          />
        )}
        <span className="nav_item_title">{t(`category_${navItem.name}${navItem.type === 'game' ? 'game' : ''}`)}</span>
      </div>
    );
  };

  const getCategories = (): void => {
    getTopCategories().then((res) => {
      if (res?.data) {
        setMenuItems(res.data);
        setView(res.view);
      }
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="category_wrapper" style={settings.styles}>
      {menuItems?.map((navItem: NavigationItem, i: number) => renderNavItem(navItem, i))}
    </div>
  );
};
export default TopCategories;
