import cn from 'classnames';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GameCard from 'src/components/common/GameCard';
import { Input } from 'src/components/common/Input';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { useDebounce } from 'src/hooks/use-debounce';
import { useOutsideClick } from 'src/hooks/use_outside_click';
import { seacrhByGameNameRequest } from 'src/services/gameApi';
import { RootState } from 'src/types/store-types';
import { SPECIAL_TAGS } from 'src/utils/constants';
import '../styles.scss';
import ViewWithoutResult from './ViewWithoutResult';

type ModalSearchProps = {
  styles: { [key: string]: string };
};

const ModalSearch: FC<ModalSearchProps> = ({ styles }) => {
  const { t } = useTranslation();

  const searchRef = useRef<any>();
  const inputRef = useRef<any>(null);

  const { selectedProvider, selectedTag } = useSelector((state: RootState) => state.games);
  const { selectedCategory } = useSelector((state: RootState) => state.app);

  const [search, setSearch] = useState<string>('');
  const [games, setGames] = useState<any[] | null>(null);
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
  const [isSearchSubmitted, setIsSearchSubmitted] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const debouncedSearchTerm: any = useDebounce(search, 500);

  const getSearchedGame = (game: string): void => {
    const tag = ['allGames', 'home'].includes(selectedTag) ? null : [selectedTag];

    seacrhByGameNameRequest('desktop', game, selectedProvider, tag, selectedCategory).then((res: any) => {
      setGames(res.games);
      setIsSearchSubmitted(true);
    });
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (debouncedSearchTerm.length >= 3) {
        getSearchedGame(debouncedSearchTerm);
      } else {
        setGames([]);
      }
    }
  }, [debouncedSearchTerm]);

  const onSearchValueChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    setSearch(ev.target.value);
  };

  const open = (): void => {
    !isDisabled && setOpenSearchModal(true);
  };

  const close = (): void => {
    setOpenSearchModal(false);
  };

  const clearInput = (): void => {
    setSearch('');
    setGames(null);
  };

  const handleInputFocus = (): void => {
    // Set focus on the input element from the parent component
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (openSearchModal) {
      inputRef.current?.focus();
    } else {
      clearInput();
    }
  }, [openSearchModal]);

  useEffect(() => {
    setIsDisabled(SPECIAL_TAGS.includes(selectedTag));
  }, [selectedTag]);

  useOutsideClick(searchRef, () => close(), null, null);

  return (
    <div ref={searchRef} className="modal_search_wrapper">
      <div
        className={cn('modal_search_container', { ['focused']: openSearchModal })}
        onClick={() => open()}
        style={styles}
      >
        <div className="icon_wrapper">
          <SvgIcon src="/images/icons/svg/search.svg" className="icon" />
        </div>
        <Input
          id="modal_search_inp"
          onChange={onSearchValueChange}
          value={search}
          name={'search_input'}
          placeholder={t('searchGame')}
          isTransparent
          onFocus={handleInputFocus}
          className="search_game_input"
        />
        {search?.trim() && (
          <div onClick={clearInput} className="clear-icon_wrapper">
            <SvgIcon src="/images/icons/svg/close.svg" className="icon" />
          </div>
        )}
      </div>
      {openSearchModal && (
        <div>
          <div className="search_modal_container">
            {search.length > 2 && isSearchSubmitted && (
              <p className="title">
                {games?.length}
                <span>{t('results')}</span>
              </p>
            )}
            <div className="content">
              {games?.length ? (
                games?.map((game: any) => (
                  <GameCard
                    data={{
                      thumbnail: game.thumbnail,
                      name: game.name,
                      providerName: game.providerName,
                      id: game.id,
                      cols: 1,
                      rows: 1,
                    }}
                    size={'md_s'}
                    effects={{
                      hoverType: 'fullWidthImage',
                      src: '',
                      text: '',
                    }}
                  />
                ))
              ) : (
                <ViewWithoutResult t={t} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ModalSearch;
