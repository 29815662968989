import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { MediaManager } from 'src/manager/MediaManager';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import '../../styles.scss';

enum EnumHoverTypes {
  UNDERLINED = 'underlined',
  COLORED = 'colored',
  FILLED = 'filled',
}

type Props = {
  selectedCategory: string;
  view: any;
  navItem: any;
  withUnderLine: any;
  idx: any;
  menuItems: any;
  changeNavigation: (category: NavigationItem) => void;
};
export const NavItem: FC<Props> = ({
  selectedCategory,
  view,
  navItem,
  withUnderLine,
  idx,
  menuItems,
  changeNavigation,
}) => {
  const { t }: Translation = useTranslation();

  const hoverStyle: string = EnumHoverTypes.UNDERLINED;

  const conditionalClassNames = {
    ['navItem_placement_top']: view.placement === 'top',
    ['navItem_placement_right']: view.placement === 'right',
    ['navItem_placement_bottom']: view.placement === 'bottom',
    ['navItem_placement_left']: view.placement === 'left',
    ['navItem_active']: navItem.name === selectedCategory && hoverStyle !== EnumHoverTypes.UNDERLINED,
    ['selected']: navItem.name === selectedCategory,
    ['md_icon']: view.type === 'withIcon&Divider',
    ['with_label']: navItem.label,
  };

  return (
    <div
      onClick={() => changeNavigation(navItem)}
      className={cn('nav_item', hoverStyle ?? '', conditionalClassNames)}
      key={navItem.name}
    >
      {navItem?.label && (
        <img
          src={MediaManager.getSrcFromMediaAssets(navItem?.label, '/labels/')}
          className="label-image"
          alt="new-label"
        />
      )}
      <span className="nav_item--first-children">
        {view.type.includes('withIcon') && navItem?.imageSource?.includes('.svg') ? (
          <SvgIcon src={MediaManager.getSrcFromMediaAssets(navItem?.imageSource as string, '/categoryIcons/')} />
        ) : (
          <img
            src={MediaManager.getSrcFromMediaAssets(navItem?.imageSource as string, '/categoryIcons/')}
            alt={navItem.name}
          />
        )}
        <span>{t(`category_${navItem.name}${navItem.type === 'game' ? 'game' : ''}`)}</span>
        {view.type === 'withIcon&Divider' && idx + 1 !== menuItems.length && <span className="navItem_divider" />}
      </span>

      {hoverStyle === EnumHoverTypes.UNDERLINED && navItem.name === selectedCategory && withUnderLine && (
        <span className="navItem_active" />
      )}
    </div>
  );
};
