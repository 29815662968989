export type mobileState = {
  showMenu: boolean;
  showSearch: boolean;
  showProviders: boolean;
  showBottomNavigation: boolean;
  showMobileLiveChat: boolean;
};

export const TOGGLE_MOBILE_MENU = 'mobile/TOGGLE_MOBILE_MENU';

export const TOGGLE_MOBILE_SEARCH = 'mobile/TOGGLE_MOBILE_SEARCH';

export const TOGGLE_MOBILE_PROVIDER = 'mobile/TOGGLE_MOBILE_PROVIDER';

export const TOGGLE_BOTTOM_NAVIGATION = 'mobile/TOGGLE_BOTTOM_NAVIGATION';

export const MOBILE_SET_MOBILE_LIVECHAT = 'mobile/MOBILE_SET_MOBILE_LIVECHAT';
