import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setSelectedGame } from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  win?: Win;
  isSkeleton?: boolean;
};
const WinCard: FC<Props> = ({ win, isSkeleton }) => {
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _selectedGame = useConnectedAction(setSelectedGame);

  const { user } = useSelector((state: RootState) => state.user);

  const winnerName = useMemo(() => {
    if (win) {
      const asterisks = Array(win?.username.length - 3).join('*');
      return win?.username.slice(0, 3) + asterisks;
    }
    return null;
  }, [win?.username]);

  const onWinClick = (): void => {
    if (user) {
      _selectedGame({ id: win?.gameInstanceId });
    } else {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }
  };

  return isSkeleton ? (
    <div className="win_card" />
  ) : (
    <div className="win_card" onClick={onWinClick}>
      <div className="game_thumbnail">
        <img src={win?.thumbnail} alt={win?.gameName} />
      </div>
      <div className="info">
        <Typography className="winner" variant={'h6'}>
          {winnerName}
        </Typography>
        <div className="win_amount">
          <Typography className="winner" variant={'h6'}>
            Just won:
          </Typography>
          <Typography className="winner" variant={'h6'}>
            {win?.amount} {win?.currency}
          </Typography>
        </div>
        <div className="game_name">
          <Typography className="winner" variant={'h6'}>
            In:
          </Typography>
          <Typography className="winner" variant={'h6'}>
            {win?.gameName}
          </Typography>
        </div>
      </div>
    </div>
  );
};
export default WinCard;
