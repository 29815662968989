import React from 'react';
import { Components } from 'src/configs/components/components';

export class Constructor {
  public static renderWidgets = (settings: { [key: string]: any }): any => {
    if (!Object.keys(settings).length) {
      return <></>;
    }

    const orderIndex = {};

    Object.keys(settings).forEach((key: string) => (orderIndex[`${key}`] = settings[key].priority));

    return Object.keys(settings)
      .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
      .filter((key: string) => key !== 'styles')
      .map((key: string, i: number) => {
        return React.createElement(Components[key] ?? <p>{key}</p>, {
          settings: settings[key] || {},
          placement: 'footer',
          key: `${key}_${i}`,
        });
      });
  };
}
