import { createReducer } from 'deox';
import produce from 'immer';
import {
  setMobileLiveChatVisibility,
  toggleMobileBottomNavigation,
  toggleMobileMenu,
  toggleMobileProvider,
  toggleMobileSearch,
} from './actions';
import { mobileState } from './types';

export const mobileInitialState: mobileState = {
  showMenu: false,
  showSearch: false,
  showProviders: false,
  showBottomNavigation: false,
  showMobileLiveChat: false,
};

export const mobileReducer = createReducer(mobileInitialState, (handle) => [
  handle(toggleMobileMenu, (state, {}: any) =>
    produce(state, (draft) => {
      draft.showMenu = !state.showMenu;
    })
  ),
  handle(toggleMobileSearch, (state, {}: any) =>
    produce(state, (draft) => {
      draft.showSearch = !state.showSearch;
    })
  ),
  handle(toggleMobileProvider, (state, {}: any) =>
    produce(state, (draft) => {
      draft.showProviders = !state.showProviders;
    })
  ),
  handle(toggleMobileBottomNavigation, (state, {}: any) =>
    produce(state, (draft) => {
      draft.showBottomNavigation = !state.showBottomNavigation;
    })
  ),
  handle(setMobileLiveChatVisibility, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.showMobileLiveChat = payload;
    })
  ),
]);
