import cn from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { getExtension } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { toggleSideBarMenu } from 'src/store/app/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    view: { type: string; placement: string };
    priority: number;
    styles: { [key: string]: any };
    needLogin: false;
    src: string;
  };
};

const SideBarToggle: FC<Props> = ({ settings }) => {
  const { src, styles } = settings;

  const _toggleSideBarMenu = useConnectedAction(toggleSideBarMenu);

  const { sideBarMenuIsOpen } = useSelector((state: RootState) => state.app);

  const toggleSideBarNavigation = (): void => {
    _toggleSideBarMenu();
  };

  return (
    <div className={cn('sidebar_menu', { ['sidebar_menu--opened']: sideBarMenuIsOpen })}>
      <div className={cn('sidebar_menu_inner')} onClick={toggleSideBarNavigation} style={styles}>
        {getExtension(src) === 'svg' ? (
          <SvgIcon src={`/images/icons/header/${src}`} styles={styles} />
        ) : (
          <img src={`/images/icons/header/${src}`} style={styles} alt="Logo" />
        )}
      </div>
    </div>
  );
};
export default SideBarToggle;
