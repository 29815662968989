import React from 'react';
import QRPopup from '../QRPopup';
import SuccessMessage from '../SuccessMessage';

type NotificationProps = {
  showTransactionResult: boolean;
  transactionNotification: {
    message?: {
      responseHandling?: {
        type?: string;
      };
    };
  };
  closeNotification: () => void;
};

const PaymentNotification: React.FC<NotificationProps> = ({
  showTransactionResult,
  transactionNotification,
  closeNotification,
}) => {
  if (showTransactionResult) {
    if (transactionNotification?.message?.responseHandling?.type === 'QRCode') {
      return <QRPopup transactionNotification={transactionNotification} closeNotification={closeNotification} />;
    } else if (transactionNotification?.message) {
      return <SuccessMessage transactionNotification={transactionNotification} closeNotification={closeNotification} />;
    }
  }
  return <></>;
};

export default PaymentNotification;
