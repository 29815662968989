import { createReducer } from 'deox';
import produce from 'immer';
import { closeAuthModal, openAuthModal } from './actions';
import { authModalState } from './types';

export const authModalInitialState: authModalState = {
  modalContent: null,
};

export const authModalReducer = createReducer(authModalInitialState, (handle) => [
  handle(closeAuthModal, (state, {}: any) =>
    produce(state, (draft) => {
      draft.modalContent = null;
    })
  ),
  handle(openAuthModal, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.modalContent = payload;
    })
  ),
]);
