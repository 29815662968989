import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type QueryParams = Record<string, string>;

export const useQuery = (): QueryParams => {
  const { search } = useLocation();
  return useMemo(() => {
    return Array.from(new URLSearchParams(search).entries()).reduce((acc: QueryParams, [key, value]) => {
      acc[key] = decodeURI(value);
      return acc;
    }, {});
  }, [search]);
};
