import { FC } from 'react';
import { MediaManager } from 'src/manager/MediaManager';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    imageSrc: string;
    iconSrc?: string;
    styles: Styles;
  };
};
const GambleAware: FC<Props> = ({ settings }) => {
  const { styles, imageSrc } = settings;

  return (
    <div className="gambleAware_wrapper">
      <img
        src={MediaManager.getSrcFromMediaAssets(imageSrc, '/footerIcons/')}
        alt="Gamble aware"
        className="gambleAware"
        style={styles}
      />
    </div>
  );
};
export default GambleAware;
