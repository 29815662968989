import { call, put, select, takeLatest } from 'redux-saga/effects';
import { readMessagesRequest } from 'src/services/messagesApi';
import { RootState } from 'src/types/store-types';
import { readMessageSuccess } from './actions';
import { READ_MESSAGE_REQUEST } from './types';

function* markMessageAsRead({ payload }: any): any {
  try {
    const user = yield select((store: RootState) => store.user.user);
    const params = {
      status: 'read',
      accountId: user?.id,
      messageId: payload,
    };
    yield call(readMessagesRequest, params);
    yield put(readMessageSuccess(payload));
  } catch (e) {
    console.log('Failed to change the message status.');
  }
}

export function* watchAppMessages(): any {
  yield takeLatest(READ_MESSAGE_REQUEST, markMessageAsRead);
}
