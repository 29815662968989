import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { getFooterConfigs } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { setGeneraLoading } from 'src/store/app/actions';
import { RootState } from 'src/types/store-types';
import { IFRAMES } from 'src/utils/constants';
import { Constructor } from './constructor';
import './styles.scss';

const TabletFooterConstructor: FC = () => {
  const _setGeneraLoading = useConnectedAction(setGeneraLoading);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { user } = useSelector((state: RootState) => state.user);
  const { navigation, generalConfigs } = useSelector((state: RootState) => state.configs);

  const footerViewWithBtn = useMemo(() => {
    return IFRAMES.includes(navigation.filter((f) => f.name === selectedCategory)[0]?.type);
  }, [selectedCategory, navigation, generalConfigs]);

  const [open, setOpen] = useState<boolean>(false);
  const [configs, setConfigs] = useState<Settings>({
    template: '',
    sections: [],
    styles: {},
  });

  const sectionsArr = Object.values(configs?.sections as ISection[]);

  const footerComponent = useMemo(
    () => (
      <>
        {sectionsArr?.map((obj: any, i: number) => {
          return <React.Fragment key={obj + i}>{Constructor.renderWidgets(obj)}</React.Fragment>;
        })}
      </>
    ),
    [sectionsArr]
  );

  const getFooterConf = async (): Promise<void> => {
    try {
      if (PersisterService.hasPersistValue('tabletFooter')) {
        const res = PersisterService.getPersistValue('tabletFooter');
        setConfigs(res);
      } else {
        const res = await getFooterConfigs('tablet');

        if (res.success) {
          _setGeneraLoading(true);
          setConfigs(res.result);
          PersisterService.setPersistValue('tabletFooter', res.result);
        } else {
          _setGeneraLoading(false);
        }
      }
    } catch (e) {
      _setGeneraLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getFooterConf();
  }, [user]);

  usePostMesssage((v: any) => setConfigs(v), 'tabletFooter');

  if (sectionsArr.length > 0) {
    return (
      <>
        {footerViewWithBtn ? (
          <div className="tablet_outer_container">
            <div className={cn('footer_with_btn', { ['open']: open })} style={configs?.styles}>
              <Button
                onClick={() => setOpen((prev) => !prev)}
                className={cn('footer_icon_btn', { ['rotateArrow']: open })}
              >
                <SvgIcon src={open ? icons.close : icons.arrowDown} />
              </Button>
              <div className={cn('footer_container_with_btn', { ['show']: open })}>
                <div style={configs?.styles} className={cn('footer_container_inner', configs?.template)}>
                  {configs?.sections && footerComponent}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="tablet_outer_container">
            <div style={configs.styles} className={cn('footer_container', configs?.template)}>
              {configs?.sections && footerComponent}
            </div>
          </div>
        )}
      </>
    );
  }
  return <></>;
};
export default TabletFooterConstructor;
