class PersisterService {
  public hasPersistValue = (key: string): boolean => {
    return Boolean(sessionStorage.getItem(key));
  };
  public getPersistValue = (key: string): any => {
    const res: string = sessionStorage.getItem(key) || '';
    return JSON.parse(res);
  };
  public setPersistValue = (key: string, val: any): void => {
    sessionStorage.setItem(key, JSON.stringify(val));
  };
}

export default new PersisterService();
