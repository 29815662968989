import { takeLatest } from 'redux-saga/effects';
import { INTEGRATIONS_SET_GAME_BOX_MISSION_REQ } from './types';

// missions
function* getGameBoxMission(): any {
  // TO DO remove
}

export function* watchIntegrations(): any {
  yield takeLatest(INTEGRATIONS_SET_GAME_BOX_MISSION_REQ, getGameBoxMission);
}
