export type transactionsState = {
  transactionStatus: any;
  transactionLoading: boolean;
  transactionNotification:
    | null
    | {
        status: boolean;
        message: string;
      }
    | any;
};

export const TRANSACTION_SET_TRANSACTION_REQ = 'transactions/TRANSACTION_SET_TRANSACTION_REQ';
export const TRANSACTION_SET_TRANSACTION_SUCCESS = 'transactions/TRANSACTION_SET_TRANSACTION_SUCCESS';
export const TRANSACTION_SET_TRANSACTION_FAIL = 'transactions/TRANSACTION_SET_TRANSACTION_FAIL';
export const TRANSACTION_SET_TRANSACTION_LOAD = 'transactions/TRANSACTION_SET_TRANSACTION_LOAD';

export const TRANSACTION_SET_TRANSACTION_NOTIOFICATION = 'transactions/TRANSACTION_SET_TRANSACTION_NOTIOFICATION';
