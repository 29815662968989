import { createReducer } from 'deox';
import produce from 'immer';
import {
  setBottomNavigation,
  setCategoriesData,
  setConfigs,
  setGeneralConfigs,
  setHeaderConfigs,
  setPageConfigs,
  setPalette,
} from './actions';
import { configsState } from './types';

export const initialState: configsState = {
  generalConfigs: sessionStorage.getItem('generalConfigs') ?? null,
  pageConfigs: null,
  palette: '',
  headerSettings: null,
  categoriesData: {},
  navigation: [],
  tagsMetaInfo: [],
  pageTagsPreviews: [],
  bottomNavigation: [],
  headerTemplate: '',
};

export const configsReducer = createReducer(initialState, (handle) => [
  handle(setGeneralConfigs.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.generalConfigs = payload;
    })
  ),
  handle(setHeaderConfigs, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.headerTemplate = payload;
    })
  ),
  handle(setGeneralConfigs.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.generalConfigs = payload;
    })
  ),
  handle(setPageConfigs.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.pageConfigs = payload;
    })
  ),
  handle(setPalette.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.palette = payload.palette;
    })
  ),
  handle(setConfigs.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.headerSettings = payload.settings;
    })
  ),
  handle(setCategoriesData.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.categoriesData = payload.categoriesData;
      draft.tagsMetaInfo = payload.tagsMetaInfo;
      draft.pageTagsPreviews = payload.pageTagsPreviews;
      draft.navigation = payload.navigation;
    })
  ),
  handle(setBottomNavigation, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.bottomNavigation = payload;
    })
  ),
]);
