import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from 'src/components/base-components/Logo';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { Typography } from 'src/components/common/Typography';
import { getDocumentNotFoundTranslation } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import './styles.scss';

type Props = {
  data: any;
};
const RegistrationFailure: FC<Props> = ({ data }) => {
  const { params, translationKey } = data;

  const { t }: Translation = useTranslation();

  const _closeDialog = useConnectedAction(closeDialog);

  const translationText = useMemo(() => {
    return getDocumentNotFoundTranslation(params, translationKey);
  }, [params, translationKey]);

  const removeDialog = (): void => {
    _closeDialog({ dialogType: EnumDialogsKeys.REGISTRATION_FAILURE });
  };

  return (
    <PopUpContainer isOpen handleClose={removeDialog} handleOK={removeDialog} hasOverlay={false}>
      <div className="description-wrapper">
        <Logo />
        <i className="icon-httpslock" />
        <Typography variant={'h5'} className="title">
          {t('failureRegistrationTitle')}
        </Typography>
        <p className="text">{t(translationText)}</p>
      </div>
    </PopUpContainer>
  );
};

export default RegistrationFailure;
