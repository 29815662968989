/* eslint-disable no-nested-ternary */
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { formatCurrency } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { readMessageRequest } from 'src/store/messages/actions';
import './styles.scss';

type Props = {
  data: any;
};
const TournamentResults: FC<Props> = ({ data }) => {
  const { tournamentName, id } = data;

  const { t }: Translation = useTranslation();

  const _closeDialog = useConnectedAction(closeDialog);
  const _readMessage = useConnectedAction(readMessageRequest);

  const isTypeTourTicket = data?.winning?.type === 'tourTicket';

  const tournamentResults = [
    { label: `${t('score')}:`, value: data?.score },
    { label: `${t('yourPosition')}:`, value: data?.playerPosition },
    { label: `${t('tournamentId')}:`, value: data?.tournamentId },
  ];

  const removeDialog = useCallback((): void => {
    _readMessage(id);
    _closeDialog({ dialogType: EnumDialogsKeys.TOURNAMENT_RESULTS });
  }, []);

  return (
    <PopUpContainer
      isOpen
      handleClose={removeDialog}
      hasOverlay
      title={t(`tournamentReultsDialog.title`)}
      description={t('tournamentReultsDialog.text', { tournamentName: tournamentName })}
    >
      <div className="tournament-results">
        <span className="tournament-results__text">{`${t('tournamentResults')}:`}</span>
        <div className="tournament-results__info-wrapper">
          {tournamentResults?.map((result: any) => {
            return (
              <div className="tournament-results__info-item">
                <span className="tournament-results__info-item-label">{result.label}</span>
                <span className="tournament-results__info-item-value">{result?.value}</span>
              </div>
            );
          })}
        </div>
        {(data?.winning?.amount > 0 || isTypeTourTicket || data?.winning?.prizeName) && (
          <div className="tournament-results__winning-wrapper">
            <div className="tournament-results__winning">
              <div className="tournament-results__winning-icon-wrapper">
                <div className={`tournament-results__${isTypeTourTicket ? 'ticket' : 'winning'}-icon`} />
              </div>
              <div className="tournament-results__winning-info-wrapper">
                {!isTypeTourTicket && (
                  <span className="tournament-results__winning-info-title">
                    {data?.winning?.prizeName
                      ? data?.winning?.prizeName
                      : formatCurrency(data.winning.amount, data.winning.currency)}
                  </span>
                )}
                <span
                  className="tournament-results__winning-info-subtitle"
                  dangerouslySetInnerHTML={{
                    __html: isTypeTourTicket
                      ? t('winningTicket', { tournamentName: data?.winning?.targetName })
                      : data?.winning?.prizeName
                      ? ''
                      : t('addedToYourBalance'),
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </PopUpContainer>
  );
};

export default TournamentResults;
