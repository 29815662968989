import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getRowNumber } from 'src/helpers/utils';
import { RootState } from '../../../types/store-types';
import GamesSlider from '../Slider/GamesSlider';

type Props = {
  gameTags: { allTagData: any; allTagNames: string[] };
  homeTag: any;
};
const HomePage: FC<Props> = ({ gameTags, homeTag }) => {
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  return (
    <>
      {gameTags?.allTagData?.tags
        ?.sort(
          (a: { tagName: string; priority: number }, b: { tagName: string; priority: number }) =>
            b?.priority - a?.priority
        )
        ?.map((tagData: any) => {
          return (
            <GamesSlider
              key={tagData.tagName}
              data={tagData.tagGames}
              tagName={tagData.tagTrName || tagData.tagName}
              template={`template_${
                homeTag.homeTagSettings?.[tagData.tagName]?.gameSliderTemplate ||
                (generalConfigs?.isVertical ? '5' : getRowNumber(tagData?.viewType)) //TODO correct viewType for isVertical temp;ate
              }`}
            />
          );
        })}
    </>
  );
};
export default HomePage;
