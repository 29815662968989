import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RadioInput from 'src/components/common/RadioInput';
import { Typography } from 'src/components/common/Typography';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import './styles.scss';

type IProps = {
  onChange: (e: any) => void;
  required: boolean;
  label: boolean;
  type: string;
  data: string[];
  name: string;
  value: string;
  tabIndex?: number;
};

const Gender_Select: FC<IProps> = ({ label, required, data, name, value, onChange, tabIndex = 0 }) => {
  // Hooks
  const { t }: Translation = useTranslation();
  // Redux
  const _setErrorReq = useConnectedAction(setError);

  // Selectors
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // YUP Validation
  const validationSchema = Yup.object().shape({
    gender: Yup.string()
      .required(t('requiredField'))
      .test('genderCheck', t('gender_error'), (val = '') => data.includes(val)),
  });
  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ gender: value }, { abortEarly: false });
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, gender: error.errors[0] });
      }
    }
  };

  // Actions
  const handleChange = useCallback(
    (e: any): void => {
      onChange({ target: { name, value: e.target.value } });
      handleValidate(e.target.value);
    },
    [value, data]
  );

  // Effects
  useEffect(() => {
    if (!value && name && data[0]) onChange({ target: { name, value: data[0] } });
  }, [value, data]);

  return (
    <div className="gender_select__wrapper" tabIndex={tabIndex}>
      {label && (
        <Typography className="label" variant={'body5'}>
          {t(label)} {required && <span>*</span>}
        </Typography>
      )}
      <RadioInput onChange={handleChange} required={required} data={data} value={value} />
    </div>
  );
};

export default Gender_Select;
