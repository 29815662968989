import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import _styles from '../../styles.module.scss';

type Props = {
  friendsList: FriendsList | null;
  setShowFriendsList: (show: boolean) => void;
};
const ReferredFriends: FC<Props> = ({ friendsList, setShowFriendsList }) => {
  const { t } = useTranslation();

  const openFriendsList = (): void => {
    setShowFriendsList(true);
  };

  return (
    <div className={_styles.referred_friends}>
      <div className={_styles.accordion} onClick={openFriendsList}>
        <div>
          <SvgIcon src="/images/refer_a_friend/friend.svg" className={_styles.friend_icon} />
          <div>
            <Typography variant={'h5'}>{t('refer_a_friend.referred_friends')}</Typography>
            {friendsList?.friendsCount === 10 && !isMobileOnly && (
              <Typography variant={'h6'}>{t('refer_a_friend.max_friends_count_reached')}</Typography>
            )}
          </div>
        </div>
        <div>
          <span>{friendsList?.friendsCount || 0}</span>
          <SvgIcon src={icons.arrowDown} className={_styles.arrow} />
        </div>
      </div>
    </div>
  );
};
export default ReferredFriends;
