import cn from 'classnames';
import { FC, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { CMSManager } from 'src/manager/CMSManager';
import { getStaticPagesFromCms } from 'src/services/dataApi';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setBottomNavigation } from 'src/store/configs/actions';
import { toggleMobileBottomNavigation } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    type: string; //link
    viewType: string; // row | list
    priority: number;
    styles: Styles;
  };
};
const BottomNavigation: FC<Props> = ({ settings }) => {
  const { type, viewType } = settings;

  const { locale } = useI18n();

  const { bottomNavigation } = useSelector((state: RootState) => state.configs);

  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _toggleMobileBottomNavigation = useConnectedAction(toggleMobileBottomNavigation);
  const _setBottomNavigation = useConnectedAction(setBottomNavigation);

  const openInfoAndRulesModal = (navItem: any): void => {
    _openAccountModal({ section: 'info_and_rules', subSection: navItem });

    isMobileOnly && _toggleMobileBottomNavigation();
  };

  const getStaticPageData = async (): Promise<void> => {
    const res: any = await getStaticPagesFromCms(locale);

    if (res?.data?.[0]) {
      const _ = CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string].getInfoAndRulesData(res.data);

      _setBottomNavigation(_);
    } else {
      _setBottomNavigation([]);
      console.error('bottom navigation from csm is not found');
    }
  };

  useEffect(() => {
    if (locale) {
      getStaticPageData();
    }
  }, [locale]);

  return (
    <div
      className={cn('bottom_navigation_wrapper', {
        ['rules_row']: viewType === 'row',
        ['rules_list']: viewType === 'list',
      })}
    >
      {bottomNavigation?.map((navItem: any) => (
        <Button
          className={cn('rule', { ['link']: type === 'link' })}
          key={navItem.title}
          onClick={() => openInfoAndRulesModal(navItem.name)}
        >
          {navItem.title}
        </Button>
      ))}
    </div>
  );
};
export default BottomNavigation;
