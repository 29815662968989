import { FC } from 'react';
import './styles.scss';

type Props = {
  settings: {
    t_placeholder: string;
    priority: number;
    styles: Styles;
  };
};
const TextWidget: FC<Props> = ({ settings }) => {
  const { styles, t_placeholder } = settings;

  return (
    <p className="text_widget" style={styles}>
      {t_placeholder || 'TEXT_WIDGET'}
    </p>
  );
};
export default TextWidget;
