import { Button, Modal } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onCancel: () => void;
  open: boolean;
}
const EmailVerificationModal: FC<IProps> = ({ open, onCancel }) => {
  const { t }: Translation = useTranslation();

  return (
    <Modal footer={null} open={open} className={'inactivity-modal-container'} onCancel={onCancel}>
      {t('verified')}
      <Button onClick={onCancel}> {t('ok')}</Button>
    </Modal>
  );
};
export default EmailVerificationModal;
