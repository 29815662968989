import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'src/components/common/Alert';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';

type Props = {
  transactionNotification: any;
  closeNotification: () => void;
};
const QRPopup: FC<Props> = ({ transactionNotification, closeNotification }) => {
  const copyQRCode = (): void => {
    navigator.clipboard.writeText(transactionNotification.message.QRDecoded);
  };
  const { t } = useTranslation();
  const { exchangeRate } = transactionNotification?.message ?? {};

  return (
    <Alert className="notif success_notification_class qr_code_container">
      <SvgIcon src="/images/icons/svg/circle-exclamation-solid.svg" className="notification" color="green" />
      {exchangeRate && Object.keys(exchangeRate).length > 0 ? (
        <div className="exchange_rate">
          <p>
            <span>{t('rate_info')}:</span>
            {`${exchangeRate?.rateFrom} ${exchangeRate?.currencyFrom?.currency} = 
            ${exchangeRate?.rateTo} ${exchangeRate?.currencyTo?.currency}`}
          </p>
          <p>
            {t('min_deposit')} {`${exchangeRate?.currencyFrom?.minAmount} ${exchangeRate?.currencyFrom?.currency}`}
          </p>
        </div>
      ) : null}
      <div className="qr_code_content">
        <p>{transactionNotification.message.message}</p>
        <img src={transactionNotification.message.QRCodeImgUrl} alt="QRCodeImgUrl" />
        <p>{transactionNotification.message.QRDecoded}</p>
      </div>
      <div className="btn-wrapper">
        <Button className="success-filled-btn" onClick={copyQRCode}>
          {t('copyCode')}
        </Button>
        <Button className="success-filled-btn" onClick={closeNotification}>
          {t('done')}
        </Button>
      </div>
    </Alert>
  );
};
export default QRPopup;
