import { FC, useEffect, useState } from 'react';
import { detectDevice } from 'src/helpers/utils';
import { getPaymentsLogos } from 'src/services/dataApi';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
  };
};
const PaymentList: FC<Props> = ({ settings }) => {
  const { styles } = settings;

  const device = detectDevice();

  const [paymentsLogos, setPaymentsLogos] = useState<string[]>([]);

  const fetchPaymentsLogos = (): void => {
    getPaymentsLogos().then((res: any) => {
      if (res.success) {
        const logos = res.result?.paymentMethods?.map((f: any) => f.icons[device]?.path);
        setPaymentsLogos(logos);
      } else {
        console.error("Can't get payment images");
      }
    });
  };

  useEffect(() => {
    fetchPaymentsLogos();
  }, []);

  return (
    <div className="paymentList_container" style={styles}>
      {paymentsLogos?.map((paymentLogo: string, i: number) => {
        return (
          <div className="payment" key={i}>
            <img src={paymentLogo} alt={paymentLogo} />
          </div>
        );
      })}
    </div>
  );
};
export default PaymentList;
