import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'src/components/common/Typography';
import '../styles.scss';

type Props = { showText?: boolean };

const SportLoader: FC<Props> = ({ showText }) => {
  const { t } = useTranslation();
  if (showText) {
    return (
      <div className="loading_spinner">
        <Typography className="loading_spinner_text">{t('loading')} ...</Typography>
      </div>
    );
  }
  return (
    <div className="loading_spinner">
      <div className="loader loader-1">
        <div className="loader-outter"></div>
        <div className="loader-inner"></div>
      </div>
    </div>
  );
};

export default SportLoader;
