import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RadioInput from 'src/components/common/RadioInput';
import { Typography } from 'src/components/common/Typography';
import './styles.scss';

type IProps = {
  onChange: (e: any) => void;
  required: boolean;
  label: boolean;
  type: string;
  data: string[];
  name: string;
  value: string;
};

const Currency_Select: FC<IProps> = ({ label, required, data, name, value, onChange }) => {
  const { t }: Translation = useTranslation();

  useEffect(() => {
    if (!value && name && data[0]) onChange({ target: { name: name, value: data[0] } });
  }, [value, data]);

  return (
    <div className="currency_select__wrapper">
      {label && (
        <Typography className="label" variant={'body5'}>
          {t(label)} {required && <span>*</span>}
        </Typography>
      )}
      <RadioInput onChange={onChange} required={required} data={data} value={value} />
    </div>
  );
};

export default Currency_Select;
