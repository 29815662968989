import { FC } from 'react';
import { HomeBanner } from 'src/types/common-types/slider';
import HomeWidget from './HomeWidget';

type Props = {
  widgets: HomeBanner[];
  redirect: (ev: any, attributes: any) => void;
};
const Template_4: FC<Props> = ({ widgets, redirect }) => {
  return (
    <>
      {widgets?.map((widget: HomeBanner) => {
        return <HomeWidget key={widget.id} widgetData={widget} redirect={redirect} cls="" />;
      })}
    </>
  );
};
export default Template_4;
