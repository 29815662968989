import { FC } from 'react';
import { Toggle } from 'src/components/common/Toggle';
import { Typography } from 'src/components/common/Typography';
import './styles.scss';

type Props = {
  title: string;
  text: string;
  btnTitle: string;
  isDisabled: boolean;
  value: boolean;
  index: number;
  handleChange: (value: boolean, index: number) => void;
};
const CookiesSettings: FC<Props> = ({ title, text, btnTitle, isDisabled, value, index, handleChange }) => {
  return (
    <div className="cookies-settings-item">
      <div className="title">
        <Typography className="label" variant={'h4'}>
          {title}
        </Typography>
      </div>
      <div className="text">
        <Typography className="label" variant={'h6'}>
          {text}
        </Typography>
      </div>
      <Toggle
        onToggle={handleChange}
        id="login_checkbox"
        label={title}
        disabled={isDisabled}
        btnTitle={btnTitle}
        value={value}
        index={index}
      />
    </div>
  );
};
export default CookiesSettings;
