import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { detectDevice } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useDebounce } from 'src/hooks/use-debounce';
import { gameListRequest, seacrhByGameNameRequest } from 'src/services/gameApi';
import { setGamesTotalCount, setSelectedTag, setTags } from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import { EGT_GAME_MAP } from 'src/utils/constants';
import '../styles.scss';

type SimpleSearchProps = {
  styles: { [key: string]: string };
};
const SimpleSearch: FC<SimpleSearchProps> = ({ styles }) => {
  const { t } = useTranslation();
  const device = detectDevice();

  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setTags = useConnectedAction(setTags);
  const _setGamesTotalCount = useConnectedAction(setGamesTotalCount);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const { selectedProvider, selectedTag } = useSelector((state: RootState) => state.games);

  const gamesCount =
    device === 'mobile' ? generalConfigs?.gamesCountPerPageMobile : generalConfigs?.gamesCountPerPageDesktop;

  const [search, setSearch] = useState<string>('');
  const debouncedSearchTerm: any = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (debouncedSearchTerm >= 3) {
        getSearchedGame(debouncedSearchTerm);
      } else {
        search.length > 0 && search.length > debouncedSearchTerm.length && getGameListRequest();
      }
    }
  }, [debouncedSearchTerm]);

  const getSearchedGame = (game: string): void => {
    seacrhByGameNameRequest(device, game, selectedProvider, [selectedTag], selectedCategory).then((res: any) => {
      _setGamesTotalCount(res.totalCount);
      _setTags(res.games);
    });
  };

  const getGameListRequest = (): void => {
    const tag = ['allGames', 'home'].includes(selectedTag) ? null : [selectedTag];

    gameListRequest(device, selectedProvider, 0, selectedCategory, tag, gamesCount).then((res: any) => {
      if (!!res.games) {
        const newGames = res.games.map((game: any) => {
          if (game.providerName === 'EGT Digital') {
            return {
              ...game,
              gameKey: EGT_GAME_MAP[game.launchId],
            };
          } else {
            return game;
          }
        });
        _setTags(newGames);
        _setGamesTotalCount(res.totalCount);
      }
    });
  };

  const onSearchValueChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    selectedTag === 'home' && _setSelectedTag('allGames');

    setSearch(ev.target.value);
  };

  useEffect(() => {
    setSearch('');
  }, [selectedCategory]);

  return (
    <div className="simple_search_container" style={styles}>
      <SvgIcon src="/images/icons/svg/search.svg" className="icon" />
      <Input
        type="text"
        value={search}
        onChange={onSearchValueChange}
        placeholder={t('searchGame')}
        name={''}
        isTransparent
        className="simple_search_container_btn"
      />
    </div>
  );
};
export default SimpleSearch;
