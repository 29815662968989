import { FC } from 'react';
import useNavigation from 'src/hooks/use-navigation';
import { Button } from '../../common/Button';
import './styles.scss';

type Props = {
  settings: {
    src: string;
    link: string;
    needLogin: false;
    priority: number;
    styles: Styles;
  };
};
const ExternalLinkButton: FC<Props> = ({ settings }) => {
  const { styles, link, src } = settings;

  const navigationManager = useNavigation();

  const openSupeWheelPage = (): void => {
    navigationManager.selectExternalLink(link);
  };

  return (
    <div className="external_link">
      <Button color="transparent" onClick={openSupeWheelPage} style={styles}>
        <img src={`/images/externalLinks/${src}`} alt="Link" />
      </Button>
    </div>
  );
};
export default ExternalLinkButton;
