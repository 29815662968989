import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';

const SuccessFullyReged: FC = () => {
  const { t }: Translation = useTranslation();

  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    _closeDialog({ dialogType: EnumDialogsKeys.SECCESSFULLY_REGED });
  };

  return (
    <PopUpContainer
      isOpen
      hasOverlay={false}
      title={t('confirmation')}
      description={t(`regSuccessDialog`)}
      type="default"
      handleClose={removeDialog}
      handleOK={removeDialog}
    />
  );
};

export default SuccessFullyReged;
