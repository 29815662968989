import { ChangeEvent, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { getSkinId } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { validateUsernameRequest } from 'src/services/authorizationApi';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required?: boolean;
};
const Username_Input: FC<IProps> = (props) => {
  // Hooks
  const { t }: Translation = useTranslation();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // Constants
  const usernameRegExpForLatin = useMemo(() => {
    return props.regexp ? new RegExp(props.regexp) : new RegExp('^[a-zA-Z0-9_]{3,32}$');
  }, [props.regexp]);

  const skinId = getSkinId();

  // API Calls
  const validateUsernameReq = (value: any): void => {
    validateUsernameRequest({ skinId: skinId, username: value, field: 'username' })
      .then((res) => {
        if (res.success) {
          if (res.result.duplicateUsername) {
            _setErrorReq({ ...signUpErrors, username: t('usernameAlreadyExists') });
          } else {
            resetSignUpError('username');
          }
        }
      })
      .catch(() => _setErrorReq({ ...signUpErrors, username: t('usernameAlreadyExists') }));
  };

  // YUP Validation
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required(t('requiredField'))
      .min(6, t('pleaseEnterAtLeast6Characters'))
      .matches(usernameRegExpForLatin, t('lettersNumbersOrUnderscores')),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ username: value }, { abortEarly: false });
      validateUsernameReq(value);
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, username: error.errors[0] });
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (signUpErrors.username) {
      resetSignUpError('username');
    }
    props.onChange(e);
  };

  const handleBlur = (): void => {
    handleValidate(props.value?.trim());
  };

  return <Input error={signUpErrors.username} {...props} onChange={handleOnChange} onBlur={handleBlur} />;
};
export default Username_Input;
