import cn from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { getTransactionItem } from 'src/helpers/transformers';
import { TRANSACTIONS_HEADER } from 'src/utils/constants';
import './styles.scss';

type Props = {
  data: any[];
  selectedType: string;
  subSection: string;
};
const TransactionTable: FC<Props> = ({ selectedType, data, subSection }) => {
  const { t } = useTranslation();

  const renderHeaders = useMemo(() => {
    return TRANSACTIONS_HEADER[subSection === 'casino_history' ? 'casino' : selectedType].map((header: string) => {
      return (
        <div key={header} className={cn('entries_table_caption__item', selectedType)}>
          {t(header)}
          {!isMobileOnly && ':'}
        </div>
      );
    });
  }, [selectedType, subSection]);

  const statusClasses = useCallback((f: any) => {
    return cn('table_container__item__dot', {
      ['pending']: f['status'] === 'pending',
      ['confirmed']: ['confirmed', 'completed'].includes(f['status']),
      ['rejected']: f['status'] === 'rejected',
      ['canceled']: f['status'] === 'canceled',
    });
  }, []);

  const winLossClasses = useCallback((f: number) => {
    return cn('table_container__item__dot', f >= 0 ? 'confirmed' : 'rejected');
  }, []);

  const renderTable = useCallback(
    (f: any) => {
      return (
        <div key={f._id} className={cn('data_row', selectedType)}>
          {TRANSACTIONS_HEADER[subSection === 'casino_history' ? 'casino' : selectedType].map((header: string) => {
            return getTransactionItem(header, selectedType, f, statusClasses, winLossClasses, cn);
          })}
        </div>
      );
    },
    [selectedType, subSection]
  );

  const renderList = useCallback(
    (f: any) => {
      return (
        <div key={f._id} className="list">
          <div className="entries_table_caption">{renderHeaders}</div>
          {renderTable(f)}
        </div>
      );
    },
    [selectedType, subSection]
  );

  return (
    <div className="table_container">
      <div className="entries_table_caption">{renderHeaders}</div>
      {data.length > 0 && (
        <div className="entries_table_list">{data.map((f) => (isMobile ? renderList(f) : renderTable(f)))}</div>
      )}
    </div>
  );
};

export default TransactionTable;
