import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Button';
import { ModalBase } from '../ModalBase';
import { ModalHeader } from '../ModalHeader';
import styles from './styles.module.scss';

type Props = {
  isOpenCustom?: boolean;
  bodyHeight?: 'default' | 'full';
  placement?: 'center' | 'right' | 'left';
  noPadding?: boolean;
  closeIcon?: boolean;
  className?: string[];
  onClose?: () => void;
  children: ReactNode;
  width?: 'xs' | 'sm' | 'md' | 'min-content' | 'full';
  title?: string;
  iconName?: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  submitBtnType?: 'primary' | 'secondary' | 'alert' | 'none' | 'transparent' | undefined;
  customFooter?: ReactNode;
  onSubmit?: () => void;
  onCancel?: () => void;
  image?: string;
};

export const SimpleModal: FC<Props> = ({
  isOpenCustom = false,
  cancelButtonText = 'buttons.cancel',
  submitButtonText = 'buttons.save',
  submitBtnType = 'primary',
  customFooter,
  bodyHeight,
  noPadding,
  onSubmit,
  onCancel,
  closeIcon,
  iconName,
  className = [],
  children,
  onClose,
  width,
  title = '',
  placement = 'center',
  image,
}) => {
  const { t }: any = useTranslation();

  const modalBaseProps = {
    bodyHeight,
    width,
    open: isOpenCustom,
    placement,
    className,
    src: image,
  };

  return (
    <ModalBase {...modalBaseProps}>
      {(title || closeIcon) && (
        <ModalHeader
          closeIcon={closeIcon}
          onClose={onClose}
          title={title && t(title + '_modal_title')}
          iconName={iconName}
        />
      )}
      <div
        className={cn(
          styles.simpleModal__content,
          {
            [styles.simpleModal__content_noPadding]: noPadding,
          },
          className
        )}
      >
        {children}
      </div>
      {customFooter ||
        (!!onSubmit && (
          <div className={styles.simpleModal_footer}>
            <Button color={submitBtnType} size="md" onClick={onSubmit}>
              {t(submitButtonText)}
            </Button>
            <Button className={styles.simpleModal_footer_cancelButton} color="transparent" size="md" onClick={onCancel}>
              {t(cancelButtonText)}
            </Button>
          </div>
        ))}
    </ModalBase>
  );
};
