import { FC } from 'react';
import { Typography } from '../Typography';
import './styles.scss';

type Props = {
  defaultChecked?: boolean;
  disabled?: boolean;
  onToggle: (event: any, index: number) => void;
  btnTitle: string;
  id: string;
  label?: string;
  value: boolean;
  index: number;
};

export const Toggle: FC<Props> = ({ onToggle, disabled, btnTitle, id, label, value, index }) => {
  return (
    <div className="toggle_input_container">
      <div className="toggle_input" onClick={() => !disabled && onToggle(!value, index)}>
        <input
          type="checkbox"
          id={id}
          disabled={disabled}
          checked={value}
          onClick={(ev: any) => !disabled && onToggle(ev.target.checked, index)}
        />
        <label htmlFor={label} />
      </div>
      <Typography component="p" variant={'h5'}>
        {btnTitle}
      </Typography>
    </div>
  );
};
