import cn from 'classnames';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Language from 'src/components/base-components/Language';
import { Logo } from 'src/components/base-components/Logo';
import { Button } from 'src/components/common/Button';
import { Checkbox } from 'src/components/common/Checkbox';
import { Input } from 'src/components/common/Input';
import { Typography } from 'src/components/common/Typography';
import { makeSignInPayload } from 'src/helpers/transformers';
import { loginFormValidation } from 'src/helpers/validationScheme';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { getAuth } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { setError, signIn } from 'src/store/user/actions';
import { ISignInPayload, SignInPayload } from 'src/types/req-dto/authorization';
import { ISignInRes } from 'src/types/res-dto/authorization';
import { RootState } from 'src/types/store-types';
import './styles.scss';

interface IForm extends ISignInPayload {
  rememberMe: boolean;
}
const LoginPage: FC = () => {
  const { t }: Translation = useTranslation();
  const dispatch = useDispatch();

  const _signInReq = useConnectedAction(signIn.req);
  const _setErrorReq = useConnectedAction(setError);

  const { errors: signInErrors, authLoading } = useSelector((state: RootState) => state.user);

  const [loginModalData, setLoginModalData] = useState<ISignInRes>({});

  const formik = useFormik<IForm>({
    validationSchema: loginFormValidation(t),
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      username: localStorage.getItem('userCredentials')
        ? JSON.parse(localStorage.getItem('userCredentials') || '').username
        : '',
      password: localStorage.getItem('userCredentials')
        ? JSON.parse(localStorage.getItem('userCredentials') || '').password
        : '',
      rememberMe: Boolean(localStorage.getItem('userCredentials')),
    },
    onSubmit: (values) => {
      const data: SignInPayload = makeSignInPayload(values);

      _signInReq({
        data,
        onSuccessCb: () => {
          successCb(values);
        },
        dispatch,
      });
    },
  });

  const successCb = (values: IForm): void => {
    if (values.rememberMe) {
      localStorage.setItem(
        'userCredentials',
        JSON.stringify({ username: values.username, password: values.password, rememberMe: true })
      );
    } else if (localStorage.getItem('userCredentials')) {
      localStorage.removeItem('userCredentials');
    }
  };

  const handleChange = (): void => {
    setFieldValue('rememberMe', !values.rememberMe);
  };

  const fetchAuthData = (): void => {
    getAuth().then((res: any) => {
      if (res.success) {
        setLoginModalData(res.result.signIn);
        sessionStorage.setItem(
          'authConfigs',
          JSON.stringify([
            res.result.signIn,
            res.result.forgotPassword,
            res.result.signUp,
            { login: res.result.login, registration: res.result.registration },
          ])
        );
      }
    });
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    let result = null;

    if (PersisterService.hasPersistValue('authConfigs')) {
      result = PersisterService.getPersistValue('authConfigs');

      if (result[0]) setLoginModalData(result[0]);
    } else {
      fetchAuthData();
    }
  }, []);

  useEffect(() => {
    document.body.setAttribute('style', 'overflow:hidden');
    _setErrorReq({});

    return () => {
      document.body.removeAttribute('style');
    };
  }, []);

  const { values, getFieldProps, setFieldValue, handleSubmit, errors } = formik;

  usePostMesssage((v: any) => setLoginModalData(v), 'signIn');

  return (
    <div className="login_page_container">
      <div className="login_page_inner_container">
        <div className="inner_wrapper">
          <div className="logo_container">
            <Typography className="title" variant="subtitle1">
              Welcome to
            </Typography>
            <Logo />
          </div>
          <form className="loginModal__content" onSubmit={handleSubmit}>
            {Boolean(loginModalData.username) && (
              <Input
                {...getFieldProps('username')}
                value={values.username?.trim() as string}
                label={loginModalData.username?.label ? t('user_name') : ''}
                error={loginModalData.username?.errorMessage && errors.username}
                inputHeight={40}
              />
            )}
            {Boolean(loginModalData.password) && (
              <Input
                {...getFieldProps('password')}
                type="password"
                value={values.password?.trim()}
                label={loginModalData.password?.label ? t('password') : ''}
                error={loginModalData.password?.errorMessage && errors.password}
                inputHeight={40}
                iconPosition="right"
                height="md"
              />
            )}
            {Boolean(loginModalData.rememberMe) && (
              <Checkbox
                name="rememberMe"
                value={values.rememberMe}
                onChange={handleChange}
                id="login_checkbox"
                label={loginModalData.rememberMe ? t('rememberMe') : ''}
              />
            )}
            {signInErrors.error && (
              <Typography variant={'body3'} color="error" className={'signin_error'}>
                {t(signInErrors.error)}
              </Typography>
            )}
            <Button
              className={cn('loginButton', 'brand-filled-btn')}
              variant={'text'}
              onClick={handleSubmit}
              type={'submit'}
              loading={authLoading}
            >
              {t('button_signIn')}
            </Button>
          </form>
          <div className="language_container">
            <Language />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
