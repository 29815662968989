import React, { FC } from 'react';
import { Typography } from 'src/components/common/Typography';

type Props = {
  hyperTextInfo: { view: string; title?: string | null; info: string };
};

const HyperText: FC<Props> = ({ hyperTextInfo }) => {
  return (
    <Typography
      className="label"
      component={'p'}
      variant={'h6'}
      children={
        <span
          dangerouslySetInnerHTML={{
            __html: hyperTextInfo.info,
          }}
          className="gam-care__contact-value"
        />
      }
    />
  );
};

export default HyperText;
