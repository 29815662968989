import { createReducer } from 'deox';
import produce from 'immer';
import { closeAccountModal, openMyAccountModal } from './actions';
import { accountModalState } from './types';

export const accountModaleInitialState: accountModalState = {
  visible: false,
  error: '',
  openFrom: '',
  section: '',
  subSection: '',
  parentSection: '',
  fullHeight: false,
  subTitle: '',
};

export const accountModalReducer = createReducer(accountModaleInitialState, (handle) => [
  handle(closeAccountModal, (state, {}: any) =>
    produce(state, (draft) => {
      draft.section = '';
      draft.subSection = '';
      draft.parentSection = '';
      draft.openFrom = '';
      draft.subTitle = '';
      draft.fullHeight = false;
    })
  ),
  handle(openMyAccountModal, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.section = payload.section ?? state.section;
      draft.subSection = payload.subSection ?? state.subSection;
      draft.subTitle = payload?.subTitle ?? state?.subTitle;
      draft.parentSection = payload.parentSection;
      draft.fullHeight = payload.fullHeight ?? draft.fullHeight;
      draft.openFrom = payload.from ?? '';
    })
  ),
]);
