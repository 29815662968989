import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Button } from 'src/components/common/Button';
import Dropdown from 'src/components/common/Dropdown';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { configsVariablesHandler, getDefaultTheme } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { getPallete } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { setShowUserSettings } from 'src/store/app/actions';
import { setPalette } from 'src/store/configs/actions';
import { THEMES } from 'src/utils/constants';
import './styles.scss';

const ThemeSwitcher: FC = () => {
  const _setPalette = useConnectedAction(setPalette.req);
  const _setShowUserSettings = useConnectedAction(setShowUserSettings);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [themes, setThemes] = useState<SelectOptionType[]>([]);
  const [selectedTheme, setSelectedTheme] = useState<SelectOptionType>(getDefaultTheme());

  const changeTheme = (theme: SelectOptionType): void => {
    setSelectedTheme(theme);
    localStorage.setItem('selectedPalette', theme.value as string);
    _setPalette({ theme });

    if (PersisterService.hasPersistValue('palette')) {
      const res = PersisterService.getPersistValue('palette');
      configsVariablesHandler(res[theme.value]);
    } else {
      getPallete(theme.value as string).then((res: any) => {
        if (res.result) {
          configsVariablesHandler(res.result[theme.value] ?? res.result);
          PersisterService.setPersistValue('palette', res.result);
        }
      });
    }

    if (isMobileOnly) {
      _setShowUserSettings();
      toggle();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const themeName = localStorage.getItem('selectedPalette');
    if (themeName) {
      const selectedName = {
        label: themeName,
        value: themeName,
      };
      setSelectedTheme(selectedName);
    }
  }, []);

  const toggle = (): void => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!themes.length) {
      const palletes = JSON.parse(sessionStorage.getItem('palette') as any);
      if (palletes) {
        const dynamicThemes = Object.keys(palletes).map((key: string) => ({ label: key, value: key }));
        setThemes(dynamicThemes);
      } else {
        setThemes([
          ...themes,
          ...THEMES?.map((m: string) => {
            return {
              label: m,
              value: m,
            };
          }),
        ]);
      }
    }
  }, []);

  if (isMobileOnly) {
    return (
      <div className="switcher">
        <Button className="accordion" onClick={() => toggle()}>
          <div className="category">
            <SvgIcon src="/images/icons/svg/theme.svg" className="theme_icon" />
          </div>
          <SvgIcon src={icons.arrowDown} className={cn('arrow', { ['rotateArrow']: isOpen })} />
        </Button>
        {isOpen && (
          <ul className="panel">
            {themes?.map((subSection: SelectOptionType) => {
              return (
                <li key={subSection.value} onClick={() => changeTheme(subSection)}>
                  {subSection.label}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }

  return (
    <div className="theme_container">
      <SvgIcon src="/images/icons/svg/theme.svg" className="theme_icon" />
      <Dropdown
        containerClassName="theme_dropdown"
        handleSelect={changeTheme}
        selected={selectedTheme}
        list={themes}
        variant="default"
        listClassName="secondary-ul"
      />
    </div>
  );
};
export default ThemeSwitcher;
