export const cardsData = [
  {
    title: '01. Royal Flush',
    src: 'RoyalFlush.png',
    description: 'royal_flush',
  },
  {
    title: '02. Straight flush',
    src: 'StraightFlush.png',
    description: 'straight_flush',
  },
  {
    title: '03. Four of a kind',
    src: 'FourOfKind.png',
    description: 'four_kind',
  },
  {
    title: '04. Full house',
    src: 'FullHouse.png',
    description: 'full_house',
  },
  {
    title: '05. Flush',
    src: 'Flush.png',
    description: 'flush',
  },
  {
    title: '06. Straight',
    src: 'Straight.png',
    description: 'straight',
  },
  {
    title: '07. Three of a kind',
    src: 'ThreeOfKind.png',
    description: 'three_kind',
  },
  {
    title: '08. Two pair',
    src: 'TwoPair.png',
    description: 'two_pair',
  },
  {
    title: '09. One pair',
    src: 'OnePair.png',
    description: 'one_pair',
  },
  {
    title: '10. High card',
    src: 'HighCard.png',
    description: 'high_card',
  },
];
