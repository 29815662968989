import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { setBodyOverflow } from 'src/helpers/utils';
import { useOutsideClick } from 'src/hooks/use_outside_click';
import CookiesSettings from './CookiesSetting';
import './styles.scss';

type ManagerItem = {
  title: string;
  text: string;
  btnTitle: string;
  isDisabled: boolean;
};

const ManagerData: ManagerItem[] = [
  {
    title: 'Sistem Çerezleri',
    text: ` Bu tip çerezler web sitesinin çalışabilmesi zorunludur ve kapatılamamaktadır. Genellikle, size
  gizlilik tercihlerinizi ayarlama, giriş yapma veya form doldurma gibi hizmetleri sunmak için
  kullanılırlar. Tarayıcınızı bu tip çerezleri engellemek veya kullanıldığında size uyarması için
  ayarlayabilirsiniz, ancak bu durumda sitenin bazı bölümleri çalışamayacaktır.`,
    btnTitle: 'Sistem Çerezleri',
    isDisabled: false,
  },
  {
    title: 'Tanıtım Çerezleri',
    text: ` Bu çerezler sitemiz üzerinden tanıtım ortaklarımız tarafından belirlenebilir. Bunlar, sizin ilgi
  alanlarınızın bir profilini oluşturmak ve diğer sitelerde sizinle ilgili tanıtımları göstermek için
  bu şirketler tarafından kullanılabilir. Tarayıcınızı ve internet cihazınızı benzersiz bir şekilde
  tanımlayabilirler. Bu çerezlere izin vermezseniz, sizinle ilgisiz tanıtımları görebilirsiniz.`,
    btnTitle: 'Tanıtım Çerezleri',
    isDisabled: true,
  },
];

type Props = {
  settings: {
    priority: number;
    styles: Styles;
  };
};
const CookieSettingManager: FC<Props> = ({ settings }) => {
  const { styles } = settings;

  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  const [isCookiesManagerVisiable, setIsCookiesManagerVisiable] = useState<boolean>(false);
  const [systemCookiesIsSelected, setSystemCookiesIsSelected] = useState<boolean[]>([false, false]);

  const toggleCookiesManagerModal = (): void => {
    if (isCookiesManagerVisiable) {
      setBodyOverflow('set');
    }
    setIsCookiesManagerVisiable((prev) => !prev);
  };

  const handleCookiesToggle = (value: boolean, index: number): void => {
    setSystemCookiesIsSelected({
      ...systemCookiesIsSelected,
      [index]: value,
    });
  };

  useOutsideClick(ref, toggleCookiesManagerModal, ['cookies_manager_id'], null);

  return (
    <>
      <div className="cookieSettingManager_container" style={styles}>
        <Button
          style={styles}
          className="cookies-widget primary-filled-btn"
          onClick={toggleCookiesManagerModal}
          icon={icons.cookiesManager}
          iconPosition="left"
        >
          {t('cookiesManager')}
        </Button>
      </div>
      <PopUpContainer isOpen={isCookiesManagerVisiable} hasOverlay type="fullSize">
        <div className="cookies_manager_container" ref={ref} id="cookies_manager_id">
          <div className="header">
            <Typography variant={'h3'} className="title">
              Çerez Ayarları
            </Typography>
            <SvgIcon src={icons.close} className="close_icon" onClick={toggleCookiesManagerModal} />
          </div>
          <div className="content">
            {ManagerData.map((m: ManagerItem, i: number) => {
              return (
                <CookiesSettings
                  key={m.title}
                  title={m.title}
                  text={m.text}
                  btnTitle={m.btnTitle}
                  isDisabled={m.isDisabled}
                  index={i}
                  value={systemCookiesIsSelected[i]}
                  handleChange={handleCookiesToggle}
                />
              );
            })}
          </div>
          <div className="footer">
            <Button type="button" className="primary-filled-btn" onClick={toggleCookiesManagerModal}>
              Ayarları Kaydet
            </Button>
          </div>
        </div>
      </PopUpContainer>
    </>
  );
};
export default CookieSettingManager;
