import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import _styles from './styles.module.scss';

type Props = {
  view: 'default' | 'noResult';
};
const ViewWithoutResult: FC<Props> = ({ view }) => {
  const { t } = useTranslation();
  return (
    <div className={_styles.no_result_body}>
      <div className={_styles.result_search_icon}>
        <SvgIcon src="/images/icons/svg/content_search.svg" />
      </div>
      <p className={_styles.title}>{t(view === 'default' ? 'searchDefaultTitle' : 'noResultFoundTitle')}</p>
      <p className={_styles.subTitle}>{t(view === 'default' ? 'searchDefaultText' : 'noResultFoundText')}</p>
    </div>
  );
};
export default ViewWithoutResult;
