import { FC, useEffect, useState } from 'react';
import { isIOS, isMobileOnly, isSafari } from 'react-device-detect';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setBalance, setLogout } from 'src/store/user/actions';
import SportLoader from '../SportLoader';
import NovusSportBook from './NovusSportBook';

type Props = {
  iframeSrc: FrameSrcType;
  iframeId: string;
  iframeClasses: string;
  user: any;
  selectedCategory: string;
};
const SportFrame: FC<Props> = ({ iframeSrc, iframeId, iframeClasses, user, selectedCategory }) => {
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const iframe = (document?.getElementById(iframeId) as HTMLIFrameElement)?.contentWindow;

  const [className, setClassName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const actions = (): any => {
    return {
      _openAuthModal: useConnectedAction(openAuthModal),
      _setBalance: useConnectedAction(setBalance.req),
      _setSelectedCategory: _setSelectedCategory,
      _setLogout: useConnectedAction(setLogout),
    };
  };

  useEffect(() => {
    if (!user && !!iframe) {
      iframe?.postMessage(
        JSON.stringify({
          logout: '',
        }),
        '*'
      );
    }
  }, [user, iframe]);

  useEffect(() => {
    if (isMobileOnly && isIOS) {
      if (isSafari) {
        setClassName(`${iframeClasses} safary_sport_frame`);
      } else {
        setClassName(`${iframeClasses} ios_sport_frame`);
      }
    } else {
      setClassName(iframeClasses);
    }
  }, [iframeClasses, isIOS, isMobileOnly, selectedCategory]);

  return (
    <>
      {isLoading && <SportLoader showText />}
      <NovusSportBook
        iframeSrc={iframeSrc}
        iframeClasses={className}
        id={iframeId}
        actions={actions}
        selectedCategory={selectedCategory}
        user={user}
        setIsLoading={setIsLoading}
      />
    </>
  );
};
export default SportFrame;
