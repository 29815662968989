import { FC, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { CMSManager } from 'src/manager/CMSManager';
import { MediaManager } from 'src/manager/MediaManager';
import { getStaticPagesFromCms } from 'src/services/dataApi';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setBottomNavigation } from 'src/store/configs/actions';
import { toggleMobileBottomNavigation } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
    data: { src: string; url?: string }[];
  };
};
const AdvertisementList: FC<Props> = ({ settings }) => {
  const { styles, data } = settings;

  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _toggleMobileBottomNavigation = useConnectedAction(toggleMobileBottomNavigation);
  const { bottomNavigation } = useSelector((state: RootState) => state.configs);

  const _setBottomNavigation = useConnectedAction(setBottomNavigation);

  const { locale } = useI18n();

  const imgBlock = (d: { src: string; type?: string }): JSX.Element => {
    if (d.type === 'license') {
      const openInfoAndRulesModal = (): void => {
        // need to change from turkish hardcode label to type
        _openAccountModal({ section: 'info_and_rules', subSection: 'license' });

        isMobileOnly && _toggleMobileBottomNavigation();
      };
      return (
        <img
          className={`advertisementList_img advertisementList_link`}
          key={d.src}
          src={MediaManager.getSrcFromMediaAssets(d.src, '/footerIcons/')}
          alt={d.src}
          onClick={openInfoAndRulesModal}
        />
      );
    }
    return (
      <img
        className="advertisementList_img"
        key={d.src}
        src={MediaManager.getSrcFromMediaAssets(d.src, '/footerIcons/')}
        alt={d.src}
      />
    );
  };

  const getStaticPageData = (): void => {
    if (bottomNavigation.length === 0) {
      getStaticPagesFromCms(locale).then((res) => {
        if (res?.data?.[0]) {
          const _ = CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string].getInfoAndRulesData(res.data);
          _setBottomNavigation(_);
        } else {
          console.error('bottom navigation from csm is not found');
        }
      });
    }
  };
  useEffect(() => {
    if (locale) {
      getStaticPageData();
    }
  }, [locale]);
  return (
    <div className="advertisementList_container" style={styles}>
      {data?.map((d) => {
        return (
          <>
            {d.url ? (
              <a href={d.url} target="_blank">
                {imgBlock(d)}
              </a>
            ) : (
              imgBlock(d)
            )}
          </>
        );
      })}
    </div>
  );
};
export default AdvertisementList;
