import { ISagaReqStructure } from '../../types/store-types';

export interface IFirstDepositToWallet extends ISagaReqStructure {
  data: {
    bonusConfigId: string;
    isParticipating: boolean;
  };
}

export const FIRST_DEPOSIT_TO_BONUS_REQUEST = 'bonus-messaging/FIRST_DEPOSIT_TO_BONUS_REQUEST';
export const FIRST_DEPOSIT_TO_BONUS_SUCCESS = 'bonus-messaging/FIRST_DEPOSIT_TO_BONUS_SUCCESS';
export const FIRST_DEPOSIT_TO_BONUS_FAILURE = 'bonus-messaging/FIRST_DEPOSIT_TO_BONUS_FAILURE';
