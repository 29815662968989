import React, { FC } from 'react';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';

type Props = {
  data: any;
};

const TransactionInfo: FC<Props> = ({ data }) => {
  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    _closeDialog({ dialogType: EnumDialogsKeys.MAIN_TO_POKER_TRANSACTION });
  };

  return (
    <PopUpContainer
      isOpen
      hasOverlay={false}
      title={data.message}
      type="default"
      handleClose={removeDialog}
      handleOK={removeDialog}
    >
      <div>
        <SvgIcon src={icons.mail} />
      </div>
    </PopUpContainer>
  );
};

export default TransactionInfo;
