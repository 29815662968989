import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import _styles from '../../styles.module.scss';

const steps: number[] = [1, 2, 3];

const Steps: FC = () => {
  const { t } = useTranslation();

  const step = useCallback((stepinfo: any): JSX.Element => {
    return (
      <div className={_styles.step}>
        <div className={_styles.step_info}>
          <span>{stepinfo}</span>
          <SvgIcon src={`/images/refer_a_friend/step_${stepinfo}.svg`} />
        </div>
        <p>{t(`refer_a_friend.step_${stepinfo}`)}</p>
      </div>
    );
  }, []);

  return (
    <div className={_styles.steps}>
      {steps.map((s: number) => (
        <Fragment key={s}>{step(s)}</Fragment>
      ))}
    </div>
  );
};
export default Steps;
