import cn from 'classnames';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import GameCard from 'src/components/common/GameCard';
import { GAME_CARD_SIZES } from 'src/utils/constants';
import '../styles.scss';

type Props = {
  activeGames: any;
  viewAllGamesOfTag: any;
  tagName: string;
};
const Template3: FC<Props> = ({ activeGames, viewAllGamesOfTag, tagName }) => {
  const { t }: Translation = useTranslation();
  return (
    <div className={cn('game_container', 'template_3')}>
      <div className="game_container_header">
        <div className="game_container_header_title_with_arrows">
          <Button ariaLabelledby="arrow" type="button" onClick={viewAllGamesOfTag} className="tag_title">
            {t(tagName)}
          </Button>
        </div>
        <Button type="button" onClick={viewAllGamesOfTag} className="viewAll">
          {t('viewAll')}
        </Button>
      </div>
      <div
        className="template_3_carousel_conatiner"
        style={{ height: isMobileOnly ? 'auto' : GAME_CARD_SIZES['md_s'] + 20 }}
      >
        {activeGames?.map((game: any, i: number) => {
          return (
            <div id={game.id} key={game.id}>
              <GameCard
                key={game.name + i}
                data={game}
                showDetails={false}
                size={isMobileOnly ? 'sm' : 'md_s'}
                useRatio
                effects={{
                  hoverType: 'fullWidthImage',
                  src: '',
                  text: '',
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Template3;
