import cn from 'classnames';
import { FC, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import '../../styles.scss';
import { NavItem } from '../NavItem/NavItem';

enum EnumHoverTypes {
  UNDERLINED = 'underlined',
  COLORED = 'colored',
  FILLED = 'filled',
}

type Props = {
  selectedCategory: string;
  view: any;
  withUnderLine: any;
  menuItems: any;
  changeNavigation: (category: NavigationItem) => void;
  items: any[];
};
export const NavDropdown: FC<Props> = ({
  selectedCategory,
  view,
  withUnderLine,
  items,
  menuItems,
  changeNavigation,
}) => {
  const { t }: Translation = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hoverStyle: string = EnumHoverTypes.UNDERLINED;

  const conditionalClassNames = {
    ['navItem_placement_top']: view.placement === 'top',
    ['navItem_placement_right']: view.placement === 'right',
    ['navItem_placement_bottom']: view.placement === 'bottom',
    ['navItem_placement_left']: view.placement === 'left',
    ['navItem_active']: isOpen,
    ['open']: isOpen ? 'open' : '',
  };

  return (
    <div
      className={cn('dropdown-nav-menu nav_item', hoverStyle, conditionalClassNames)}
      onMouseOver={() => isDesktop && setIsOpen(true)}
      onMouseOut={() => isDesktop && setIsOpen(false)}
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <span className="nav_item--first-children more_nav_item">
        {view.type.includes('withIcon') && <SvgIcon src={`/images/navigation-icons/more.svg`} className="more_icon" />}
        <span>{t('more')}</span>
      </span>
      <div className="dropdown-menu-list">
        <div className="dropdown-menu-list__items">
          {items.map((navItem: NavigationItem, idx: number) => (
            <NavItem
              key={idx}
              selectedCategory={selectedCategory}
              view={view}
              navItem={navItem}
              withUnderLine={withUnderLine}
              idx={idx}
              menuItems={menuItems}
              changeNavigation={changeNavigation}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
