/* eslint-disable sonarjs/no-duplicate-string */
import { isMobile } from 'react-device-detect';
import { setParams } from 'src/helpers/utils';
import { gameUrlRequest } from 'src/services/gameApi';
import { GameParamType } from 'src/types/req-dto/game';
import './styles.scss';

export class IframeUrl {
  public static getTounamentFrameData = (
    skinId: number,
    user: any,
    language: string,
    websiteName: string
  ): Promise<FrameDataType> => {
    const siteName = `websiteName=${websiteName}`;
    const lang = `lang=${language}`;
    const skin = `skinId=${skinId}`;
    const userInfo = user ? `userId=${user?.id}&username=${user?.username}&token=${user?.token}` : '';
    const tournamentModule = sessionStorage.getItem('casinoTournament');
    const showFlags = 'showFlags=' + process.env.REACT_APP_TOURNAMENT_MODULE_SHOW_FLAGS || 'true';
    const formatCurrency = process.env.REACT_APP_FORMAT_CURRENCY ? '&showFormattedAmountWithDot=true' : '';

    return new Promise((resolve) => {
      resolve({
        urls: {
          loggedIn: `${tournamentModule}?${skin}&${userInfo}&${lang}&${siteName}&${showFlags}${formatCurrency}`,
          loggedOut: `${tournamentModule}?${skin}&${lang}&${siteName}&${showFlags}${formatCurrency}`,
        },
        id: 'tournament',
        classes: 'frame',
      });
    });
  };

  public static getRaceFrameData = (user: any): Promise<FrameDataType> => {
    const raceModule = sessionStorage.getItem('race');
    const playerToken = user ? `playerToken=${user.token}` : '';
    const formatCurrency = process.env.REACT_APP_FORMAT_CURRENCY ? '&showFormattedAmountWithDot=true' : '';

    return new Promise((resolve) => {
      resolve({
        urls: {
          loggedIn: `${raceModule}&${playerToken}${formatCurrency}`,
          loggedOut: `${raceModule}&${formatCurrency}`,
        },
        id: 'raceIframe',
        classes: 'frame',
      });
    });
  };

  public static getBlogFrameData = (
    language: string,
    url: any,
    skinId: number,
    encodedTheme: string
  ): Promise<FrameDataType> => {
    const skin = `skinId=${skinId}`;
    const theme = `theme=${encodedTheme}`;
    return new Promise((resolve) => {
      resolve({
        urls: {
          loggedIn: `${url}/${language}?${skin}&${theme}`,
          loggedOut: `${url}/${language}?${skin}&${theme}`,
        },
        id: 'blogIframe',
        classes: 'blogFrame frame',
      });
    });
  };

  public static getFunMissionData = (skinId: number, user: any, language: string, url: any): Promise<FrameDataType> => {
    const lang = `language=${language}`;
    const skin = `skinId=${skinId}`;
    const playerToken = user ? `token=${user.token}` : '';
    const missionId = 'missionId=' + localStorage.getItem('missionId');
    const formatCurrency = process.env.REACT_APP_FORMAT_CURRENCY ? '&showFormattedAmountWithDot=true' : '';

    return new Promise((resolve) => {
      resolve({
        urls: {
          loggedIn: `${url}?${playerToken}&${lang}&${skin}&${missionId}${formatCurrency}`,
          loggedOut: `${url}?${skin}&${lang}${formatCurrency}`,
        },
        id: 'funMissionIframe',
        classes: 'funMission frame',
      });
    });
  };

  public static getPokerFrameData = (skinId: number, user: any, language: string, url: any): Promise<FrameDataType> => {
    const lang = `lang=${language}`;
    const skin = `skinId=${skinId}`;
    const username = user ? `username=${user.username}` : '';
    const playerToken = user ? `token=${user.token}` : '';
    const formatCurrency = process.env.REACT_APP_FORMAT_CURRENCY ? '&showFormattedAmountWithDot=true' : '';

    return new Promise((resolve) => {
      resolve({
        urls: {
          loggedIn: `${url}?${username}&${skin}&${playerToken}&${lang}${formatCurrency}`,
          loggedOut: `${url}?${skin}&${lang}${formatCurrency}`,
        },
        id: 'pokerIframe',
        classes: 'frame',
      });
    });
  };

  public static getGameFrameData = async (
    skinId: number,
    user: any,
    language: string,
    game: any,
    type: string,
    classes: string,
    onFail?: () => void
  ): Promise<FrameDataType | void> => {
    try {
      const params: GameParamType = setParams(user, game, skinId, language, isMobile);
      const res = await gameUrlRequest(params);

      return {
        urls: {
          loggedIn: res.result.data.url,
          loggedOut: res.result.data.url,
        },
        id: type,
        classes,
      };
    } catch (e) {
      console.log(e);
      onFail && onFail();
    }
  };

  public static getSportFrameData = async (
    user: any,
    skinId: number,
    language: string,
    game: any,
    className: string
  ): Promise<FrameDataType | null> => {
    const params: GameParamType = setParams(user, game, skinId, language, isMobile);
    if (!game) return null;
    const res = await gameUrlRequest(params);
    return {
      urls: {
        loggedIn: res?.result.data.url ?? '',
        loggedOut: res?.result.data.url ?? '',
      },
      id: 'sport',
      classes: `frame ${className}`,
    };
  };

  public static getCyberSportFrameData = async (
    user: any,
    skinId: number,
    language: string,
    game: any,
    className: string
  ): Promise<FrameDataType | null> => {
    const params: GameParamType = setParams(user, game, skinId, language, isMobile);
    if (!game) return null;
    const res = await gameUrlRequest(params);
    return {
      urls: {
        loggedIn: res?.result.data.url ?? '',
        loggedOut: res?.result.data.url ?? '',
      },
      id: 'cybersport',
      classes: `frame ${className}`,
    };
  };
}
