import cn from 'classnames';
import { FC } from 'react';
import { Typography } from 'src/components/common/Typography';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { MediaManager } from 'src/manager/MediaManager';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    image: string;
    styles: Styles;
    text: { [key: string]: string };
    classes: string;
  };
};
const AgeInfo: FC<Props> = ({ settings }) => {
  const { image, styles, text, classes } = settings;

  const { locale } = useI18n();

  return (
    <div className={cn('ageInfo_container', classes)} style={styles}>
      <img src={MediaManager.getSrcFromMediaAssets(image, '/footerIcons/')} alt="Age info" />
      <Typography className="label" variant={'h6'}>
        {text?.[locale]}
      </Typography>
    </div>
  );
};
export default AgeInfo;
