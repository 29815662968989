import { Pagination } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'src/components/common/Loader';
import { loginHistoryRequest } from 'src/services/userApi';
import { HISTORY_REQ_LIMIT, TABLE_CAPTION_ITEMS } from 'src/utils/constants';
import LoginHistoryItem from './LoginHistoryItem';
import styles from './styles.module.scss';

const LoginHistory: FC = () => {
  const { t }: Translation = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [loginHistoryData, setLoginHistoryData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const changePageHandler = (page: number): void => {
    setPage(page);
  };

  const memorizedItem = useCallback(
    (item: any): { status: 'approved' | 'declined'; date: string } => ({
      status: item?.response?.success ? 'approved' : 'declined',
      date: `${moment(item?.timestamp).format('MM/DD/YYYY')}, ${new Date(item?.timestamp).getHours()}:${
        (new Date(item?.timestamp).getMinutes() < 10 ? '0' : '') + new Date(item?.timestamp).getMinutes()
      }`,
    }),
    [loginHistoryData]
  );

  const getLoginHistory = (): void => {
    setLoading(true);

    loginHistoryRequest({ limit: HISTORY_REQ_LIMIT, page })
      .then((res) => {
        setLoginHistoryData(res.result.loginHistory);
        setCount(res.result.count);
        setTimeout(() => setLoading(false), 500);
      })
      .catch(() => setTimeout(() => setLoading(false), 500));
  };

  useEffect(() => {
    getLoginHistory();
  }, [page]);

  return (
    <div className={styles.login_history_wrapper}>
      <div className={cn(styles.entries_table_caption, 'entries_table_caption')}>
        {TABLE_CAPTION_ITEMS.map((item) => (
          <div key={item} className={cn(styles.entries_table_caption__item, 'entries_table_caption__item')}>
            {t(item)}:
          </div>
        ))}
      </div>
      <div className={styles.entries_table_list}>
        {loginHistoryData.length > 0 &&
          !loading &&
          loginHistoryData?.map((item: any) => (
            <LoginHistoryItem
              key={item?.timestamp}
              date={memorizedItem(item).date}
              status={memorizedItem(item).status}
              dotStatus={item?.response?.success}
              ipAddress={item?.clientIp}
              t={t}
            />
          ))}
        {loading && (
          <div className={styles.loader_wrapper}>
            <Loader size="lg" />
          </div>
        )}
      </div>
      <div className={styles.entries_table_pagination}>
        {count > HISTORY_REQ_LIMIT && (
          <Pagination
            defaultCurrent={1}
            pageSize={HISTORY_REQ_LIMIT}
            total={count}
            showSizeChanger={false}
            current={page}
            onChange={changePageHandler}
            className={styles.pagination}
          />
        )}
      </div>
    </div>
  );
};
export default LoginHistory;
