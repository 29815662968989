import cn from 'classnames';
import { CSSProperties, FC, ReactNode } from 'react';
import { Loader } from '../Loader';
import { SvgIcon } from '../SvgIcon';
import { Typography } from '../Typography';
import styles from './styles.module.scss';

type Props = {
  fontWeight?: 'default' | 'bold' | '600';
  uppercase?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: (ev?: any) => void | undefined | string | any;
  children?: ReactNode;
  variant?: 'contained' | 'outlined' | 'text' | 'fit';
  loading?: boolean;
  height?: any;
  width?: any;
  color?: 'primary' | 'secondary' | 'alert' | 'none' | 'transparent';
  style?: CSSProperties;
  type?: 'button' | 'submit';
  size?: 'lg' | 'md' | 'sm' | 'xsm';
  id?: string;
  icon?: string;
  iconPosition?: undefined | 'left' | 'right';
  iconOrientation?: 'left' | 'right';
  minWidth?: boolean;
  justifyContentValue?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  ariaLabelledby?: string;
};

const iconColors = {
  primary: 'white',
  secondary: 'main',
  none: 'main',
};

export const Button: FC<Props> = ({
  fontWeight = 'default',
  uppercase,
  className,
  disabled,
  children,
  onClick,
  variant = 'contained',
  loading,
  style,
  color = 'primary',
  type = 'button',
  size = 'lg',
  id = '',
  icon,
  iconPosition = 'left',
  justifyContentValue = 'center',
  ariaLabelledby = '',
}) => {
  const iconColor: string = iconColors[color];

  return (
    <button
      aria-label={ariaLabelledby}
      id={id}
      className={cn(
        styles.button,
        styles[`button__justify_content__${justifyContentValue}`],
        {
          [styles[`button_fontWeight_${fontWeight}`]]: fontWeight,
          [styles[`button_variant_${variant}`]]: variant,
          [styles[`button_size_${size}`]]: size,
          [styles.button_disabled]: disabled,
        },
        className
      )}
      disabled={disabled || loading}
      onClick={loading || disabled ? () => undefined : onClick}
      style={style}
      type={type}
    >
      {icon && iconPosition === 'left' && (
        <div className={styles.button__icon}>
          <SvgIcon pointer src={icon} color={iconColor} />
        </div>
      )}
      {loading && (
        <div className="flexible vertical">
          <Loader color="primary" size="sm" />
        </div>
      )}

      <Typography
        uppercase={uppercase}
        component="div"
        variant="inherit"
        color="inherit"
        display="flex"
        fontWeight={fontWeight}
        className={cn(styles.button__content__typography, styles[`button__justify_content__${justifyContentValue}`])}
      >
        {children}
      </Typography>
      {icon && iconPosition === 'right' && (
        <div className={styles.button__right_icon}>
          <SvgIcon pointer src={icon} color={iconColor} />
        </div>
      )}
    </button>
  );
};
