import { createReducer } from 'deox';
import produce from 'immer';
import { CURRENCY_SYMBOLS } from 'src/utils/constants';
import {
  setEgtGamesStats,
  setGamesTotalCount,
  setSelectedGame,
  setSelectedProvider,
  setSelectedTag,
  setTags,
} from './actions';
import { gamesState } from './types';

export const gamesInitialState: gamesState = {
  tags: null,
  selectedTag: localStorage.getItem('selectedTag') ?? '',
  selectedProvider: localStorage.getItem('selectedProvider')?.split(',') ?? ['all_providers'],
  game: localStorage.getItem('game') ?? null,
  gamesTotalCount: 0,
  egtGamesStats: null,
};

export const gamesReducer = createReducer(gamesInitialState, (handle) => [
  handle(setEgtGamesStats, (state, { payload }: any) =>
    produce(state, (draft) => {
      const stats: any = [];

      payload.data.jackpotInstancesStats.instanceStats.forEach((instance: any) => {
        const gameNames = instance.games;
        const brlValueObject = instance.levelStats[0].currentValue.find((value: any) => value.key === payload.currency);
        if (brlValueObject) {
          stats.push({ key: gameNames, value: `${CURRENCY_SYMBOLS[payload.currency]} ${brlValueObject.value}` });
        }
      });

      draft.egtGamesStats = stats;
    })
  ),
  handle(setTags, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.tags = payload;
    })
  ),
  handle(setSelectedTag, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.selectedTag = payload;
      localStorage.setItem('selectedTag', payload);
    })
  ),
  handle(setSelectedProvider, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.selectedProvider = Array.isArray(payload) ? payload : [payload];
      localStorage.setItem('selectedProvider', payload);
    })
  ),
  handle(setSelectedGame, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.game = payload;
    })
  ),
  handle(setGamesTotalCount, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.gamesTotalCount = payload;
    })
  ),
]);
