export type integrationsState = {
  activeWageringMissions: {
    description: string;
    expireDate: string;
    missionState: string;
    bonusType: string;
    balance: number;
    currency: string;
    freespinsCount: number;
    missionName: string;
    coefficient: number;
    progress: number;
    maxFreespinsCount: number;
    missionId: string;
  } | null;
  freeSpinGames: any;
  gameBoxMissions: any;
  jackpotTickets: any;
  actualMissions: number;
  forceCloseGameBoxState: boolean;
  showTournamentPage: boolean;
  selectedTournamentGame: any;
  tournamentGame: any;
};

export const INTEGRATIONS_SET_MISSIONS_REQ = 'integrations/INTEGRATIONS_SET_MISSIONS_REQ';
export const INTEGRATIONS_SET_MISSIONS_SUCCESS = 'integrations/INTEGRATIONS_SET_MISSIONS_SUCCESS';
export const INTEGRATIONS_SET_MISSIONS_FAIL = 'integrations/INTEGRATIONS_SET_MISSIONS_FAIL';

export const INTEGRATIONS_SET_GAME_BOX_MISSION_REQ = 'integrations/INTEGRATIONS_SET_GAME_BOX_MISSION_REQ';
export const INTEGRATIONS_SET_GAME_BOX_MISSION_SUCCESS = 'integrations/INTEGRATIONS_SET_GAME_BOX_MISSION_SUCCESS';
export const INTEGRATIONS_SET_GAME_BOX_MISSION_FAIL = 'integrations/INTEGRATIONS_SET_GAME_BOX_MISSION_FAIL';

export const INTEGRATIONS_SET_JACKPOT_MISSIONS = 'integrations/INTEGRATIONS_SET_JACKPOT_MISSIONS';

export const INTEGRATIONS_SET_FREE_SPIN_GAMES_REQ = 'integrations/INTEGRATIONS_SET_FREE_SPIN_GAMES_REQ';
export const INTEGRATIONS_SET_FREE_SPIN_GAMES_SUCCESS = 'integrations/INTEGRATIONS_SET_FREE_SPIN_GAMES_SUCCESS';
export const INTEGRATIONS_SET_FREE_SPIN_GAMES_FAIL = 'integrations/INTEGRATIONS_SET_FREE_SPIN_GAMES_FAIL';

export const INTEGRATIONS_SET_EXTERNAL_TOURNAMENT_GAME = 'integrations/INTEGRATIONS_SET_EXTERNAL_TOURNAMENT_GAME';

export const SET_EXTERNAL_TOURNAMENT_GAME = 'integrations/SET_EXTERNAL_TOURNAMENT_GAME';

export const TOGGLE_TOURNAMENT_MODULE_PAGE = 'integrations/TOGGLE_TOURNAMENT_MODULE_PAGE';

export const INTEGRATIONS_FORCE_CLOSE_GAMEBOX = 'integrations/INTEGRATIONS_FORCE_CLOSE_GAMEBOX';

export const INTEGRATIONS_SET_ACTUAL_MISSIONS_COUNT = 'integrations/INTEGRATIONS_SET_ACTUAL_MISSIONS_COUNT';
