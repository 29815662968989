import { useEffect, useState } from 'react';

export function useDetectIosFs(): boolean {
  const detectFS = !!window && window.orientation === 0 && window.outerHeight - window.innerHeight > 108 ? false : true;

  const [fsState, setFSState] = useState<boolean>(detectFS);

  useEffect(() => {
    const handleResize = (): void => {
      setFSState(detectFS);
    };

    const orientationChange = (): void => {
      setFSState(detectFS);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', orientationChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', orientationChange);
    };
  }, []);

  return fsState;
}
