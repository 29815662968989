import React from 'react';
import { Components } from 'src/configs/components/components';

export class Constructor {
  public static renderWidgets = (settings: { [key: string]: any }, idx: number, user: any): any => {
    if (!Object.keys(settings).length) {
      return <></>;
    }

    const orderIndex = {};

    Object.keys(settings).forEach((key: string) => (orderIndex[`${key}`] = settings[key].priority));

    return Object.keys(settings)
      .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
      .filter((key: string) => key !== 'styles')
      .map((key: string, index: number) => {
        if (key === 'authorization' && !!user) {
          return;
        }

        if (settings[key].needLogin && !user) {
          return;
        }

        return React.createElement(Components[key], {
          settings: settings[key],
          placement: 'header',
          key: `${key}_${index + idx}`,
        });
      });
  };
}
