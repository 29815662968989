import cn from 'classnames';
import { FC } from 'react';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import '../styles.scss';

type ArrowProps = {
  placement: string; // 'center' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom'
  type: string; // 'default' | 'roundedLight' | 'roundedDark' | 'squaredDark'
  slide: () => void;
  disabled?: boolean;
  style?: string;
};
export const LeftArrow: FC<ArrowProps> = ({ placement, type, slide, disabled, style }) => {
  return (
    <Button
      ariaLabelledby="arrow"
      disabled={disabled}
      type="button"
      className={cn('arrow-left arrow', style, type, 'btn_arrow__left', {
        ['disabled']: disabled,
      })}
      onClick={slide}
    >
      {placement === 'center' ? <SvgIcon src={'/images/icons/svg/banner_left_arrow.svg'} /> : <p>&#8592;</p>}
    </Button>
  );
};

export const RightArrow: FC<ArrowProps> = ({ placement, type, slide, disabled = false, style }) => {
  return (
    <Button
      ariaLabelledby="arrow"
      disabled={disabled}
      type="button"
      className={cn('arrow-right arrow', style, type, 'btn_arrow__right', {
        ['disabled']: disabled,
      })}
      onClick={slide}
    >
      {placement === 'center' ? <SvgIcon src={'/images/icons/svg/banner_right_arrow.svg'} /> : <p>&#8594;</p>}
    </Button>
  );
};

type ArrowsProps = {
  placement: string;
  type: string;
  rows: number;
  prevClick?: () => void;
  nextClick?: () => void;
  template?: string;
  size?: 'md' | 'lg';
};

export const Arrows: FC<ArrowsProps> = ({ placement, prevClick, nextClick, size = 'lg' }) => {
  if (placement.includes('center')) {
    return (
      <div className={cn('banner_centered_arrows', placement)}>
        <div className="btn_container">
          <Button
            ariaLabelledby="arrow"
            type="button"
            className={cn('arrow-left arrow left', size)}
            onClick={prevClick}
          >
            <SvgIcon className="arrow_icon" src={'/images/icons/svg/filled_arrow.svg'} />
          </Button>
          <Button
            ariaLabelledby="arrow"
            type="button"
            className={cn('arrow-right arrow right', size)}
            onClick={nextClick}
          >
            <SvgIcon className="arrow_icon" src={'/images/icons/svg/filled_arrow.svg'} />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn('banner_small_arrows', {
        ['leftTop']: placement === 'leftTop',
        ['leftBottom']: placement === 'leftBottom',
        ['rightTop']: placement === 'rightTop',
        ['rightBottom']: placement === 'rightBottom',
      })}
    >
      <div className="arrows_wrapper">
        <Button ariaLabelledby="arrow" type="button" className="arrow-left arrow" size="md" onClick={prevClick}>
          <SvgIcon src={'/images/icons/svg/banner_left_arrow.svg'} />
        </Button>
        <Button ariaLabelledby="arrow" type="button" className="arrow-right arrow" size="md" onClick={nextClick}>
          <SvgIcon src={'/images/icons/svg/banner_right_arrow.svg'} />
        </Button>
      </div>
      <div className="arrows_wrapper_overlay"></div>
    </div>
  );
};
