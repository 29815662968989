import { FC } from 'react';
import { MediaManager } from 'src/manager/MediaManager';
import { Button } from '../../common/Button';
import './styles.scss';

interface IDownloadApp {
  src: string;
  downloadLink: string;
}
type Props = {
  settings: {
    needLogin: false;
    priority: number;
    styles: Styles;
    data: IDownloadApp[];
  };
};
const MobileApp: FC<Props> = ({ settings }) => {
  const { styles, data } = settings;

  const downloadApp = (app: string): void => {
    window.open(app, '_blank', 'noreferrer');
  };

  return (
    <div className="download_container">
      {data?.map((appData: IDownloadApp) => {
        return (
          <Button
            key={appData.src}
            color="transparent"
            className="btn"
            onClick={() => downloadApp(appData.downloadLink)}
            style={styles}
          >
            <img src={MediaManager.getSrcFromMediaAssets(appData.src, '/footerIcons/')} alt="ink" />
          </Button>
        );
      })}
    </div>
  );
};
export default MobileApp;
