import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Typography } from 'src/components/common/Typography';
import '../styles.scss';
import ArticleDropdown from './ArticleDropdown';
import HelpCenterNavigation from './HelpCenterNavigation';

type Props = {
  t: Translation;
  articles: any[];
  selectedSubSection: any;
  selectedSection: any;
  setSelectedSubSection: (section: any) => void;
};
const HelpCenterArticles: FC<Props> = ({
  t,
  articles = [],
  selectedSection,
  selectedSubSection,
  setSelectedSubSection,
}) => {
  const [expandedArticle, setExpandedArticle] = useState<number | { title: string; content: string }[]>(-1);

  useEffect(() => {
    setExpandedArticle(selectedSection ?? articles[0]);
  }, [selectedSection]);

  return (
    <div className="help-center__articles__holder">
      <HelpCenterNavigation setSelectedSubSection={setSelectedSubSection} t={t} articles={articles} showSearchbar />
      <div
        className={cn({
          'help-center__articles': true,
          'help-center__articles--opened': selectedSection,
        })}
      >
        <div className="help-center__articles__list">
          {expandedArticle &&
            articles?.map((item, i) => (
              <ArticleDropdown
                key={i}
                t={t}
                article={item}
                expandedArticle={expandedArticle}
                setExpandedArticle={setExpandedArticle}
                selectedArticle={selectedSection}
                setSelectedArticle={setSelectedSubSection}
                selectedSubSection={selectedSubSection}
              />
            ))}
        </div>
        {selectedSubSection && (
          <div className="help-center__articles--selected">
            <Typography className="text" variant={'h3'}>
              {selectedSubSection?.data.title}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: selectedSubSection?.data.content }} />
            {selectedSection?.data?.table && (
              <table>
                <thead>
                  <tr>
                    {selectedSection.data.table.header.map((headerItem: any, i: number) => (
                      <th key={i}>{t(headerItem)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {selectedSection.data.table.body.map((bodyRow: any, i: number) => (
                    <tr key={i}>
                      {bodyRow.map((bodyRowItem: any) => (
                        <td>{t(bodyRowItem)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default HelpCenterArticles;
