import cn from 'classnames';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { getHeaderConfigs } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { setGeneraLoading } from 'src/store/app/actions';
import { setHeaderConfigs } from 'src/store/configs/actions';
import { RootState } from 'src/types/store-types';
import { Constructor } from './constructor';
import './styles.scss';

const MobileHeaderContructor: FC = () => {
  const _setGeneraLoading = useConnectedAction(setGeneraLoading);
  const _setHeaderConfigs = useConnectedAction(setHeaderConfigs);

  const { user } = useSelector((state: RootState) => state.user);

  const [headerTemplate, setHeaderTemplate] = useState<string>('header-1Row');
  const [configs, setConfigs] = useState<HeaderTopLine>({
    topLine: {
      type: '',
      sections: {},
      styles: {},
    },
    bottomLine: {
      type: '',
      sections: {},
      styles: {},
    },
  });

  const widgetsTopArr = Object.values(configs?.topLine?.sections || {});
  const widgetsBottomArr = Object.values(configs?.bottomLine?.sections || {});

  const renderWidgets = useCallback(
    (settings: { [key: string]: any }, idx: number): JSX.Element => {
      return <Fragment key={idx}>{Constructor.renderWidgets(settings, idx, user)}</Fragment>;
    },
    [configs, user]
  );

  const getHeaderConf = async (): Promise<void> => {
    if (PersisterService.hasPersistValue('mobileHeader')) {
      const res = PersisterService.getPersistValue('mobileHeader');
      _setHeaderConfigs(res.template);
      setConfigs(res.sections);
      setHeaderTemplate(res.template);
    } else {
      const res = await getHeaderConfigs('mobile');

      if (res.success) {
        _setGeneraLoading(true);
        _setHeaderConfigs(res.result.template);
        setConfigs(res.result.sections);
        setHeaderTemplate(res.result.template);
        PersisterService.setPersistValue('mobileHeader', res.result);
      } else {
        _setGeneraLoading(false);
      }
    }
  };

  useEffect(() => {
    getHeaderConf();
  }, []);

  usePostMesssage((v: any) => setConfigs(v), 'mobileHeader');

  return (
    <>
      {configs?.topLine?.sections && (
        <div className={cn('mobile_header_wrapper', headerTemplate)}>
          {widgetsTopArr.map((obj: TypeObject, i: number) => {
            return (
              <div className="header_row top_row" key={`mobile_widget_${i}`}>
                {renderWidgets(obj, i)}
              </div>
            );
          })}
          {widgetsBottomArr.map((obj: TypeObject, i: number) => {
            return (
              <div className="header_row bottom_row" key={`mobile_widget_${i}`}>
                {renderWidgets(obj, i)}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default MobileHeaderContructor;
