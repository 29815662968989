import cn from 'classnames';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import './styles.scss';

type Props = {
  speed: string; //fast|slow
};
export const CSLoader: FC<Props> = ({ speed }) => {
  if (isMobileOnly) {
    return (
      <div className={'cs_loader'}>
        <div className={cn('cs_loader_inner', speed)}>
          <label>●</label>
          <label>●</label>
          <label>●</label>
        </div>
      </div>
    );
  }

  return (
    <div className={'cs_loader'}>
      <div className={cn('cs_loader_inner', speed)}>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
        <label>●</label>
      </div>
    </div>
  );
};
