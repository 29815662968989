import cn from 'classnames';
import { FC, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { icons } from 'src/configs/icons';
import styles from './styles.module.scss';

export type Props = {
  className?: string;
  color?: 'primary' | 'secondary' | 'onSecondary';
  size?: 'sm' | 'md' | 'lg';
  loadingText?: string;
};

export const Loader: FC<Props> = ({ size }) => {
  const _size = useMemo(() => {
    if (!size) {
      return isMobileOnly ? 'sm' : 'lg';
    }
    return size;
  }, [size]);

  return (
    <div className={cn(styles.loader, styles[_size])}>
      <img src={icons.loading} className={styles.loader_inner} />
    </div>
  );
};
