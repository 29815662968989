import cn from 'classnames';
import { forwardRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Typography } from 'src/components/common/Typography';
import SignUpContructor from 'src/constructors/SignUp/SignUpConstructor';
import './styles.scss';

type Prop = {
  formValues: any;
  activeStep: number;
  affiliateId: string | null;
  styles: Record<string, string> | undefined;
  forgotLink: boolean | undefined;
  signIn: boolean | undefined;
  changeContent: (content: 'forgot' | 'login') => () => void;
  signUpErrors: { [key: string]: string };
  registrationSteps: any;
  renderSteperNav: () => void;
};
type Ref = HTMLDivElement;

const SignupForm = forwardRef<Ref, Prop>(
  (
    {
      formValues,
      activeStep,
      affiliateId,
      styles,
      forgotLink,
      signIn,
      changeContent,
      signUpErrors,
      registrationSteps,
      renderSteperNav,
    },
    ref
  ) => {
    const { t }: Translation = useTranslation();

    return (
      <div className={cn('form_wrapper', `step_${activeStep}`)} ref={isMobileOnly ? null : ref}>
        {formValues.length && (
          <SignUpContructor
            settings={{ registrationSteps: formValues, affiliateId, styles }}
            step={activeStep}
            ref={ref}
            children={
              <>
                {forgotLink && <Button onClick={changeContent('forgot')}>{t('button_forgot')}</Button>}
                {signIn && <Button onClick={changeContent('login')}>{t('button_signIn')}</Button>}
                {signUpErrors.error && <Typography variant={'body3'}>{signUpErrors.error}</Typography>}
                {Boolean(registrationSteps?.length) ? renderSteperNav() : null}
                <Button className="loginModal__content__signUpButton" variant={'text'} onClick={changeContent('login')}>
                  <span>{t('account')}</span> {t('login')}
                </Button>
              </>
            }
          />
        )}
      </div>
    );
  }
);

export default SignupForm;
