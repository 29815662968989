import { FC } from 'react';
import { SvgIcon } from '../SvgIcon';
import './styles.scss';

type Props = {
  amount: number;
};

const VisualCurrencyIcon: FC<Props> = ({ amount }) => {
  return (
    <div className={'coins_wrapper'}>
      <SvgIcon src={`/images/icons/svg/visualCurrency.svg`} className="visual-currency" />
      {amount?.toFixed()}
    </div>
  );
};
export default VisualCurrencyIcon;
