import { Action, combineReducers } from 'redux';
import { RootState } from '../types/store-types';
import { accountModalReducer } from './account-modal/reducer';
import { appReducer } from './app/reducer';
import { authModalReducer } from './auth-modal/reducer';
import { configsReducer } from './configs/reducer';
import { dialogManagerReducer } from './dialog/reducers';
import { gamesReducer } from './games/reducer';
import { integrationsReducer } from './integrations/reducer';
import { messagesReducer } from './messages/reducer';
import { mobileReducer } from './mobile/reducer';
import { tltWidgetReducer } from './tlt-widget/reducer';
import { transactionsReducer } from './transactions/reducer';
import { userReducer } from './user/reducer';

const projectReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  accountModal: accountModalReducer,
  authModal: authModalReducer,
  mobile: mobileReducer,
  dialog: dialogManagerReducer,
  messages: messagesReducer,
  games: gamesReducer,
  configs: configsReducer,
  transactions: transactionsReducer,
  integrations: integrationsReducer,
  tltWidgetConfig: tltWidgetReducer,
});

const rootReducer = (state: any | undefined, action: Action): RootState => {
  return projectReducer(state, action);
};

export default rootReducer;
