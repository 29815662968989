import cn from 'classnames';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { replaceImgPath } from 'src/helpers/transformers';
import '../styles.scss';

type Props = {
  widgetData: any;
  btnStyles?: string;
  redirect: (ev: any, attributes: any) => void;
  cls: string;
};
const HomeWidget: FC<Props> = ({ widgetData, btnStyles, redirect, cls }) => {
  const { t }: Translation = useTranslation();
  const imageSrc = widgetData?.imageSrc;
  if (!imageSrc || imageSrc.includes('undefined')) {
    return <div style={{ color: 'red' }}>Image is broken</div>;
  }
  const redirection = widgetData?.OnClickRedirection;

  const convertImageUrl = useCallback(
    (imageSrc: string): string => {
      if (process.env.REACT_APP_CONVERT_IMAGE_PATH_WITH_IMIGIX) {
        const convertConfigs = JSON.parse(process.env.REACT_APP_CONVERT_IMAGE_PATH_WITH_IMIGIX as any);
        if (convertConfigs.homeWidget) {
          return replaceImgPath(imageSrc);
        }
      }
      return imageSrc;
    },
    [widgetData]
  );

  return (
    <div
      className={cls}
      style={{ backgroundImage: `url(${convertImageUrl(imageSrc)})` }}
      onClick={redirection?.Target && redirection?.TargetParam ? (ev) => redirect(ev, widgetData) : undefined}
    >
      {!!widgetData.buttonActions?.targetParam && (
        <Button className={cn('action-btn', btnStyles)} onClick={(ev) => redirect(ev, widgetData.buttonActions)}>
          {t(`goTo${widgetData.buttonActions?.targetParam}`) ?? `Go To ${widgetData.buttonActions.targetParam}`}
        </Button>
      )}
    </div>
  );
};
export default HomeWidget;
