/* eslint-disable no-nested-ternary */
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { readMessageRequest } from 'src/store/messages/actions';
interface IProps {
  data: any;
}
const FreeSpins: FC<IProps> = ({ data }) => {
  const { t }: Translation = useTranslation();

  const _closeDialog = useConnectedAction(closeDialog);
  const _readMessage = useConnectedAction(readMessageRequest);

  const removeDialog = useCallback((): void => {
    _readMessage(data._id);
    _closeDialog({ dialogType: EnumDialogsKeys.FREE_SPINS });
  }, []);

  return (
    <PopUpContainer
      isOpen
      handleClose={removeDialog}
      handleOK={removeDialog}
      title={t('freeSpins.title')}
      description={t('freeSpins.text', {
        count: data?.data?.mission?.prize?.spinCount,
        gameName: data?.data?.thirdPartyResponse?.result?.games[0].name,
      })}
    />
  );
};
export default FreeSpins;
