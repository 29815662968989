import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { detectDevice, scrollToTop } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useBannerActions } from 'src/hooks/useBannerActions';
import { CMSManager } from 'src/manager/CMSManager';
import { getHomeGrid } from 'src/services/dataApi';
import PersisterService from 'src/services/PersisterService';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { HomeBanner } from 'src/types/common-types/slider';
import { RootState } from 'src/types/store-types';
import Template_2_3 from './components/Template_2_3';
import Template_3_1 from './components/Template_3_1';
import Template_4 from './components/Template_4';
import './styles.scss';

type Props = {
  settings: { priority: number; styles: Styles; type: string };
};
const HomeWidgets: FC<Props> = ({ settings }) => {
  const { styles, type } = settings;
  const { locale } = useI18n();

  const device = detectDevice();

  const { user } = useSelector((state: RootState) => state.user);

  const _openAuthModal = useConnectedAction(openAuthModal);

  const { loginAction, registrAction, promotionAction, gameAction, categoryAction, externalUrlAction } =
    useBannerActions();

  const [widgets, setWidgets] = useState<HomeBanner[]>([]);

  const redirect = (ev: any, attributes: any): any => {
    if (process.env.REACT_APP_GLOBAL_NEED_LOGIN && !user) {
      return _openAuthModal('login');
    }

    const redirection = attributes?.OnClickRedirection;
    const redirectParams = {
      target: redirection ? redirection?.Target : attributes?.target,
      param: redirection ? redirection?.TargetParam : attributes?.targetParam,
    };

    attributes ? ev.stopPropagation() : ev.preventDefault();

    switch (redirectParams.target) {
      case 'goToRegister':
        registrAction();
        break;
      case 'goToLogin':
        loginAction();
        break;
      case 'goToPromotion':
        promotionAction(redirectParams.param);
        break;
      case 'goToExternalUrl':
        externalUrlAction();
        break;
      case 'goToGame':
        gameAction(redirectParams.param);
        break;
      case 'goToCategory':
        categoryAction(redirectParams.param);
        scrollToTop();
        break;
      case 'goToRace':
      case 'noAction':
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    let result = null;

    if (PersisterService.hasPersistValue('homeGrid'?.[locale])) {
      result = PersisterService.getPersistValue('homeGrid')[locale];
      const widgets = result.sort((a: any, b: any) => a.priority - b.priority);
      setWidgets(widgets);
    } else {
      const _deviceTtype = device === 'tablet' ? 'desktop' : device;

      getHomeGrid(_deviceTtype, locale).then((res: any) => {
        if (!!res?.data.length) {
          const _ = {
            [locale]: CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string]?.getPageGridData(res.data),
          };

          PersisterService.setPersistValue('homeGrid', _);
          const widgets = _[locale]?.sort(
            (a: { priority: number }, b: { priority: number }) => a.priority - b.priority
          );
          setWidgets(widgets);
        }
      });
    }
  }, [locale]);

  const widgetTemplate = (): JSX.Element => {
    let template;

    switch (type) {
      case 'home_widget_template_2-3':
        template = <Template_2_3 widgets={widgets} redirect={redirect} />;
        break;
      case 'home_widget_template_3-1':
        template = <Template_3_1 widgets={widgets} redirect={redirect} />;
        break;
      case 'home_widget_template_4':
        template = <Template_4 widgets={widgets} redirect={redirect} />;
        break;
      default:
        template = <Template_2_3 widgets={widgets} redirect={redirect} />;
    }

    return template;
  };

  if (!!widgets.length) {
    return (
      <div className={type} style={styles}>
        {widgetTemplate()}
      </div>
    );
  }

  return <></>;
};
export default HomeWidgets;
