import { createReducer } from 'deox';
import produce from 'immer';
import { getMessagesSuccess, readMessageSuccess } from './actions';
import { messagesState } from './types';

const messagesInitialState: messagesState = [];

export const messagesReducer = createReducer(messagesInitialState, (handleAction) => [
  handleAction(getMessagesSuccess, (_, { payload: arrMessages }) => {
    return produce(arrMessages, (draftState) => draftState);
  }),
  handleAction(readMessageSuccess, (state, { payload: messageId }) => {
    return produce(state, (draftState) => {
      const message = draftState.find((msg) => msg._id === messageId);
      if (message) {
        message.status = 'read';
      }
    });
  }),
]);
