import { ChangeEvent, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError, setPassword } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  error?: any;
  required?: boolean;
};

const Password_Input: FC<IProps> = (props) => {
  // Hooks
  const { t }: Translation = useTranslation();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();
  const _setPassword = useConnectedAction(setPassword);

  // Selectors
  const { errors: signUpErrors, repeatPassword } = useSelector((state: RootState) => state.user);

  // Constants
  const passwordRegExp = useMemo(() => {
    return props.regexp
      ? new RegExp(props.regexp)
      : new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9~_+^#$@!%*?&]+){6,16}$');
  }, [props.regexp]);

  // YUP Validation
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('requiredField'))
      .min(6, t('passwordMustContainBothLettersAndDigits'))
      .max(16, t('passwordMustContainBothLettersAndDigits'))
      .matches(passwordRegExp, t('passwordMustContainBothLettersAndDigits')),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ password: value }, { abortEarly: false });
      resetSignUpError('password');
      if (repeatPassword?.trim() === value) {
        resetSignUpError('password2');
      } else {
        _setErrorReq({ ...signUpErrors, password2: t('password_must_be_a_match') });
      }
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, password: error.errors[0] });
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    props.onChange(e);
    if (signUpErrors?.password) {
      resetSignUpError('password');
    }
    // TO DO must replace and handle from one place
    _setPassword({ key: 'password', value: e.target.value });
  };

  const handleBlur = (): void => {
    handleValidate(props.value?.trim());
  };

  return (
    <Input
      error={signUpErrors?.password || ''}
      {...props}
      onChange={handleOnChange}
      onBlur={handleBlur}
      id="password_input"
    />
  );
};
export default Password_Input;
