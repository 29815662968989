/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { formatCurrency } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { setSelectedGame } from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';
interface IProps {
  data: any;
}

const GameLaunchPreview: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const _openAuthModal = useConnectedAction(openAuthModal);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _closeDialog = useConnectedAction(closeDialog);

  const { user, wallets } = useSelector((state: RootState) => state.user);

  const mainWallet = wallets.filter((wallet: any) => wallet.walletType === 'main')[0];
  const bonusWallet = wallets.filter((wallet: any) => wallet.walletType === 'bonus')[0];

  const onGameClick = (type: string): void => {
    if (user) {
      const walletId = type === 'bonus' ? bonusWallet.walletId : mainWallet.walletId;
      _selectedGame({ ...data, gameWalletId: walletId });
      _closeDialog({ dialogType: EnumDialogsKeys.GAME_LAUNCH_PREVIEW });
    } else {
      _openAuthModal('login');
    }
  };

  const close = (): void => {
    _closeDialog({ dialogType: EnumDialogsKeys.GAME_LAUNCH_PREVIEW });
  };

  return (
    <PopUpContainer
      handleClose={close}
      hasOverlay={true}
      id="game_launch_preview"
      type="fullSize"
      isOpen
      title={t('game_launch_preview')}
    >
      <div className="game_launch_preview-container">
        <div className="game_launch_preview--description">
          <img src={data.thumbnail} className="game-bg-image"></img>
          <div className="game_launch_preview--description--info">
            <Typography variant="h4" fontWeight="bold" className="game_launch_preview--name">
              {data.name}
            </Typography>
            <Typography variant="h6" className="game_launch_preview--provider">
              {data.providerName}
            </Typography>
          </div>
        </div>
        <div className="game_launch_preview--footer">
          <div className="btn-section">
            <Button className="primary-filled-btn game-preview" type="button" onClick={() => onGameClick('main')}>
              <span className="game-preview-btn--container">
                <SvgIcon className="game_launch_preview--icon" src={icons.play} />
                <div className="game_launch_preview--btn-text">
                  {isMobileOnly ? t('main_wallet') : t('play_from_main_wallet')} :{' '}
                </div>
                <div className="game_launch_preview--btn-text--money">
                  {formatCurrency(mainWallet?.balance, mainWallet?.currency)}
                </div>
              </span>
            </Button>
            <Button className="primary-filled-btn game-preview" type="button" onClick={() => onGameClick('bonus')}>
              <span className="game-preview-btn--container">
                <SvgIcon className="game_launch_preview--icon" src={icons.play} />
                <div className="game_launch_preview--btn-text">
                  {isMobileOnly ? t('bonus_wallet') : t('play_from_bonus_wallet')}:{' '}
                </div>
                <div className="game_launch_preview--btn-text--money">
                  {formatCurrency(bonusWallet?.balance, bonusWallet?.currency)}
                </div>
              </span>
            </Button>
          </div>
        </div>
      </div>
    </PopUpContainer>
  );
};
export default GameLaunchPreview;
