const CMSManager: any = {
  strapi: {
    getPublicPopups: (data: any, deviceType: any): any => {
      const _ = data.filter(
        (item: { attributes: { Device: { type: string } } }) => item?.attributes?.Device?.type === deviceType
      );

      return _.map((popup: { id: number; attributes: any }) => {
        const { id, attributes } = popup;
        const imageUrl = attributes?.Image?.data?.[0]?.attributes?.url;
        const callAction = attributes?.CallToActions?.[0] ? attributes?.CallToActions?.[0] : null;
        const dontShowAgain = attributes?.DontShowAgain;

        return { id, imageUrl, callAction, dontShowAgain };
      });
    },
    getCustomI18N: (data: any, locale: string): any => {
      const _ = data?.filter((f: any) => locale === f?.attributes.locale)?.[0]?.attributes;

      return {
        businessActivityInfo: _?.businessActivityInfo ?? '',
        businessLegalInfo: _?.businessLegalInfo ?? '',
        copyRight: _?.copyRight ?? '',
        newsLetter: _?.newsLetter ?? '',
        websiteName: _?.websiteName ?? '',
        websiteTermsBriefInfo: _?.websiteTermsBriefInfo ?? '',
      };
    },
    getInfoAndRulesData: (data: any): any => {
      return data?.[0]?.attributes?.info_pages?.data?.map((m: any) => {
        return {
          title: m.attributes.Title,
          content: m.attributes.Content,
          name: m.attributes.Name,
        };
      });
    },
    getStaticBannersData: (data: any): any => {
      return data?.map((m: any) => {
        return {
          imageSrc:
            process.env.REACT_APP_FILE_STORAGE_ADDRESS + m?.attributes?.Image?.data?.attributes?.url?.substring(1) ??
            '/images/default-image.webp',
          redirection: {
            target: m?.attributes?.OnClickRedirection?.Target ?? '',
            targetParam: m?.attributes?.OnClickRedirection?.TargetParam ?? '',
          },
          buttonActions: {
            target: m?.attributes?.CallToActions?.[0]?.Target,
            targetParam: m?.attributes?.CallToActions?.[0]?.TargetParam,
            position: m?.attributes?.CallToActions?.[0]?.Position,
          },
          priority: m?.attributes?.priority ?? 1,
          key: m?.attributes?.WebsiteSection?.Key,
        };
      });
    },
    getPageGridData: (data: any): any => {
      return data?.map((m: any) => {
        return {
          id: m?.id,
          imageSrc:
            process.env.REACT_APP_FILE_STORAGE_ADDRESS + m?.attributes?.Image?.data?.attributes?.url?.substring(1),
          buttonActions: {
            target: m?.attributes?.CallToActions.length > 0 ? m?.attributes?.CallToActions?.[0]?.Target : '',
            targetParam: m?.attributes?.CallToActions.length > 0 ? m?.attributes?.CallToActions?.[0]?.TargetParam : '',
          },
          priority: m?.attributes?.priority ?? 1,
          OnClickRedirection: {
            Target: m?.attributes?.OnClickRedirection?.Target ?? '',
            TargetParam: m?.attributes?.OnClickRedirection?.TargetParam ?? '',
          },
        };
      });
    },
    getPromotionData: (data: any): any => {
      return data?.map((m: any) => {
        return {
          thumbnail: `${(process.env.REACT_APP_FILE_STORAGE_ADDRESS as string).slice(0, -1)}${
            m?.attributes?.Thumbnail?.data?.attributes?.url
          }`,
          buttonActions: {
            target: m?.attributes?.CallToActions?.[0]?.Target ?? '',
            targetParam: m?.attributes?.CallToActions?.[0]?.TargetParam ?? '',
            position: m?.attributes?.CallToActions?.[0]?.Position ?? '',
          },
          priority: m?.attributes?.priority ?? 1,
          iframeSrc: m?.attributes?.contentExternalURL,
          banner: `${(process.env.REACT_APP_FILE_STORAGE_ADDRESS as string).slice(0, -1)}${
            m?.attributes?.Banner?.data?.attributes?.url
          }`,
          title: m?.attributes?.Title ?? '',
          longDescription: m?.attributes?.LongDescription ?? '',
          shortDescription: m?.attributes?.ShortDescription ?? '',
          promoTags:
            m?.attributes?.PromoTags.length > 0 ? m?.attributes?.PromoTags.map((m: { Tag: string }) => m?.Tag) : [],
          id: m?.id,
          name: m?.attributes?.Name.replaceAll('&', '-').replaceAll('%', ''),
        };
      });
    },
  },
};
export { CMSManager };
