import { FC } from 'react';
import _styles from './styles.module.scss';

type Props = {
  error: { [keys: string]: string };
  paymentField: string;
};
const ErrorMessage: FC<Props> = ({ error, paymentField }) => {
  return <span className={_styles.payment_error}>{error[paymentField] ?? ''}</span>;
};
export default ErrorMessage;
