import { FC, useState } from 'react';
import { SimpleModal } from 'src/components/common/modal/SimpleModal';
import { Button } from '../../common/Button';
import { SvgIcon } from '../../common/SvgIcon';
import './styles.scss';

type Props = {
  settings: {
    icon: string;
    needLogin: false;
    priority: number;
    styles: Styles;
  };
};
const Wagering: FC<Props> = ({ settings }) => {
  const [visible, setVisiable] = useState<boolean>(false);

  const openWageringModal = (): void => {
    setVisiable((prevState: boolean) => !prevState);
  };

  const closeWageringModal = (): void => {
    setVisiable(false);
  };

  return (
    <>
      <div className="wagering_wrapper">
        <Button
          fontWeight="bold"
          variant="contained"
          color="transparent"
          className="promotion_wrapper"
          onClick={openWageringModal}
        >
          <SvgIcon src={`/images/${settings?.icon}`} styles={settings?.styles} />
        </Button>
      </div>
      <SimpleModal isOpenCustom={visible} placement="right" onClose={closeWageringModal} closeIcon>
        content
      </SimpleModal>
    </>
  );
};
export default Wagering;
