import { createReducer } from 'deox';
import produce from 'immer';
import {
  forceCloseGameBox,
  setAccountActiveWageringMissions,
  setActualMissionsCount,
  setFreeSpinGames,
  setGameBoxMissions,
  setJackpotTicket,
  setSelectedTounamentGame,
  setTournamentGame,
  setTournamentModulePageView,
} from './actions';
import { integrationsState } from './types';

export const initialState: integrationsState = {
  activeWageringMissions: null, // gamebox
  gameBoxMissions: null, // gamebox
  jackpotTickets: null, // gamebox
  freeSpinGames: null, //gamebox
  actualMissions: 0, //gamebox
  forceCloseGameBoxState: false, // gamebox
  showTournamentPage: false, // GOS
  selectedTournamentGame: null, // GOS
  tournamentGame: null, // GOS
};

export const integrationsReducer = createReducer(initialState, (handle) => [
  handle(setAccountActiveWageringMissions.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.activeWageringMissions = payload;
    })
  ),
  handle(setGameBoxMissions.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.gameBoxMissions = payload;
    })
  ),
  handle(setJackpotTicket, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.jackpotTickets = payload;
    })
  ),
  handle(setFreeSpinGames.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.freeSpinGames = payload;
    })
  ),
  handle(setSelectedTounamentGame, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.selectedTournamentGame = payload;
    })
  ),
  handle(setTournamentGame, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.tournamentGame = payload;
    })
  ),
  handle(setTournamentModulePageView, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.showTournamentPage = payload;
    })
  ),
  handle(forceCloseGameBox, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.forceCloseGameBoxState = payload;
    })
  ),
  handle(setActualMissionsCount, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.actualMissions = payload;
    })
  ),
]);
