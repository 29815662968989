import cn from 'classnames';
import { FC } from 'react';
import { HomeBanner } from 'src/types/common-types/slider';
import { WIDGET_STYLES_FOR_3_1 } from 'src/utils/constants';
import HomeWidget from './HomeWidget';

type Props = {
  widgets: HomeBanner[];
  redirect: (ev: any, attributes: any) => void;
};
const Template_3_1: FC<Props> = ({ widgets, redirect }) => {
  return (
    <>
      {widgets?.map((widget: HomeBanner, i: number) => {
        return (
          <HomeWidget
            key={widget.id}
            widgetData={widget}
            btnStyles={WIDGET_STYLES_FOR_3_1[i]?.btnStyles || WIDGET_STYLES_FOR_3_1[0].btnStyles}
            redirect={redirect}
            cls={cn(
              (WIDGET_STYLES_FOR_3_1[i] || WIDGET_STYLES_FOR_3_1[0])?.cls,
              `${WIDGET_STYLES_FOR_3_1[i]?.cls || WIDGET_STYLES_FOR_3_1[0].cls}_${i + 1}`
            )}
          />
        );
      })}
    </>
  );
};
export default Template_3_1;
