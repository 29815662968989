import { FC, useMemo } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Loader } from 'src/components/common/Loader';
import { Typography } from 'src/components/common/Typography';
import '../styles.scss';

interface IProps {
  imgSrc: string;
  modalTitle: string;
  modalContentsMap: { [key: string]: any };
  content: string | null;
}

const ContentWithImage: FC<IProps> = ({ imgSrc, content, modalTitle, modalContentsMap }) => {
  const { t }: Translation = useTranslation();

  const image = useMemo(() => {
    if (content !== 'forgot' && !isTablet) {
      return (
        <div className="content_img">
          {imgSrc ? <LazyLoadImage src={imgSrc} className="img" alt={content} /> : <Loader size="lg" />}
        </div>
      );
    }

    return null;
  }, [content, imgSrc]);

  if (isMobileOnly) {
    return (
      <div className="content_with_image_wrapper">
        {image}
        <div className="right_content">
          {modalTitle && (
            <Typography variant={'h4'} className="title">
              {t(modalTitle + '_modal_title')}
            </Typography>
          )}
          {modalContentsMap[content as string]}
        </div>
      </div>
    );
  }

  return (
    <div className="content_with_image_wrapper">
      {image}
      <div className="form">
        {modalTitle && (
          <Typography variant={'h4'} className="title">
            {t(modalTitle + '_modal_title')}
          </Typography>
        )}
        {modalContentsMap[content as string]}
      </div>
    </div>
  );
};

export default ContentWithImage;
