import cn from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { MediaManager } from 'src/manager/MediaManager';

type Props = {
  groupedPayments?: { [key: string]: any[] } | null;
  group: string;
  selectedGroupOfPayment: string;
  selectPaymentGroup?: (group: string) => void;
};
const GroupedPayment: FC<Props> = ({ group, selectedGroupOfPayment, selectPaymentGroup, groupedPayments }) => {
  const { t }: Translation = useTranslation();

  const duraction = useMemo(() => {
    if (Boolean(groupedPayments?.[group]?.[0]?.duration?.min || groupedPayments?.[group]?.[0]?.duration?.max)) {
      let _dur = `${t('duration')} - `;
      groupedPayments?.[group][0]?.duration?.min && (_dur += groupedPayments?.[group][0]?.duration.min);
      groupedPayments?.[group][0]?.duration?.max && (_dur += '-' + groupedPayments?.[group][0]?.duration.max);
      _dur += t('dur_min');
      return _dur;
    }

    return null;
  }, [groupedPayments, group]);

  const icon = useCallback((group: string) => {
    const _imageName = isMobileOnly ? `${group}_mobile.svg` : `${group}.svg`;

    return MediaManager.getSrcFromMediaAssets(_imageName, '/paymentGroupIcons/');
  }, []);

  return (
    <div
      className={cn('group_item', { ['active']: selectedGroupOfPayment === group })}
      onClick={() => selectPaymentGroup && selectPaymentGroup(group)}
    >
      {(duraction || isMobileOnly) && (
        <div className="bottom_container">
          <span className="active_container">{duraction && <span>{duraction}</span>}</span>
        </div>
      )}
      <img src={icon(group)} alt={group} className="group_image" />
    </div>
  );
};
export default GroupedPayment;
