import cn from 'classnames';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EnumRoutePaths } from 'src/configs/routes';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { MediaManager } from 'src/manager/MediaManager';
import { closeAccountModal } from 'src/store/account-modal/actions';
import { resetSectionLoading, setGameBoxWidgetOpen, setSelectedCategory } from 'src/store/app/actions';
import { setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { toggleMobileMenu, toggleMobileProvider, toggleMobileSearch } from 'src/store/mobile/actions';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import { RootState } from 'src/types/store-types';
import { envBoolean, scrollToTop, setBodyOverflow } from '../../../helpers/utils';
import './styles.scss';

type Props = {
  settings?: {
    src: string;
    styles?: Styles;
    priority?: number;
    needLogin?: false;
    classes?: string;
  };
};
export const Logo: FC<Props | null> = (props) => {
  const navigate = useNavigate();
  const logoUrl =
    sessionStorage.getItem('dynamicClubAppLogo') ||
    MediaManager.getSrcFromMediaAssets(props?.settings?.src ?? 'logo.png', '/');

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _toggleMobileSearch = useConnectedAction(toggleMobileSearch);
  const _toggleMobileProvider = useConnectedAction(toggleMobileProvider);
  const _toggleMobileMenu = useConnectedAction(toggleMobileMenu);
  const _setGameBoxWidgetOpen = useConnectedAction(setGameBoxWidgetOpen);
  const _closeModal = useConnectedAction(closeAccountModal);
  const _resetSectionLoading = useConnectedAction(resetSectionLoading);
  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);

  const { isGameBoxOpen, sideBarMenuIsOpen } = useSelector((state: RootState) => state.app);
  const { user } = useSelector((state: RootState) => state.user);
  const { showMenu, showSearch, showProviders } = useSelector((state: RootState) => state.mobile);
  const { navigation } = useSelector((state: RootState) => state.configs);

  const iframe = document?.getElementById('gamebox-widget') as HTMLIFrameElement;

  const [defaultRoute, setDefaultRoute] = useState<{ route: string; category: string }>({
    route: EnumRoutePaths.DEFAULT,
    category: 'home',
  });

  const handleClick = (e: MouseEvent<HTMLElement> | TouchEvent): void => {
    if (
      (envBoolean(process.env.REACT_APP_HAS_LOGIN_PAGE as string) && user) ||
      !envBoolean(process.env.REACT_APP_HAS_LOGIN_PAGE as string)
    ) {
      e.preventDefault();
      _resetSectionLoading();

      if (iframe && isGameBoxOpen && isMobileOnly) {
        iframe?.contentWindow?.postMessage(
          {
            type: 'CHANGE_WIDGET_VIEW',
            view: '',
          },
          '*'
        );
        _setGameBoxWidgetOpen();
      }

      _setSelectedCategory(defaultRoute.category);
      _setSelectedTag('');
      _setSelectedProvider(['all_providers']);

      if (isMobileOnly) {
        showMenu && _toggleMobileMenu();
        showProviders && _toggleMobileProvider();
        showSearch && _toggleMobileSearch();
        setBodyOverflow('set');
        _closeModal();
      }

      scrollToTop();
      navigate(defaultRoute.route);
    }
  };

  useEffect(() => {
    if (!!navigation?.length) {
      const _defaultRoute = navigation.find((route: NavigationItem) => route.isDefault) as NavigationItem;

      if (_defaultRoute.type === 'category') {
        setDefaultRoute({ category: _defaultRoute.name, route: `/category/${_defaultRoute.name}` });
      }
    }
  }, [navigation]);

  return (
    <div
      className={cn('logo', props?.settings?.classes, { ['hide-logo']: sideBarMenuIsOpen })}
      onClick={handleClick}
      style={props?.settings?.styles}
    >
      <img src={logoUrl} style={props?.settings?.styles} alt="Logo" />
    </div>
  );
};
