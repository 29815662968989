import { FC } from 'react';
import { isDesktop } from 'react-device-detect';
import GenerateLink from './components/GenerateLink/GenerateLink';
import TermsAndConditions from './components/GenerateLink/TermsAndCondions';
import PrizeDistribution from './components/PrizeDistribution/PrizeDistribution';
import ReferredFriends from './components/ReferredFriends/ReferredFriends';
import ReferredFriendsList from './components/ReferredFriends/ReferredFriendsList';
import Reward from './components/Rewards/Reward';
import Steps from './components/Steps/Steps';
import _styles from './styles.module.scss';

interface IReward {
  title: string;
  data: Reward;
}

type Props = {
  rewards: IReward[];
  friendsList: FriendsList | null;
  setShowFriendsList: (show: boolean) => void;
  setShowTermsAndConditions: (show: boolean) => void;
  showFriendsList: boolean;
  showTermsAndConditions: boolean;
  openSocilMedia: (media: string) => void;
  link: string;
  copiedLink: boolean;
  copyLink: () => void;
  setOffset: any;
};

const DesktopRAF: FC<Props> = ({
  rewards,
  friendsList,
  setShowFriendsList,
  setShowTermsAndConditions,
  showFriendsList,
  showTermsAndConditions,
  openSocilMedia,
  link,
  copiedLink,
  copyLink,
  setOffset,
}) => {
  return (
    <>
      <div className={`${_styles.refer_a_friend_container} ${_styles[isDesktop ? 'desktop' : 'tablet']}`}>
        <Steps />
        <div className={_styles.collection_wrapper}>
          {rewards?.map((reward: IReward) => {
            return <Reward reward={reward.data} title={reward.title} />;
          })}
        </div>
        <div className={_styles.bottom_Section}>
          <GenerateLink
            friendsList={friendsList}
            setShowFriendsList={setShowFriendsList}
            setShowTermsAndConditions={setShowTermsAndConditions}
            openSocilMedia={openSocilMedia}
            link={link}
            copiedLink={copiedLink}
            copyLink={copyLink}
          />
          <div className={_styles.right_hand}>
            {!friendsList || <ReferredFriends friendsList={friendsList} setShowFriendsList={setShowFriendsList} />}
            <PrizeDistribution />
          </div>
        </div>
        {showFriendsList && (
          <ReferredFriendsList
            friendsList={friendsList}
            setShowFriendsList={setShowFriendsList}
            setOffset={setOffset}
          />
        )}
        {showTermsAndConditions && <TermsAndConditions setShowTermsAndConditions={setShowTermsAndConditions} />}
      </div>
      {(showFriendsList || showTermsAndConditions) && <div className="backdrop"></div>}
    </>
  );
};
export default DesktopRAF;
