export type tltWidgetState = {
  tltWidgetConfig: {
    key: string | null;
    op: string | null;
    ts: string | null;
    player?: number | null;
    currency?: string | null;
  };
};
export const SET_TLT_WIDGET_CONFIG = 'tlt/SET_WIDGET_CONFIG';
export const REMOVE_TLT_WIDGET_CONFIG = 'tlt/REMOVE_WIDGET_CONFIG';
