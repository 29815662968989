import React, { FC } from 'react';
import HelpCenter from 'src/components/base-components/HelpCenter/HelpCenter';
import StaticPromotionPage from 'src/components/base-components/StaticPage/StaticPromotionPage';
import '../styles.scss';

const staticPages = {
  helpCenter: <HelpCenter />,
  promotions: <StaticPromotionPage />,
};
type Props = {
  page: string;
};
const StaticPageConstructor: FC<Props> = ({ page }) => {
  return staticPages[page];
};
export default StaticPageConstructor;
