import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { RootState } from 'src/types/store-types';
import Version from '../Version';
import _styles from './styles.module.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
  };
};
const CopyRight: FC<Props> = ({ settings }) => {
  const { styles } = settings;

  const { t }: Translation = useTranslation();

  const { customI18n } = useSelector((state: RootState) => state.app);

  return (
    <div className={_styles.copyRight_container} style={styles}>
      {!!customI18n?.copyRight && (
        <Typography className={_styles.copyRight} variant={'body3'}>
          {customI18n.copyRight}
        </Typography>
      )}
      <div className={_styles.copyRight_subContainer}>
        <Typography className={_styles.copyRight} variant={'body3'}>
          {process.env.REACT_APP_WEBSITENAME as string} {t('copyRight')} {new Date().getFullYear()}
        </Typography>
        <Typography className={_styles.copyRight} variant={'body3'}>
          |
        </Typography>
        <Version />
      </div>
    </div>
  );
};
export default CopyRight;
