import cn from 'classnames';
import { FC, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { useOutsideClick } from 'src/hooks/use_outside_click';
import '../styles.scss';
import ListViewCard from './ListViewCard';

type ProviderListViewProps = {
  providers: any[];
  selectedProvider: string[];
  onProviderChange: any;
  togglePovidersFilterBtn: any;
  openProvidersList: boolean;
};
const ProviderListView: FC<ProviderListViewProps> = (props) => {
  const { providers, selectedProvider, onProviderChange, togglePovidersFilterBtn, openProvidersList } = props;

  const { t }: Translation = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);

  const selectProvider = (provider: string): void => {
    onProviderChange(provider);
  };

  const oudsideClick = (): void => {
    if (openProvidersList) {
      togglePovidersFilterBtn(false);
    }
  };

  useOutsideClick(ref, oudsideClick, null, ['providers_container']);

  return (
    <div className="providers_list_container">
      <div className="provider_wrapper" ref={ref}>
        <Button
          fontWeight="bold"
          color="transparent"
          className="provider_collapse"
          onClick={togglePovidersFilterBtn}
          justifyContentValue={isMobileOnly ? 'flex-start' : 'space-between'}
        >
          {selectedProvider.length >= 1 && selectedProvider[0] !== 'all_providers'
            ? t('selected') + ' ' + selectedProvider.length
            : t('all_providers')}
          <SvgIcon
            src="/images/icons/svg/filled_arrow_down.svg"
            className={cn('arrow', { ['up']: openProvidersList })}
          />
        </Button>
        {openProvidersList && (
          <div className="providers_container">
            {providers?.map((provider: string) => (
              <ListViewCard
                key={provider}
                t={t}
                provider={provider}
                selectedProvider={selectedProvider}
                onProviderChange={selectProvider}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default ProviderListView;
