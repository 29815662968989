import { useLocation } from 'react-router-dom';
import { getFromPath } from 'src/helpers/utils';
import { setSelectedCategory } from 'src/store/app/actions';
import { useConnectedAction } from './use-connected-action';

export const useNavigateToCategory: any = () => {
  const { pathname } = useLocation();
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  if (pathname.includes('category')) {
    const category = getFromPath(pathname, '/', 2);
    return () => _setSelectedCategory(category);
  } else {
    return () => _setSelectedCategory(process.env.REACT_APP_DEFAULT_CATEGORY);
  }
};
