import { createAction } from 'deox';
import {
  INTEGRATIONS_FORCE_CLOSE_GAMEBOX,
  INTEGRATIONS_SET_ACTUAL_MISSIONS_COUNT,
  INTEGRATIONS_SET_EXTERNAL_TOURNAMENT_GAME,
  INTEGRATIONS_SET_FREE_SPIN_GAMES_FAIL,
  INTEGRATIONS_SET_FREE_SPIN_GAMES_REQ,
  INTEGRATIONS_SET_FREE_SPIN_GAMES_SUCCESS,
  INTEGRATIONS_SET_GAME_BOX_MISSION_FAIL,
  INTEGRATIONS_SET_GAME_BOX_MISSION_REQ,
  INTEGRATIONS_SET_GAME_BOX_MISSION_SUCCESS,
  INTEGRATIONS_SET_JACKPOT_MISSIONS,
  INTEGRATIONS_SET_MISSIONS_FAIL,
  INTEGRATIONS_SET_MISSIONS_REQ,
  INTEGRATIONS_SET_MISSIONS_SUCCESS,
  SET_EXTERNAL_TOURNAMENT_GAME,
  TOGGLE_TOURNAMENT_MODULE_PAGE,
} from './types';

export const setAccountActiveWageringMissions = {
  req: createAction(INTEGRATIONS_SET_MISSIONS_REQ),
  success: createAction(INTEGRATIONS_SET_MISSIONS_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(INTEGRATIONS_SET_MISSIONS_FAIL),
};

export const setGameBoxMissions = {
  req: createAction(INTEGRATIONS_SET_GAME_BOX_MISSION_REQ),
  success: createAction(INTEGRATIONS_SET_GAME_BOX_MISSION_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(INTEGRATIONS_SET_GAME_BOX_MISSION_FAIL),
};

export const setJackpotTicket = createAction(INTEGRATIONS_SET_JACKPOT_MISSIONS, (res) => (params) => res(params));

export const setFreeSpinGames = {
  req: createAction(INTEGRATIONS_SET_FREE_SPIN_GAMES_REQ),
  success: createAction(INTEGRATIONS_SET_FREE_SPIN_GAMES_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(INTEGRATIONS_SET_FREE_SPIN_GAMES_FAIL),
};

export const setSelectedTounamentGame = createAction(
  INTEGRATIONS_SET_EXTERNAL_TOURNAMENT_GAME,
  (res) => (params) => res(params)
);

export const setTournamentGame = createAction(SET_EXTERNAL_TOURNAMENT_GAME, (res) => (params) => res(params));

export const setTournamentModulePageView = createAction(
  TOGGLE_TOURNAMENT_MODULE_PAGE,
  (res) => (params) => res(params)
);

export const forceCloseGameBox = createAction(INTEGRATIONS_FORCE_CLOSE_GAMEBOX, (res) => (params) => res(params));

export const setActualMissionsCount = createAction(
  INTEGRATIONS_SET_ACTUAL_MISSIONS_COUNT,
  (res) => (params) => res(params)
);
