import { FC } from 'react';
import styles from './styles.module.scss';

type Props = {
  t: any;
  selectedPayment: any;
};
const PaymentInformation: FC<Props> = ({ t, selectedPayment }) => {
  const infoArr = [
    { title: 'fee', value: selectedPayment?.fee ?? 0 },
    { title: 'min', value: selectedPayment?.range?.min ?? '-' },
    { title: 'max', value: selectedPayment?.range?.max ?? '-' },
  ];

  return (
    <div className={styles.payment__information}>
      {infoArr.map((info) => {
        return (
          <div key={info.title} className={styles.payment_information_item}>
            <span className={styles.payment_information_item__title}>{t(info.title)}</span>
            <span className={styles.payment_information_item__value}>{info.value}</span>
          </div>
        );
      })}
    </div>
  );
};
export default PaymentInformation;
