import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { toggleMobileSearch } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import { SPECIAL_TAGS } from 'src/utils/constants';
import ModalSearch from './components/ModalSearch';
import SimpleSearch from './components/SimpleSearch';
import './styles.scss';

type SearchProps = {
  settings: {
    view: 'input' | 'modal';
    priority: number;
    styles: { [key: string]: any };
  };
};
const Search: FC<SearchProps> = ({ settings }) => {
  const { view, styles } = settings;

  const _toggleMobileSearch = useConnectedAction(toggleMobileSearch);

  const { selectedTag } = useSelector((state: RootState) => state.games);

  const openSearchModal = (): void => {
    if (!SPECIAL_TAGS.includes(selectedTag)) {
      setBodyOverflow('unset');
      _toggleMobileSearch();
    }
  };

  return (
    <>
      {isMobileOnly ? (
        <div className="search_mobile_container secondary-outlined-btn">
          <Button size="lg" onClick={openSearchModal}>
            <SvgIcon src="/images/icons/svg/search.svg" className="icon" />
          </Button>
        </div>
      ) : (
        <>{view === 'input' ? <SimpleSearch styles={styles} /> : <ModalSearch styles={styles} />}</>
      )}
    </>
  );
};
export default Search;
