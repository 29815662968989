import { createAction } from 'deox';
import {
  APP_CLOSE_USER_SETTINGS,
  APP_CUSTOM_I18N,
  APP_RESET_SECTION_LOADING,
  APP_SET_DEEP_LINK,
  APP_SET_FINAL_TOUR_INFO_FAIL,
  APP_SET_FINAL_TOUR_INFO_REQ,
  APP_SET_FINAL_TOUR_INFO_SUCCSESS,
  APP_SET_FOOTER_MENU,
  APP_SET_GAMEBOX_CLOSE,
  APP_SET_GAMEBOX_OPEN,
  APP_SET_GENERAL_LOADING,
  APP_SET_LOADING,
  APP_SET_SECTION_LOADING,
  APP_SET_SELECTED_CATEGORY,
  APP_SET_SELECTED_SECTION,
  APP_SET_TAGS_DATA,
  APP_SET_TOURANAMENT_GAME_OPEN,
  APP_SHOW_BONUS_WALLET,
  APP_SHOW_USER_SETTINGS,
  APP_SHOW_VIP_POINTS,
  OPEN_SIDEBAR_NAVIGATION_MENU,
  TOGGLE_BALANCE_VISIBILITY,
  TOGGLE_SIDEBAR_NAVIGATION_MENU,
} from './types';

export const toggleSideBarMenu = createAction(TOGGLE_SIDEBAR_NAVIGATION_MENU);

export const openSideBarMenu = createAction(OPEN_SIDEBAR_NAVIGATION_MENU);

export const setLoading = createAction(APP_SET_LOADING, (res) => (params) => res(params));

export const toggleBalanceVisibility = createAction(TOGGLE_BALANCE_VISIBILITY);

export const setSelectedSection = createAction(APP_SET_SELECTED_SECTION, (res) => (params) => res(params));

export const setSelectedCategory = createAction(APP_SET_SELECTED_CATEGORY, (res) => (params) => res(params));

export const setShowUserSettings = createAction(APP_SHOW_USER_SETTINGS);

export const setCloseUserSettings = createAction(APP_CLOSE_USER_SETTINGS);

export const setFinalTourInfo = {
  req: createAction(APP_SET_FINAL_TOUR_INFO_REQ),
  success: createAction(APP_SET_FINAL_TOUR_INFO_SUCCSESS),
  fail: createAction(APP_SET_FINAL_TOUR_INFO_FAIL),
};

export const setSelectedFooterMenuItem = createAction(APP_SET_FOOTER_MENU, (res) => (params) => res(params));

export const setCustomI18N = createAction(APP_CUSTOM_I18N, (res) => (params) => res(params));

export const setGameBoxWidgetOpen = createAction(APP_SET_GAMEBOX_OPEN);

export const setGameBoxWidgetClose = createAction(APP_SET_GAMEBOX_CLOSE);

export const setTournamentIsOpen = createAction(APP_SET_TOURANAMENT_GAME_OPEN, (res) => (params) => res(params));

export const setToggleVipPoits = createAction(APP_SHOW_VIP_POINTS);
export const setToggleBonusWallet = createAction(APP_SHOW_BONUS_WALLET);

export const setDeepLink = createAction(APP_SET_DEEP_LINK, (res) => (params) => res(params));

export const setGeneraLoading = createAction(APP_SET_GENERAL_LOADING, (res) => (params) => res(params));

export const resetSectionLoading = createAction(APP_RESET_SECTION_LOADING);

export const setSectionLoading = createAction(APP_SET_SECTION_LOADING, (res) => (params) => res(params));

export const setTagsData = createAction(APP_SET_TAGS_DATA, (res) => (params) => res(params));
