import cn from 'classnames';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { becomeAReferral } from 'src/services/referFriend';
import { setReferralLink } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { GENERATE_LINK_SOCIAL_ICONS } from 'src/utils/constants';
import _styles from '../../styles.module.scss';
import GenerateReferralLink from './GenerateReferralLink';

type Props = {
  friendsList: FriendsList | null;
  setShowFriendsList: (show: boolean) => void;
  setShowTermsAndConditions: (show: boolean) => void;
  openSocilMedia: (medis: string) => void;
  link: string;
  copiedLink: boolean;
  copyLink: () => void;
  toggleLinkModal?: (show: boolean) => void;
};

const GenerateLink: FC<Props> = ({
  friendsList,
  link,
  copiedLink,
  copyLink,
  setShowTermsAndConditions,
  openSocilMedia,
  toggleLinkModal,
}) => {
  const { t } = useTranslation();

  const _setReferralLink = useConnectedAction(setReferralLink);

  const personalInfo = useSelector((state: RootState) => state.user.user?.personalInfo);
  const { referralLinks } = useSelector((state: RootState) => state.user);

  const openTermsAndonditions = (): void => {
    setShowTermsAndConditions(true);
  };

  const generateLink = (): void => {
    becomeAReferral(+(process.env.REACT_APP_PARENTID as string))
      .then((res: BecomeReferralRes) => {
        if (res.success) {
          _setReferralLink(res.result);
        }
      })
      .finally(() => toggleLinkModal && toggleLinkModal(true));
  };

  const handleShare = (src: string): void => {
    if (src !== 'instagram') {
      openSocilMedia(src);
    }
  };

  if (isMobileOnly) {
    return (
      <div className={_styles.generate_link}>
        {(friendsList || !friendsList) && (
          <Button className={_styles.btn} onClick={generateLink}>
            {t('refer_a_friend.invite_a_friend')}
          </Button>
        )}
        <div className={_styles.condition}>
          <Button
            onClick={openTermsAndonditions}
            className={_styles.btn}
            icon={icons.arrowDown}
            iconPosition="right"
            justifyContentValue="space-between"
          >
            {t('terms_and_conditions')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={_styles.generate_link}>
      <div className={_styles.header}>
        <SvgIcon src={'/images/refer_a_friend/leftCoins.svg'} />
        <div className={_styles.title}>
          <Typography variant={'h6'}>{t('referAFriend')}</Typography>
          <span>{t('referAFriend_bonusText')}</span>
        </div>
        <SvgIcon src={'/images/refer_a_friend/rightCoins.svg'} />
      </div>
      <div className={_styles.main_content}>
        <div className={_styles.inputs}>
          <div className={_styles.input}>
            <span>{t(`refer_a_friend.shareByLink`)}</span>
            <div className={_styles.inner_input}>
              <p>{link}</p>
              <Button className={_styles.copy} onClick={copyLink}>
                {copiedLink && (
                  <span className={_styles.copied}>
                    {t('refer_a_friend.copied')}
                    <span className={_styles.arrow_down} />
                  </span>
                )}
                <SvgIcon src="/images/refer_a_friend/copy.svg" className={_styles.copy_icon} />
                {t('refer_a_friend.copy')}
              </Button>
            </div>
          </div>
        </div>
        <div className={_styles.divider}>{t('refer_a_friend.or_share')}</div>
        <div className={_styles.social_icons}>
          {referralLinks &&
            GENERATE_LINK_SOCIAL_ICONS.map((src: string) => (
              <div className={cn(_styles.icon, _styles[src])} key={src} onClick={() => handleShare(src)}>
                <SvgIcon src={`/images/refer_a_friend/${src}.svg`} />
              </div>
            ))}
        </div>
        {!personalInfo?.referralId && !referralLinks?.referralUrl && <GenerateReferralLink />}
        <div className={_styles.condition}>
          <Button onClick={openTermsAndonditions} className={_styles.btn} justifyContentValue="space-between">
            <span>{t('terms_and_conditions')}</span>
            <SvgIcon src={icons.arrowDown} />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default GenerateLink;
