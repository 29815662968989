import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/types/store-types';
import GamesSlider from '../Slider/GamesSlider';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    image: string;
    styles: Styles;
    type: string;
    category: string;
    limit: number;
  };
};
const FeaturedGames: FC<Props> = ({ settings }) => {
  const { type, styles, category, limit } = settings;

  const { t }: Translation = useTranslation();

  const { user } = useSelector((state: RootState) => state.user);
  const { categoriesData }: any = useSelector((state: RootState) => state.configs);

  const [featuredSlots, setFeaturedSlots] = useState<any[]>([]);

  const widgetTemplate = (): JSX.Element => {
    let template;

    if (type === 'gameSlider') {
      template = (
        <GamesSlider
          data={featuredSlots}
          tagName={t('featuredSlots')}
          template="template_2"
          showViewAllLink={false}
          showArrows
        />
      );
    } else {
      template = (
        <GamesSlider data={featuredSlots} tagName={''} template="template_2" showViewAllLink={false} showArrows />
      );
    }

    return template;
  };

  useEffect(() => {
    if (!!categoriesData[category].tags) {
      const uniqGames = categoriesData[category]?.tags?.[0].tagGames?.slice(0, limit) ?? [];
      setFeaturedSlots(uniqGames);
    }
  }, [categoriesData, user]);

  return (
    <div className="featured_slots_wrapper" style={styles}>
      {widgetTemplate()}
    </div>
  );
};
export default FeaturedGames;
