import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { setBodyOverflow } from 'src/helpers/utils';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
    classes: string;
  };
};

const MailButton: FC<Props> = ({ settings }) => {
  const { styles, classes = '' } = settings;

  const [count, setCount] = useState<number>(0);

  const handleClick = (): void => {
    (window as any)?._smartico.dp('dp:inbox');
    setBodyOverflow('unset');
  };

  (window as any)?._smartico?.on('props_change', function (props: any) {
    if (props.core_inbox_unread_count !== undefined && props.core_inbox_unread_count !== count) {
      setCount(props.core_inbox_unread_count);
    }
  });

  (window as any)?._smartico?.on('gf_closing', () => {
    setBodyOverflow('set');
  });

  return (
    <div className="mailBtn_wrapper">
      <Button ariaLabelledby="mail" style={styles} className={classNames(classes, 'mail_icon')} onClick={handleClick}>
        <SvgIcon src={icons.email} />
      </Button>
      {count ? <Typography className={'actual_count'}>{count}</Typography> : null}
    </div>
  );
};

export default MailButton;
