import { DurationInputArg2 } from 'moment';
import React, { FC, lazy, Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PERIOD_FILTER_TYPES, TRANSACTION_TYPES } from 'src/utils/constants';
import './styles.scss';

type Props = {
  selectTransactionType: (type: 'gameMovement' | 'movement') => void;
  filterByPeriod: (period: DurationInputArg2) => void;
  filterByDate: (range: (Date | null)[]) => void;
  setStartDate: (data: Date | null) => void;
  startDate: Date | null;
  setEndDate: (data: Date | null) => void;
  endDate: Date | null;
  selectedType: string;
  historyType: string;
  period: string;
};

const DatePicker = lazy(() => import('react-datepicker'));

const Filters: FC<Props> = ({
  selectTransactionType,
  filterByPeriod,
  filterByDate,
  selectedType,
  historyType,
  period,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (startDate || endDate) {
      filterByDate([startDate, endDate]);
    }
  }, [startDate, endDate]);

  const defaultStartDate = useMemo(() => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    setStartDate(today);
    return today;
  }, []);

  const defaultValueEndDate = useMemo(() => {
    const today = new Date();
    today.setDate(today.getDate());
    return today;
  }, []);

  return (
    <div className="filters_wrapper">
      <div className="first_section">
        {historyType !== 'casino_history' && (
          <select onChange={(ev: any) => selectTransactionType(ev.target.value)} value={selectedType}>
            {TRANSACTION_TYPES.map((type) => (
              <option key={type.name} value={type.value}>
                {t(`types.${type.name}`)}
              </option>
            ))}
          </select>
        )}
        <select onChange={(ev: any) => filterByPeriod(ev.target.value)} value={period}>
          {PERIOD_FILTER_TYPES.map((period_filter: { name: string; value: string }) => (
            <option key={period_filter.value} value={period_filter.value}>
              {t(period_filter.name)}
            </option>
          ))}
        </select>
      </div>
      {period === 'manual' && (
        <div className="rangepicker">
          <Suspense fallback={<div>Loading...</div>}>
            <DatePicker
              showIcon
              selected={startDate ? startDate : defaultStartDate}
              onChange={(date: Date | null) => setStartDate(date)}
              className="picker"
              id="picker1"
              placeholderText={t('from')}
              maxDate={endDate ? endDate : defaultValueEndDate}
            />
            <DatePicker
              showIcon
              selected={endDate ? endDate : defaultValueEndDate}
              onChange={(date: Date | null) => setEndDate(date)}
              className="picker"
              id="picker2"
              placeholderText={t('to')}
              minDate={startDate ? startDate : defaultStartDate}
            />
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default Filters;
