import React, { FC } from 'react';

type Props = {
  listInfo: { view: string; title: string | null; info: string[] };
};

const List: FC<Props> = ({ listInfo }) => {
  return (
    <>
      {listInfo?.title && <p>{listInfo.title}</p>}
      <ul>
        {listInfo.info.map((el: string, idx: number) => (
          <li key={idx}>{el}</li>
        ))}
      </ul>
    </>
  );
};

export default List;
