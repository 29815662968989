import { createAction } from 'deox';
import {
  DIALOG_MANAGER_CLOSE_DIALOG,
  DIALOG_MANAGER_CLOSE_ERROR_MESSAGE,
  DIALOG_MANAGER_OPEN_DIALOG,
  DIALOG_MANAGER_OPEN_ERROR_MESSAGE,
  EnumDialogsKeys,
  ICloseDialogPayload,
  IOpenDialogPayload,
} from './types';

export const openDialog = createAction(
  DIALOG_MANAGER_OPEN_DIALOG,
  (resolve) =>
    <T extends EnumDialogsKeys>(payload: IOpenDialogPayload<T>) =>
      resolve(payload)
);

export const closeDialog = createAction(
  DIALOG_MANAGER_CLOSE_DIALOG,
  (resolve) =>
    <T extends EnumDialogsKeys>(payload: ICloseDialogPayload<T>) =>
      resolve(payload)
);

export const openErrorMessage = createAction(DIALOG_MANAGER_OPEN_ERROR_MESSAGE, (res) => (params) => res(params));
export const closeErrorMessage = createAction(DIALOG_MANAGER_CLOSE_ERROR_MESSAGE);
