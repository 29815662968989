import { FC } from 'react';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import '../styles.scss';

type Props = {
  t: (arg: string) => string;
};
const ViewWithoutResult: FC<Props> = ({ t }) => (
  <div className="wrapper">
    <Typography className="subTitle" variant={'h6'}>
      {t('searchDefaultText')}
    </Typography>
    <div className="result_search_icon">
      <SvgIcon src="/images/icons/svg/content_search.svg" />
    </div>
  </div>
);
export default ViewWithoutResult;
