import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { getRewards } from 'src/helpers/utils';
import { RootState } from 'src/types/store-types';
import _styles from '../../styles.module.scss';

type Props = {
  reward: Reward;
  title: string;
};
const Reward: FC<Props> = ({ reward, title }) => {
  const { money } = reward;

  const { t } = useTranslation();

  const personalInfo = useSelector((state: RootState) => state.user.user?.personalInfo);
  const { referralLinks } = useSelector((state: RootState) => state.user);

  const { cash, spins } = getRewards(personalInfo, referralLinks, money);

  return (
    <div className={_styles.collection}>
      <Typography variant={'h5'} className={_styles.title}>
        {t(`refer_a_friend.${title}`)}
      </Typography>
      <div className={_styles.list}>
        <div className={_styles.listItem}>
          <Typography className={_styles.title} variant={'h6'}>
            {t('refer_a_friend.cash')}
            {!isMobileOnly && ':'}
          </Typography>
          <span className={_styles.value}>{cash}</span>
        </div>
        <div className={_styles.listItem}>
          <Typography className={_styles.title} variant={'h6'}>
            {t('points')} {!isMobileOnly && ':'}
          </Typography>
          <span className={_styles.value}>{spins}</span>
        </div>
      </div>
    </div>
  );
};
export default Reward;
