import { createContext } from 'react';

export interface II18nContextProps {
  locale: string;
  locales: SelectOptionType[];
  setLocale: (locale: string) => void;
  setLocales: (locales: SelectOptionType[]) => void;
}

export const I18nContext = createContext<II18nContextProps>({
  locale: process.env.REACT_APP_FALLBACK_LANGUAGE as string,
  locales: [],
  setLocale: () => null,
  setLocales: () => null,
});
