import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GameCard from 'src/components/common/GameCard';
import _styles from './styles.module.scss';

type Props = {
  games: any[];
};
const ViewWithResult: FC<Props> = ({ games }) => {
  const { t } = useTranslation();
  return (
    <div className={_styles.result_body_wrapper}>
      <p className={_styles.result_search_title}>{t('searchResults')}</p>
      <div className={_styles.result_body}>
        {games?.map((game: any) => (
          <GameCard
            key={game.src}
            data={game}
            showDetails={false}
            effects={{
              hoverType: 'fullWidthImage',
              src: '',
              text: '',
            }}
            size={'sm'}
          />
        ))}
      </div>
    </div>
  );
};
export default ViewWithResult;
