import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { icons } from '../../../configs/icons';
import { RootState } from '../../../types/store-types';
import { SvgIcon } from '../SvgIcon';
import _styles from './styles.module.scss';

type Props = {
  list: SelectOptionType[];
  selected: SelectOptionType | null;
  handleSelect?: (option: SelectOptionType) => void;
  styles?: Record<string, string>;
  title?: string;
  variant?: 'default' | 'link';
  className?: string;
  customClassname?: string;
  listClassName?: string;
  position?: string;
  withImg?: boolean;
  tabIndex?: number;
  setIsDropdownOpen?: (isOpen: boolean) => void;
  containerClassName?: string;
};

const Dropdown: FC<Props> = ({
  selected,
  list,
  handleSelect,
  title,
  styles,
  variant,
  className = '',
  listClassName = '',
  position = '',
  withImg = false,
  tabIndex = 0,
  setIsDropdownOpen,
  containerClassName,
}) => {
  const { t }: Translation = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [labelItem, setLabelItem] = useState<SelectOptionType | null>(null);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const toggleDropdown = (): void => {
    setIsDropdownOpen && setIsDropdownOpen(true);
    setIsOpen(!isOpen);
  };

  const hideDropdown = (): void => {
    setIsOpen(false);
  };

  const chooseItem = (value: SelectOptionType): void => {
    if (labelItem !== value) {
      if (handleSelect) {
        handleSelect(value);
      }

      if (variant === 'link') {
        sessionStorage.setItem('lang', JSON.stringify(value.label));
      }
      setLabelItem(value);
    }

    hideDropdown();
  };

  const renderDataDropDown = (item: SelectOptionType, index: number): any => {
    const { value, label, iconSrc } = item;

    return label === labelItem?.label ? null : (
      <li className={_styles['dropdown-btn']} key={index} value={value} onClick={() => chooseItem(item)}>
        <div className={_styles['dropdown-menu-item']}>
          {iconSrc && <img src={withImg ? iconSrc : icons[iconSrc]} alt={label} className={_styles.iconSrc} />}
          <span>{t(label)}</span>
        </div>
      </li>
    );
  };

  const topPosition = useMemo(() => {
    return position === 'top' ? `-${(list.length - 1) * 80}%` : '';
  }, [position, list]);

  useEffect(() => {
    setLabelItem(selected);
  }, [selected]);

  return (
    <div
      style={styles}
      className={cn(
        _styles['dropdown'],
        {
          [_styles[`open`]]: isOpen ? 'open' : '',
          [_styles.lngBar]: generalConfigs.isVertical && isDesktop,
        },
        containerClassName
      )}
      tabIndex={tabIndex}
      onBlur={hideDropdown}
    >
      <div className={cn(_styles['dropdown-toggle'], className)} onClick={toggleDropdown}>
        <div className={_styles['dropdown-menu-item']}>
          {labelItem?.iconSrc && (
            <img src={withImg ? labelItem.iconSrc : icons[labelItem.iconSrc]} alt={labelItem?.label} />
          )}
          {labelItem ? labelItem.label : title || ''}
        </div>
        <SvgIcon pointer src={icons.arrowDown} color="white" />
      </div>

      <ul className={cn(_styles['dropdown-menu'], _styles[listClassName], listClassName)} style={{ top: topPosition }}>
        {list?.map(renderDataDropDown)}
      </ul>
    </div>
  );
};

export default Dropdown;
