import { Pagination } from 'antd';
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'src/components/common/Loader';
import { transactionRequest } from 'src/services/userApi';
import { RootState } from 'src/types/store-types';
import { GAME_MOVEMENTS, HISTORY_REQ_LIMIT, TRANSACTIONS_MOVEMENTS } from 'src/utils/constants';
import Filters from './Filters';
import './styles.scss';
import TransactionTable from './TransactionTable';

const History: FC = () => {
  const { subSection } = useSelector((state: RootState) => state.accountModal);

  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [transactionData, setTransactionData] = useState<[]>([]);
  const [period, setPeriod] = useState<string>('day');
  const [range, setRange] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<'gameMovement' | 'movement'>('movement');
  const [result, setResult] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const transactionTypes = useMemo(() => {
    if (selectedType === 'movement') {
      return TRANSACTIONS_MOVEMENTS;
    } else {
      return GAME_MOVEMENTS;
    }
  }, [selectedType]);

  const changeType = (type: 'gameMovement' | 'movement'): void => {
    setSelectedType(type);

    let _transactionTypes = GAME_MOVEMENTS;

    if (type === 'movement') {
      _transactionTypes = TRANSACTIONS_MOVEMENTS;
    }

    setPage(1);
    setPeriod('day');

    getTransactionData(1, undefined, undefined, 'day', _transactionTypes);
  };

  const getTransactionData = (
    _page: number,
    startDate?: any,
    endDate?: Date | null,
    _period?: any,
    _transactionType?: string[]
  ): void => {
    transactionRequest(
      {
        limit: HISTORY_REQ_LIMIT,
        transactionTypes: _transactionType ?? transactionTypes,
        page: _page,
        dateFrom:
          startDate && startDate !== null
            ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            : moment()
                .subtract(1, _period ?? period)
                .toISOString(),
        dateTo:
          endDate && endDate !== null ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : moment().toISOString(),
      },
      subSection
    ).then((res) => {
      if (!!res?.result) {
        setResult(true);
        setTransactionData(res.result.movements);
        setCount(res.result.totalCount);
      }
    });
  };

  const changePage = (currentPage: number): void => {
    setPage(currentPage);
    getTransactionData(currentPage, range[0] || (period == 'manual' && startDate), range[1], period);
  };

  const filterByPeriod = (period: string): void => {
    setPeriod(period);
    setPage(1);
    if (period == 'manual') {
      setEndDate(null);
      getTransactionData(1, startDate, undefined, 'day');
    } else {
      setRange([]);
      getTransactionData(1, undefined, undefined, period);
    }
  };

  const filterByDate = (_range: (Date | any)[]): void => {
    if (!isFirstLoad) {
      getTransactionData(1, _range[0], _range[1], 'manual');
      setRange(_range);
      setPage(1);
    }
  };

  useEffect(() => {
    setTransactionData([]);
    setPeriod('day');
    setRange([]);
    setPage(1);

    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      getTransactionData(1, undefined, undefined, 'day');
    }
  }, [subSection, isFirstLoad]);

  return (
    <div className="transaction_history_wrapper">
      <Filters
        selectTransactionType={changeType}
        selectedType={selectedType}
        filterByPeriod={filterByPeriod}
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        endDate={endDate}
        filterByDate={filterByDate}
        period={period}
        historyType={subSection}
      />
      <div className="table_wrapper">
        {result ? (
          <TransactionTable data={transactionData} selectedType={selectedType} subSection={subSection} />
        ) : (
          <div className="loader">
            <Loader />
          </div>
        )}
        {count > HISTORY_REQ_LIMIT && (
          <div className="entries_table_pagination">
            <Pagination
              defaultCurrent={1}
              pageSize={HISTORY_REQ_LIMIT}
              total={count}
              showSizeChanger={false}
              current={page}
              onChange={changePage}
              className="pagination"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default History;
