import cn from 'classnames';
import { FC, useEffect, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import useNavigation from 'src/hooks/use-navigation';
import { setCloseUserSettings } from 'src/store/app/actions';
import { RootState } from 'src/types/store-types';
import { Button } from '../../common/Button';
import './styles.scss';

const GAMEBOX_WIDGET = 'gamebox-widget';

type Props = {
  settings: {
    needLogin: boolean;
    priority: number;
    styles: Styles;
    classes: string;
  };
};
const GameBoxToggle: FC<Props> = ({ settings }) => {
  const moduleLibIntegration = (window as any)?._gamebox_moduleList
    ? (window as any)?._gamebox_moduleList?.includes(GAMEBOX_WIDGET)
    : null;

  const { styles, classes } = settings;

  const navigationManager = useNavigation();

  const _setCloseUserSettings = useConnectedAction(setCloseUserSettings);

  const { isGameBoxOpen, selectedCategory, showUserSetting } = useSelector((state: RootState) => state.app);
  const { actualMissions } = useSelector((state: RootState) => state.integrations);

  const iframe = document?.getElementById('gamebox-widget') as HTMLIFrameElement;

  const missionsCount = useMemo(() => {
    if (actualMissions === 0) return;

    return <span className="actual_missions">{actualMissions}</span>;
  }, [actualMissions]);

  const onToggle = (): void => {
    navigationManager.openGamebox();
  };

  useEffect(() => {
    if (moduleLibIntegration && isGameBoxOpen) {
      (window as any)?.__gdlib?.openGameBoxWidget({
        module: GAMEBOX_WIDGET,
        parentId: GAMEBOX_WIDGET, // maybe need to change parent id => parent name
        customClassName: `frame`,
      });
    } else {
      iframe?.contentWindow?.postMessage(
        {
          type: 'CHANGE_WIDGET_VIEW',
          view: isGameBoxOpen ? 'widget' : '',
        },
        '*'
      );
    }
  }, [isGameBoxOpen, selectedCategory]);

  useEffect(() => {
    if (isGameBoxOpen) {
      showUserSetting && _setCloseUserSettings();
    }

    isMobileOnly && setBodyOverflow(isGameBoxOpen ? 'unset' : 'set');
  }, [isGameBoxOpen]);

  return (
    <div className="gameBox_toggle_wrapper" onClick={() => onToggle()}>
      <Button
        ariaLabelledby="gameBoxWidget"
        fontWeight="bold"
        variant="contained"
        color="transparent"
        className={cn('toggle_wrapper', {
          [classes]: !!classes,
          opened: isGameBoxOpen,
        })}
        style={styles}
      >
        <img src={`/images/gbIcon.png`} alt="gameBox" />
      </Button>
      <Typography className="label" variant={'h6'}>
        {missionsCount}
      </Typography>
    </div>
  );
};
export default GameBoxToggle;
