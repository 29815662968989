import HttpClient from './http';

const getBiggestWins = (hierarchyId: number): Promise<any> => {
  return HttpClient.get({
    path: `getBetWinVisualFeed?hierarchyId=${hierarchyId}&tag=biggest`,
    baseURL: process.env.REACT_APP_BC_API_V3 as string,
  });
};

const getLatestWins = (hierarchyId: number): Promise<any> => {
  return HttpClient.get({
    path: `getBetWinVisualFeed?hierarchyId=${hierarchyId}&tag=latest`,
    baseURL: process.env.REACT_APP_BC_API_V3 as string,
  });
};

export { getBiggestWins, getLatestWins };
