import cn from 'classnames';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useFullScreen } from 'react-browser-hooks';
import { isIOS, isMobile, isMobileOnly, isSafari } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { isJsonString, mobileClassListHandler } from 'src/helpers/utils';
import { useDetectIosFs } from 'src/hooks/use-detectIosFs';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { RootState } from 'src/types/store-types';
import { LANGUAGES_CODES, SPORT_CATEGORIES } from 'src/utils/constants';
import '../../styles.scss';

type Props = {
  iframeSrc: {
    loggedIn: string;
    loggedOut: string;
  };
  iframeClasses: string;
  id: string;
  actions: () => { [key: string]: any };
  user: any;
  selectedCategory: string;
  setIsLoading: (isLoading: boolean) => void;
};
const NovusSportBook: FC<Props> = ({ iframeSrc, id, actions, selectedCategory, user, setIsLoading, iframeClasses }) => {
  const iOSFullScreened = useDetectIosFs();
  const androidFSDetector = useFullScreen();
  const { locale } = useI18n();
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const { _openAuthModal, _setBalance, _setLogout } = actions();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(iOSFullScreened);
  const [androidFS, setAndroidFS] = useState<any>();
  const [sportIframe, setSportFrame] = useState<any>();
  const [blockScroll, setBlockScroll] = useState<boolean>(false);
  const [className, setClassName] = useState<string>('');

  const handleMessages = (event: any): void => {
    if (typeof event.data !== 'string') return;

    if (isJsonString(event?.data)) {
      const triggerAction = JSON.parse(event?.data);

      if (triggerAction?.loginRequired !== undefined) {
        _setLogout();
        _openAuthModal('login');

        if (!localStorage.getItem('user')) {
          _setBalance();
        }
      }
    }
  };

  useEffect(() => {
    let timeOut: NodeJS.Timeout;

    if (blockScroll) {
      timeOut = setTimeout(() => {
        setBlockScroll(false);
      }, 1000);
    }

    return () => clearTimeout(timeOut);
  }, [blockScroll]);

  useEffect(() => {
    if (isMobile) {
      setIsFullscreen(iOSFullScreened);

      if (SPORT_CATEGORIES.includes(selectedCategory)) {
        setBlockScroll(true);
      }
    }
  }, [iOSFullScreened]);

  useEffect(() => {
    if (isMobile) {
      setAndroidFS(androidFSDetector.fullScreen);

      if (SPORT_CATEGORIES.includes(selectedCategory)) {
        setBlockScroll(true);
      }
    }
  }, [androidFSDetector.fullScreen]);

  useEffect(() => {
    window.addEventListener('message', (event) => handleMessages(event));

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, []);

  useEffect(() => {
    if (!!sportIframe) {
      sportIframe?.postMessage(
        JSON.stringify({
          changeLanguage: LANGUAGES_CODES[locale],
        }),
        '*'
      );
    }
  }, [locale, sportIframe]);

  useEffect(() => {
    if (SPORT_CATEGORIES.includes(selectedCategory) && !localStorage.getItem('user')) {
      _setBalance();
    }
  }, [selectedCategory]);

  useLayoutEffect(() => {
    mobileClassListHandler(isMobile, selectedCategory);
  }, [selectedCategory, isMobile, isFullscreen, androidFS]);

  useEffect(() => {
    const frame = (document?.getElementById(id) as HTMLIFrameElement)?.contentWindow;

    if (!!frame) {
      setSportFrame(frame);
    }
  }, []);

  useEffect(() => {
    if (isMobileOnly && isIOS) {
      if (isSafari) {
        setClassName(`${iframeClasses} safary_sport_frame`);
      } else {
        setClassName(`${iframeClasses} ios_sport_frame`);
      }
    } else {
      setClassName(iframeClasses);
    }
  }, [isIOS, isMobileOnly, selectedCategory, iframeClasses]);

  return (
    <iframe
      className={cn(className, {
        ['fixed-to-bottom']: selectedCategory === 'betting' && process.env.REACT_APP_HIDE_SPORT === 'true',
        ['fullWidth']: selectedCategory === 'betting' && generalConfigs?.isFullWidth,
      })}
      src={user ? iframeSrc.loggedIn : iframeSrc.loggedOut}
      id={id}
      onLoad={() => {
        setIsLoading(false);
      }}
    />
  );
};
export default NovusSportBook;
