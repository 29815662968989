import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import '../styles.scss';

const RaceIframe: FC<FrameTypes> = ({ iframeSrc, iframeId, iframeClasses, user }) => {
  const { locale } = useI18n();
  const moduleLibIntegration = (window as any)?._gamebox_moduleList
    ? (window as any)?._gamebox_moduleList?.includes('race')
    : null;

  const navigate = useNavigate();

  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const iframe = (document?.getElementById(iframeId) as HTMLIFrameElement)?.contentWindow;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleMessages = (event: any): void => {
    if (event.data.type === 'CHANGE_CATEGORY') {
      _setSelectedCategory(event.data.category);
      navigate(`/category/${event.data.category}`);
    }

    if (event.data.type == 'OPEN_LOGIN' && !moduleLibIntegration) {
      _openAuthModal('login');
    }
  };

  useEffect(() => {
    if (iframe && locale) {
      iframe.postMessage({ type: 'CHANGE_LANGUAGE', language: locale }, '*');
    }
  }, [locale, iframe]);

  useEffect(() => {
    window.addEventListener('message', handleMessages);
    return () => window.removeEventListener('message', handleMessages);
  }, []);

  useEffect(() => {
    if (moduleLibIntegration) {
      (window as any)?.__gdlib?.startWidget({
        module: 'race',
        parentId: 'raceId',
        customClassName: 'frame raceIframe',
      });
    }
    return () => {
      if (moduleLibIntegration) {
        (window as any)?.__gdlib?.closeWidget('race');
      }
    };
  }, [moduleLibIntegration]);

  if (moduleLibIntegration) {
    return null;
  }
  return (
    <iframe
      className={iframeClasses}
      id={iframeId}
      src={user ? iframeSrc?.loggedIn : iframeSrc?.loggedOut}
      allow="autoplay"
    />
  );
};

export default RaceIframe;
