import { createReducer } from 'deox';
import produce from 'immer';
import { setTransactions, setTrasactionNotification } from './actions';
import { transactionsState } from './types';

export const transactionsInitialState: transactionsState = {
  transactionStatus: null,
  transactionLoading: false,
  transactionNotification: null,
};

export const transactionsReducer = createReducer(transactionsInitialState, (handle) => [
  handle(setTransactions.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.transactionStatus = payload;
    })
  ),
  handle(setTransactions.load, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.transactionLoading = payload;
    })
  ),
  handle(setTrasactionNotification, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.transactionNotification = payload;
    })
  ),
]);
