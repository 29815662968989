import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  tag: string;
  onGameTagChange: (ev: string) => void;
  selectedTag: string;
  label: string | undefined;
};
const Tag: FC<Props> = ({ tag, onGameTagChange, selectedTag, label = '' }) => {
  const { t }: Translation = useTranslation();

  return (
    <div
      className={cn('gameTag', 'tab-btn', { ['selected']: selectedTag === tag })}
      onClick={() => onGameTagChange(tag)}
      id={tag}
    >
      {t(tag)}
      {label && <span className="gameTag__label">{label}</span>}
    </div>
  );
};
export default Tag;
