import cn from 'classnames';
import { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import Zendesk from 'react-zendesk';
import { icons } from 'src/configs/icons';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setDeepLink } from 'src/store/app/actions';
import { RootState } from 'src/types/store-types';
import _style from './styles.module.scss';

type Props = {
  secretKey: string;
  imgSrc?: string;
  styles?: Record<string, string>;
};
const ZendeskChat: FC<Props> = ({ secretKey, imgSrc = '', styles = {} }) => {
  const { deepLink } = useSelector((state: RootState) => state.app);

  const _setDeepLink = useConnectedAction(setDeepLink);

  const openChat = (): void => {
    (window as any)?.zE && (window as any)?.zE('messenger', 'open');
  };

  const zendeskLoadEvent = (): void => {
    (window as any)?.zE('webWidget:on', 'close', function () {
      (window as any)?.zE('webWidget', 'hide');
    });
  };

  useEffect(() => {
    window.addEventListener('touchmove', (e) => {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    if (deepLink === 'liveChat' && (window as any)?.zE) {
      openChat();
      _setDeepLink('');
    }
  }, [deepLink, (window as any)?.zE]);

  return (
    <div className={_style.zendesk_chat_conatiner}>
      {!isMobile && (
        <img
          style={styles}
          src={imgSrc ? imgSrc : icons.chat}
          onClick={openChat}
          className={cn(_style.chat_icon, { [_style.zendesk_icon]: imgSrc })}
          alt="zendesk chat icon"
        />
      )}
      <Zendesk defer zendeskKey={secretKey} onLoaded={zendeskLoadEvent} />
    </div>
  );
};
export default ZendeskChat;
