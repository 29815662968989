import cn from 'classnames';
import { FC, ReactNode } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ClientSidePortalRp } from '../../../../components/render-props/ClientSidePortalRp';
import styles from './styles.module.scss';

type Props = {
  bodyHeight?: 'default' | 'full';
  children: ReactNode;
  width?: 'xs' | 'sm' | 'md' | 'min-content' | 'full';
  open: boolean;
  placement: 'center' | 'right' | 'left';
  hasOverlay?: boolean;
  className?: string[];
  src?: string;
};

export const ModalBase: FC<Props> = ({
  bodyHeight,
  children,
  width = 'sm',
  open,
  placement = 'center',
  hasOverlay = true,
  className = [],
  src,
}) => {
  return (
    <ClientSidePortalRp>
      {open ? (
        <div className={cn({ [styles.portal]: src && isMobileOnly })}>
          {isMobileOnly && src && (
            <div className={styles.image_container}>{src && <img src={src} alt={'Content image'} />}</div>
          )}
          <div
            className={cn(styles.modal, className, {
              [styles.modal_open]: open,
              [styles.fullHeight]: className.includes('fullHeight'),
              [styles.fullSize]: className.includes('fullSize'),
            })}
          >
            <div
              className={cn(styles.modal__content, styles[`${placement}`], {
                [styles[`modal__content_width_${width}`]]: width,
                [styles[`modal__content_bodyHeight_${bodyHeight}`]]: bodyHeight,
              })}
            >
              {children}
            </div>

            {hasOverlay && <div className={styles.modal__backdrop} />}
          </div>
        </div>
      ) : null}
    </ClientSidePortalRp>
  );
};
