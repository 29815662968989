import { Banner } from 'src/types/common-types/slider';
import {
  SLIDER_MOBILE_COUNT,
  TEMPLATE_1_SLIDER_GAMES_COUNT,
  TEMPLATE_2_SLIDER_GAMES_COUNT,
  WIN_SLIDER_COUNT_DESKTOP,
  WIN_SLIDER_COUNT_MOBILE,
} from 'src/utils/constants';

export class SliderDataTransforamtionHelper {
  public static setSliderItemObj = (data: any[], cols: number): Banner[][] => {
    const initial = [...data];

    const missingCount = data.length % cols;
    const needSlice = missingCount ? cols - missingCount : 0;
    initial.push(...initial.slice(0, needSlice));

    return initial
      .map(function (_, i) {
        return initial.slice(i * cols, i * cols + cols);
      })
      .filter((f) => !!f.length);
  };

  public static setGameSliderTemplate1 = (data: any[]): any[][] => {
    const initial = [...data];

    if (!!initial.length) {
      const r = initial
        .map((_, i) => {
          return i === 0
            ? initial.slice(0, TEMPLATE_1_SLIDER_GAMES_COUNT - 3)
            : initial.slice(
                i * TEMPLATE_1_SLIDER_GAMES_COUNT - 3,
                i * TEMPLATE_1_SLIDER_GAMES_COUNT - 3 + TEMPLATE_1_SLIDER_GAMES_COUNT
              );
        })
        .map((m) => {
          return m.map((j) => {
            j.rows = 1;
            j.cols = 1;

            return j;
          });
        })
        .filter((f) => !!f.length);

      r[0][0].rows = 2;
      r[0][0].cols = 2;

      return r;
    }

    return [[]];
  };

  public static setGameSliderTemplate2Mobile = (data: any[]): any[][] => {
    let initial: any[] = [];

    if (!!data.length) {
      initial = [...data]?.map((m: any) => {
        m.cols = 1;
        m.rows = 1;

        return m;
      });
    }

    const result = initial
      .map(function (_, i) {
        return initial.slice(i * 3, i * 3 + 3);
      })
      .filter((f) => !!f.length);

    const lastArr = result[result.length - 1];

    if (lastArr.length < 3 && result.length > 3) {
      result[result.length - 1] = [...lastArr, ...result[0].slice(0, 3 - lastArr.length)];
    }

    return result;
  };

  public static setGameSliderTemplate2 = (data: any[], isMobile: boolean): any[][] => {
    let initial: any[] = [];

    if (!!data.length) {
      initial = [...data]?.map((m: any) => {
        m.cols = 1;
        m.rows = 1;

        return m;
      });
    }

    const startIdx = isMobile ? SLIDER_MOBILE_COUNT : TEMPLATE_2_SLIDER_GAMES_COUNT;

    return initial
      .map(function (_, i) {
        return initial.slice(i * startIdx, i * startIdx + startIdx);
      })
      .filter((f) => !!f.length);
  };

  public static setGameSliderTemplate3 = (data: any[]): any[][] => {
    if (!!data.length) {
      data.map((m: any) => {
        const img = new Image();
        img.src = m.thumbnail;
        img.onload = () => {
          m.width = img.width;
        };
        return m;
      });
    }

    return data;
  };

  public static setWinSliderTemplate = (data: Win[], isMobileOnly: boolean): any[][] => {
    let initial: any[] = [];

    if (!!data.length) {
      initial = [...data]?.map((m: any) => {
        m.cols = 1;
        m.rows = 1;

        return m;
      });
    }

    const sliceCount = isMobileOnly ? WIN_SLIDER_COUNT_MOBILE : WIN_SLIDER_COUNT_DESKTOP;

    return initial
      .map(function (_, i) {
        return initial.slice((i - 1) * sliceCount, i * sliceCount);
      })
      .filter((f) => !!f.length);
  };

  public static setEqualTagGames = (tags: any[]): any[] => {
    if (!!tags.length) {
      return tags?.map((tag) => {
        tag.cols = 1;
        tag.rows = 1;

        return tag;
      });
    }

    return [];
  };
}
