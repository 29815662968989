import cn from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
    needLogin: boolean;
    classes: 'dark' | 'primary' | 'light';
  };
};
const NewsLetter: FC<Props> = ({ settings }) => {
  const { styles, classes } = settings;

  const { customI18n } = useSelector((state: RootState) => state.app);

  return (
    <div className={cn('newsLetter_container', classes)} style={styles}>
      <Typography className="text" variant={'body4'} component={'p'}>
        {customI18n?.newsLetter || 'NEWS_LETTER'}
      </Typography>
    </div>
  );
};
export default NewsLetter;
