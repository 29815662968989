import { FC, MouseEvent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { MediaManager } from 'src/manager/MediaManager';
import { resetSectionLoading, setSelectedCategory } from 'src/store/app/actions';
import { EnumRoutePaths } from '../../../configs/routes';
import { scrollToTop } from '../../../helpers/utils';
import './styles.scss';

type Props = {
  settings?: {
    src?: string;
    styles?: Styles;
    priority?: number;
    needLogin?: false;
  };
};
export const LogoGif: FC<Props | null> = (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _resetSectionLoading = useConnectedAction(resetSectionLoading);

  const handleClick = (e: MouseEvent<HTMLElement> | TouchEvent): void => {
    e.preventDefault();
    _resetSectionLoading();
    _setSelectedCategory('home');

    if (pathname === EnumRoutePaths.DEFAULT) return;

    navigate(EnumRoutePaths.DEFAULT);

    scrollToTop();
  };

  return (
    <div className="logo_gif_container" onClick={handleClick} style={props?.settings?.styles}>
      <div className="logo_gif_container_inner">
        <Link to={EnumRoutePaths.DEFAULT}>
          <img
            className="logo_gif_container_image"
            src={MediaManager.getSrcFromMediaAssets(isMobileOnly ? 'mobileLoaderGif.png' : 'logo.svg', '/')}
            style={props?.settings?.styles}
            alt="Logo"
          />
        </Link>
      </div>
    </div>
  );
};
