import cn from 'classnames';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import PersisterService from 'src/services/PersisterService';
import { setBottomNavigation } from 'src/store/configs/actions';
import Dropdown from '../../../components/common/Dropdown';
import { useI18n } from '../../../hooks/use-i18n-translation';
import _styles from './styles.module.scss';

type Props = {
  settings?: {
    classes: string;
    priority?: number;
    needLogin?: false;
  };
};
const Language: FC<Props> = ({ settings }) => {
  const { locales, setLocale, locale } = useI18n();
  const { i18n }: Translation = useTranslation();
  const _setBottomNavigation = useConnectedAction(setBottomNavigation);

  const changeLanguage = (ev: any): void => {
    if (PersisterService.hasPersistValue('depositConfig')) {
      sessionStorage.removeItem('depositConfig');
      _setBottomNavigation([]);
    }
    i18n.changeLanguage(ev.value);
    setLocale(ev.value);
    localStorage.setItem('selectedLanguage', ev.value);
  };

  return (
    <Dropdown
      handleSelect={changeLanguage}
      selected={{
        label: locale,
        value: locale,
        iconSrc: `${locale}Flag`,
      }}
      list={locales}
      variant="link"
      className={cn(_styles.language_wrapper, settings?.classes ?? 'secondary-btn')}
      listClassName={settings?.classes ?? 'secondary-btn'}
      position={isMobileOnly ? 'top' : ''}
    />
  );
};
export default Language;
