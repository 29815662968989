import cn from 'classnames';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Logo } from 'src/components/base-components/Logo';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { MediaManager } from 'src/manager/MediaManager';
import { accountModalState } from 'src/store/account-modal/types';
import { setShowUserSettings } from 'src/store/app/actions';
import { toggleMobileBottomNavigation, toggleMobileMenu } from 'src/store/mobile/actions';
import { RootState } from 'src/types/store-types';
import _styles from './styles.module.scss';

type Props = {
  View: JSX.Element;
  accountModal: accountModalState;
  close: () => void;
};
const MobileAccountModal: FC<Props> = ({ View, accountModal, close }) => {
  const { t } = useTranslation();

  const _toggleMobileBottomNavigation = useConnectedAction(toggleMobileBottomNavigation);
  const _setShowUserSettings = useConnectedAction(setShowUserSettings);
  const _toggleMobileMenu = useConnectedAction(toggleMobileMenu);

  const { showBottomNavigation, showMenu } = useSelector((state: RootState) => state.mobile);
  const { showUserSetting } = useSelector((state: RootState) => state.app);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const { modalContent } = useSelector((state: RootState) => state.authModal);

  setBodyOverflow('unset');

  const onClose = (): void => {
    close();

    if (isMobileOnly && accountModal.section === 'info_and_rules' && showBottomNavigation) {
      _toggleMobileBottomNavigation();
    }
    isMobileOnly && !modalContent && !showMenu && !showUserSetting && setBodyOverflow('set');
  };

  const closeModal = (): void => {
    onClose();
    setBodyOverflow('set');
    showUserSetting && _setShowUserSettings();
    showMenu && _toggleMobileMenu();
  };

  const redirectToHome = (): void => {
    showUserSetting && _setShowUserSettings();
  };

  return (
    <div className={_styles.info_mobile_conatiner}>
      {isMobileOnly && showBottomNavigation && (
        <div className={cn(_styles.info_mobile_header, { [_styles.bottom_nav]: showBottomNavigation })}>
          <SvgIcon src={icons.arrowDown} onClick={onClose} className={_styles.arrowDown} />
          <div className={_styles.info_header}>
            <img src={MediaManager.getSrcFromMediaAssets('logo-mobile.svg', '/')} alt="Logo" />
          </div>
        </div>
      )}
      <div
        className={cn(_styles.mobile_modal_container, _styles.account_modal_mobile, generalConfigs?.template, {
          [_styles.full_height]: accountModal.fullHeight,
        })}
      >
        {!showBottomNavigation && (
          <div className={_styles.mobile_header}>
            <div>
              <SvgIcon src={icons.arrowDown} onClick={onClose} className={_styles.arrowDown} />
              <span>{t(accountModal?.subTitle ? accountModal?.subTitle : '')}</span>
            </div>
            {!accountModal?.subTitle && (
              <div onClick={redirectToHome}>
                <Logo />
              </div>
            )}
            <button type="button" className={_styles.close_menu_wrapper} onClick={closeModal}>
              <SvgIcon src={icons.close} />
            </button>
          </div>
        )}
        <div className={_styles.account_modal_mobile_content}>{View}</div>
      </div>
    </div>
  );
};

export default MobileAccountModal;
