import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { getSideBarNavigationConfigs } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { RootState } from 'src/types/store-types';
import { Constructor } from './constructor';
import './styles.scss';

const DesktopSideBarConstructor: FC = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const { sideBarMenuIsOpen } = useSelector((state: RootState) => state.app);

  const [configs, setConfigs] = useState<Settings>({
    template: '',
    sections: [],
    styles: {},
  });

  const sideBarComponent = useMemo(
    () => (
      <>
        <div className="sidebar_row_content">
          {!!configs?.sections?.length && Constructor.renderWidgets(configs?.sections?.[0])}
        </div>
        <div className="sidebar_scrollable_content">
          {!!configs?.sections?.length &&
            configs.sections?.slice(1)?.map((section: any) => {
              return (
                <div className="sidebar_row_content" key={Object.keys(section)[0]}>
                  {Constructor.renderWidgets(section)}
                </div>
              );
            })}
        </div>
      </>
    ),
    [configs.sections]
  );

  const getSideBarConfigs = async (): Promise<void> => {
    try {
      if (PersisterService.hasPersistValue('sideBarNavigation')) {
        const res = PersisterService.getPersistValue('sideBarNavigation');
        setConfigs(res);
      } else {
        const res = await getSideBarNavigationConfigs('desktop');

        if (res.success) {
          setConfigs(res.result);
          PersisterService.setPersistValue('sideBarNavigation', res.result);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSideBarConfigs();
  }, [user]);

  usePostMesssage((v: any) => setConfigs(v), 'sideBarNavigation');

  return (
    <div
      style={configs.styles}
      className={cn('sidebar_global_container', {
        ['sidebar_global_container--opened']: sideBarMenuIsOpen,
      })}
    >
      {configs?.sections && sideBarComponent}
    </div>
  );
};
export default DesktopSideBarConstructor;
