import { APP_API, APP_API_VERSION, BASE_URL } from 'src/utils/constants';
import HttpClient from './http';

const getFriendsList = (params: { offset: number; limit: number }): Promise<FriendsListRes> => {
  return HttpClient.get(
    { path: `${APP_API}/getFriendsList`, baseURL: process.env.REACT_APP_REPORTS as string },
    true,
    params
  );
};

const getRewards = (): Promise<RewardsRes> => {
  return HttpClient.get({ path: `${APP_API}/getRewards`, baseURL: process.env.REACT_APP_REPORTS as string });
};

const getRewardsConfigs = (): Promise<RewardConfigsRes> => {
  return HttpClient.get({ path: `${APP_API}/getRewardsConfigs`, baseURL: process.env.REACT_APP_REPORTS as string });
};

const becomeAReferral = (parentId: number): Promise<BecomeReferralRes> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/becomeReferral`, baseURL: process.env.REACT_APP_REMOTE as string },
    { clientBaseUrl: BASE_URL, parentId: parentId }
  );
};

export { getFriendsList, getRewards, getRewardsConfigs, becomeAReferral };
