import { ChangeEvent, FC, useCallback } from 'react';
import { Input } from 'src/components/common/Input';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required: boolean;
};
const ParentId: FC<IProps> = (props) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    props.onChange(e);
  };

  const handleBlur = useCallback((): void => {
    if (props.value) {
      const parentIdRegExp = props.regexp ? new RegExp(props.regexp) : new RegExp('^[0-9]*$');

      if (!parentIdRegExp.test(props.value)) {
        return;
      }
    }
  }, [props.value]);

  return (
    <Input
      {...props}
      onChange={handleOnChange}
      value={props.value}
      type="number"
      disableNumberSymbols
      label="parentId_label"
      minValue={0}
      onBlur={handleBlur}
      onFocus={handleBlur}
    />
  );
};
export default ParentId;
