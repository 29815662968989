export type accountModalState = {
  visible: boolean;
  error: string;
  section: string;
  openFrom: string;
  subSection: string;
  parentSection: string;
  fullHeight: boolean;
  subTitle?: string;
};

export const OPEN_ACCOUNT_MODAL = 'acccount/OPEN_ACCOUNT_MODAL';
export const CLOSE_ACCOUNT_MODAL = 'account/CLOSE_ACCOUNT_MODAL';
