import { useCallback, useEffect } from 'react';

export const useEnterSubmit = (elemId: string): void => {
  const evCB = useCallback(
    (e: any): void => {
      if (e.key === 'Enter') {
        e.preventDefault();
        document.getElementById(elemId)?.click();
      }
    },
    [elemId]
  );

  useEffect(() => {
    document.addEventListener('keypress', evCB);
    return () => {
      document.removeEventListener('keypress', evCB);
    };
  }, []);
};
