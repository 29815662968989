import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { reachTextParser, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
  };
};
const TermsBriefInfo: FC<Props> = ({ settings }) => {
  const { styles } = settings;
  const { customI18n } = useSelector((state: RootState) => state.app);
  const { t }: Translation = useTranslation();
  const _openAccountModal = useConnectedAction(openMyAccountModal);

  const openTermsAndConditions = (): void => {
    setBodyOverflow('unset');
    _openAccountModal({
      section: 'info_and_rules',
      subSection: 'Terms and Conditions',
      subTitle: t('terms_and_conditions'),
    });
  };

  useEffect(() => {
    customI18n?.websiteTermsBriefInfo &&
      document.getElementById('terms_and_conditions')?.addEventListener('click', openTermsAndConditions);
  }, [customI18n?.websiteTermsBriefInfo]);

  return (
    <Typography className="activity_info_wrapper" variant={'h6'} style={styles}>
      {(customI18n?.websiteTermsBriefInfo && reachTextParser(customI18n?.websiteTermsBriefInfo)) ||
        'BUSINESS_ACTIVITY_INFO'}
    </Typography>
  );
};
export default TermsBriefInfo;
