import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const TLTRaces: FC = () => {
  const { tltWidgetConfig } = useSelector((state: RootState) => state.tltWidgetConfig);
  const racesBlockRef = useRef<HTMLDivElement>(null);
  const racesBlock = racesBlockRef.current;
  useEffect(() => {
    if (tltWidgetConfig?.key && typeof window !== 'undefined' && window['WidgetFactory'] && racesBlock?.id) {
      const factory = new window['WidgetFactory']({
        platform_id: 1,
        op: tltWidgetConfig?.op,
        ts: tltWidgetConfig?.ts,
        key: tltWidgetConfig?.key,
      });
      factory.fetch('widget').then(function (Races: any) {
        new Races({
          el: racesBlock.id,
          limit: { options: [5, 15, 25, 35], default: 5 },
          tabs: [
            { type: 'bets', title: 'Bets' },
            { type: 'bigWins', title: 'Big Wins', multiplier: 3 },
            { type: 'races', title: 'Races' },
          ],
          popupContainer: document.getElementById('overlay'),
          onGameClick: (id: string) => window.alert('game opened: ' + id),
        });
      });
    }
  }, [tltWidgetConfig, racesBlock]);
  return <div id="races" ref={racesBlockRef}></div>;
};
export default TLTRaces;
