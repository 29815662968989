import { useEffect } from 'react';
import { setBodyOverflow } from 'src/helpers/utils';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { useSplashMessages } from 'src/store/messages/selectors';
import { useConnectedAction } from './use-connected-action';

const useMessagesHandler = (): any => {
  const _openDialog = useConnectedAction(openDialog);
  const splashMessages = useSplashMessages();
  const templatedMsg: any = splashMessages.find((msg: any) => msg.templateId);

  useEffect(() => {
    if (templatedMsg?.templateId === 'tourFinished') {
      _openDialog({
        dialogType: EnumDialogsKeys.TOURNAMENT_RESULTS,
        dialogProps: { data: { ...templatedMsg.data, id: templatedMsg._id } },
      });
    } else if (templatedMsg?.data?.subType === 'firstDepositToBonusWallet') {
      _openDialog({
        dialogType: EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET,
        dialogProps: { data: { ...templatedMsg, id: templatedMsg._id } },
      });
      setBodyOverflow('unset');
    }
  }, [splashMessages]);
};

export default useMessagesHandler;
