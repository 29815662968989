import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Typography } from 'src/components/common/Typography';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { becomeAReferral } from 'src/services/referFriend';
import { setReferralLink } from 'src/store/user/actions';
import _styles from '../../styles.module.scss';

const GenerateReferralLink: FC = () => {
  const { t } = useTranslation();

  const _setReferralLink = useConnectedAction(setReferralLink);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const generateLink = (): void => {
    setIsLoading(true);

    setTimeout(() => {
      becomeAReferral(+(process.env.REACT_APP_PARENTID as string))
        .then((res: BecomeReferralRes) => {
          if (res.success) {
            _setReferralLink(res.result);
          }
        })
        .finally(() => setIsLoading(false));
    }, 2000);
  };

  return (
    <div className={_styles.generate_link}>
      <div className={_styles.container}>
        {isLoading ? (
          <>
            <div>
              <Typography variant={'h6'}>{t('refer_a_friend.please_wait')}</Typography>
              <p>{t('refer_a_friend.loading_text')}</p>
            </div>
            <div className={_styles.loader}></div>
          </>
        ) : (
          <>
            <p>{t('refer_a_friend.generate_text')}</p>
            <Button className={_styles.btn} onClick={generateLink}>
              {t('refer_a_friend.generate')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
export default GenerateReferralLink;
