import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    view: string;
  };
};
const TLTJPWidget: FC<Props> = ({ settings }) => {
  const { view } = settings;
  const { tltWidgetConfig } = useSelector((state: RootState) => state.tltWidgetConfig);
  useEffect(() => {
    if (tltWidgetConfig?.key && typeof window !== 'undefined' && window['WidgetFactory']) {
      const config = {
        platform_id: 1,
        op: tltWidgetConfig?.op,
        ts: tltWidgetConfig?.ts,
        key: tltWidgetConfig?.key,
        player: tltWidgetConfig?.player || null,
        currency: tltWidgetConfig?.currency || null,
      };
      const factory = new window['WidgetFactory'](config);
      factory.fetch('jackpot_goodwin').then(function (JPWidget: any) {
        new JPWidget({
          el: 'jackpot_goodwin',
          popupContainer: 'popup',
          navigation: { view },
          settings: {
            logo: {
              url: '/images/jackpot_logo.png',
            },
            totalPrizePool: { show: false },
            jackpotHigh: {
              iconURL: '/images/Gold.png',
            },
            jackpotMedium: {
              iconURL: '/images/Silver.png',
            },
            jackpotLow: {
              iconURL: '/images/Bronze.png',
            },
          },
        });
      });
    }
  }, [tltWidgetConfig]);
  return (
    <div id="tlt-jp-widget">
      <div id="jackpot_goodwin"></div>
      <div id="popup"></div>
    </div>
  );
};
export default TLTJPWidget;
