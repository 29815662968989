import React, { FC } from 'react';
import { Typography } from 'src/components/common/Typography';

type Props = {
  tableInfo: { view: string; title: string | null; info: string[] };
};

const Table: FC<Props> = ({ tableInfo }) => {
  return (
    <div className="gam-care__contacts-wrapper">
      {tableInfo?.info?.map((info: any, idx: number) => (
        <div className="gam-care__contact" key={idx}>
          <Typography className="label" variant={'h6'}>
            {info.label}
          </Typography>
          {info.type === 'hyperText' ? (
            <Typography
              className="label"
              variant={'h4'}
              children={
                <span
                  dangerouslySetInnerHTML={{
                    __html: info.value,
                  }}
                  className="gam-care__contact-value"
                />
              }
            />
          ) : (
            <Typography className="label" variant={'h6'}>
              {info.value}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};
export default Table;
