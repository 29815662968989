import React, { FC } from 'react';
import { Logo } from 'src/components/base-components/Logo';
import { LogoGif } from 'src/components/base-components/LogoGif';
import { envBoolean } from 'src/helpers/utils';
import { CSLoader } from '../CsLoader';

const GeneralLoader: FC = () => {
  if (!envBoolean(process.env.REACT_APP_GENERAL_LOADER_IS_GIF as string)) {
    return (
      <div className="loader_container">
        <Logo />
        <CSLoader speed="slow" />
      </div>
    );
  }

  return (
    <div className="loader_container">
      <LogoGif />
    </div>
  );
};

export default GeneralLoader;
