import { FC, useEffect, useState } from 'react';
import { useI18n } from 'src/hooks/use-i18n-translation';
import '../styles.scss';

const StaticIframe: FC<any> = ({ iframeSrc, iframeId, iframeClasses }) => {
  const { locale } = useI18n();
  const iframe = (document?.getElementById(iframeId) as HTMLIFrameElement)?.contentWindow;

  const [iframeOnLoaded, setIframeOnLoaded] = useState<boolean>(false);

  const onLoadFn = (): void => {
    setIframeOnLoaded(true);
  };

  useEffect(() => {
    if (iframe && iframeOnLoaded && locale) {
      setTimeout(() => {
        iframe.postMessage({ type: 'languageChange', language: locale }, '*');
      }, 100);
    }
  }, [iframe, iframeOnLoaded, locale]);
  return (
    <iframe className={iframeClasses} id={iframeId} src={iframeSrc} onLoad={onLoadFn} allow="autoplay" key={iframeId} />
  );
};

export default StaticIframe;
