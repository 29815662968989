import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import './styles.scss';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  label?: boolean;
  required: boolean;
  tabIndex: number;
};

const BirthDate_Input: FC<IProps> = ({ label, required, name, onChange, tabIndex, value }) => {
  // Hooks
  const { t }: Translation = useTranslation();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // States
  const [datePickerValue, setDatePickerValue] = useState<dayjs.Dayjs | null>(null);

  // YUP Validation
  const validationSchema = Yup.object().shape({
    birthDate: Yup.date()
      .required(t('requiredField'))
      .test('isOver18', t('birthDate_input_error'), (value: any) => {
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        return maxDate > new Date(value);
      }),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ birthDate: value }, { abortEarly: false });
      resetSignUpError('birthDate');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, birthDate: error.errors[0] });
      }
    }
  };

  // Actions
  const onDateChange = (dateString: string): void => {
    if (!!dateString) {
      setDatePickerValue(dayjs(dateString));
      onChange({ target: { name: name, value: dateString } });
      handleValidate(dateString);
    }
  };

  const handleBlur = (): void => {
    handleValidate(value);
  };

  useEffect(() => {
    if (value) {
      setDatePickerValue(dayjs(value));
    }
  }, [value]);

  return (
    <div className="birthDate_input__wrapper">
      {label && (
        <Typography className="label" variant={'body5'}>
          {t(label)} {required && <span>*</span>}
        </Typography>
      )}
      <div className="rangepicker">
        <DatePicker
          className="picker"
          id="birthDatePicker"
          popupStyle={{
            zIndex: 99999999999,
          }}
          inputReadOnly
          onChange={(_, dateStr) => onDateChange(dateStr)}
          onBlur={handleBlur}
          value={datePickerValue}
          tabIndex={tabIndex}
        />
      </div>
      {signUpErrors.birthDate && (
        <Typography variant={'body5'} className="errorMessage" color="error">
          {signUpErrors.birthDate}
        </Typography>
      )}
    </div>
  );
};

export default BirthDate_Input;
