import cn from 'classnames';
import { FC, memo, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  children: ReactNode;
  data: string | ReactNode;
  position: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  showBurgerMenu?: boolean;
  setShowBurgerMenu?: (st: boolean) => void;
};

const Tooltip: FC<Props> = ({ children, data, position, className, showBurgerMenu, setShowBurgerMenu }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLElement | null>(null);

  const [show, setShow] = useState<boolean>(false);

  const handleTooltipClick = (): void => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    setShow(showBurgerMenu as boolean);
  }, [showBurgerMenu]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent): void => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setTimeout(() => {
        if (setShowBurgerMenu) {
          setShowBurgerMenu(false);
        }
      }, 100);
      setShow(false);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (show && !showBurgerMenu) {
      timer = setTimeout(() => {
        setShow(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [show, showBurgerMenu]);

  return (
    <div className={cn('tooltip_wrapper', className)}>
      {show && (
        <span ref={wrapperRef} className={cn('tooltip__overlay', { [`tooltip__overlay--${position}`]: !!position })}>
          {typeof data === 'string' ? t(data) : data}
        </span>
      )}
      <div onClick={handleTooltipClick} className="tooltip__inner">
        {children}
      </div>
    </div>
  );
};

export default memo(Tooltip);
