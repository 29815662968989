import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Loader } from 'src/components/common/Loader';
import { Typography } from 'src/components/common/Typography';
import { getStrapiMediaUrl, htmlParse, reachTextParser } from 'src/helpers/utils';
import { RootState } from 'src/types/store-types';
import _styles from './styles.module.scss';

const InfoAndRulesSection: FC = () => {
  const { t }: Translation = useTranslation();

  const ref = useRef<HTMLDivElement | null>(null);

  const { accountModal } = useSelector((state: RootState) => state);
  const { bottomNavigation } = useSelector((state: RootState) => state.configs);

  const [content, setContent] = useState<any>(null);

  useEffect(() => {
    if (accountModal.subSection) {
      const _content = bottomNavigation?.filter((f) => {
        return f.name === accountModal.subSection;
      })[0]?.content;
      setContent(_content);
      ref?.current?.scrollTo({ behavior: 'smooth', top: 0 });
    }
  }, [accountModal]);

  if (!Boolean(content)) {
    return (
      <div className={_styles.info_container}>
        <div className={_styles.loader_wrapper}>
          <Loader size="lg" />
        </div>
      </div>
    );
  }

  return (
    <div className={_styles.info_container} ref={ref}>
      {Boolean(content) ? (
        reachTextParser(htmlParse(content, getStrapiMediaUrl))
      ) : (
        <div className="no_data_container">
          <Typography variant={'h5'}>{t('no_data')}</Typography>
        </div>
      )}
    </div>
  );
};
export default InfoAndRulesSection;
