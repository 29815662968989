import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { getFooterConfigs } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { setGeneraLoading } from 'src/store/app/actions';
import { Constructor } from './constructor';
import './styles.scss';

const MobileFooterConstructor: FC = () => {
  const _setGeneraLoading = useConnectedAction(setGeneraLoading);

  const [configs, setConfigs] = useState<Settings>({
    template: '',
    sections: [],
    styles: {},
  });

  const sectionsArr = Object.values(configs?.sections as ISection[]);

  const footerComponent = useMemo(
    () => (
      <div className={'footer_wrapper'}>
        {sectionsArr?.map((obj: any, i: number) => {
          return (
            <div className="footer_row" key={obj + i}>
              {Constructor.renderWidgets(obj)}
            </div>
          );
        })}
      </div>
    ),
    [sectionsArr]
  );

  const getFooterConf = async (): Promise<void> => {
    try {
      if (PersisterService.hasPersistValue('mobileFooter')) {
        const res = PersisterService.getPersistValue('mobileFooter');
        setConfigs(res);
      } else {
        const res = await getFooterConfigs('mobile');

        if (res?.success) {
          _setGeneraLoading(true);
          setConfigs(res.result);
          PersisterService.setPersistValue('mobileFooter', res.result);
        } else {
          _setGeneraLoading(false);
        }
      }
    } catch (e) {
      _setGeneraLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getFooterConf();
  }, []);

  usePostMesssage((v: any) => setConfigs(v), 'mobileFooter');

  return (
    <div style={configs.styles} className={cn('mobile_footer_container', 'footer', configs?.template)}>
      {configs?.sections && footerComponent}
    </div>
  );
};
export default MobileFooterConstructor;
