import cn from 'classnames';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Checkbox } from 'src/components/common/Checkbox';
import { Input } from 'src/components/common/Input';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { useOutsideClick } from 'src/hooks/use_outside_click';
import { MediaManager } from 'src/manager/MediaManager';
import { RootState } from 'src/types/store-types';
import { SPECIAL_TAGS } from 'src/utils/constants';
import './styles.scss';

type ProviderListViewProps = {
  providers: any[];
  onProviderChange: any;
};
const ProviderDropdownView: FC<ProviderListViewProps> = (props) => {
  const { providers, onProviderChange } = props;

  const { t }: Translation = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  const { selectedTag, selectedProvider } = useSelector((state: RootState) => state.games);
  const { selectedCategory } = useSelector((state: RootState) => state.app);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedProvidersList, setSelectedProvidersList] = useState<string[]>(selectedProvider);
  const [providersList, setProvidersList] = useState<string[]>([]);
  const [searchVal, setSearchVal] = useState<string>('');
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const selectProvider = (provider: string): string[] => {
    const _ = selectedProvidersList.filter((provider) => provider !== 'all_providers');
    return [..._, provider];
  };

  const removeProvider = (provider: string): string[] => {
    const _ = selectedProvidersList.filter((f) => !['all_providers', provider].includes(f));
    return _.length ? _ : [];
  };

  const selectProviderList = (provider: string): void => {
    let _selectedProviders: string[] = [];

    if (selectedProvidersList.includes(provider)) {
      _selectedProviders = removeProvider(provider);
    } else {
      _selectedProviders = selectProvider(provider);
    }

    setSelectedProvidersList(_selectedProviders);
    onProviderChange(_selectedProviders);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    setSearchVal(value);
    setIsSubmited(Boolean(value));

    if (value.length >= 3) {
      const _selcetedProviders = (providers ?? [])?.filter((f) => f.toLowerCase().includes(value.toLocaleLowerCase()));
      setProvidersList(_selcetedProviders);
    } else {
      setProvidersList(providers);
    }
  };

  const onSearchInputValueRemove = (): void => {
    setSearchVal('');
    setProvidersList(providers);
  };

  const onSelecteProvidersReset = (): void => {
    setSelectedProvidersList(['all_providers']);
    setSearchVal('');
    setProvidersList(providers);
    onProviderChange([]);
  };

  const closeDropdown = (): void => {
    setSearchVal('');
    setProvidersList(providers);
    setIsActive(false);
  };

  useEffect(() => {
    if (!!providers) {
      // selectedTag === 'home' && setSelectedProvidersList([]);

      setProvidersList(providers);
    }
  }, [providers, selectedTag]);

  useEffect(() => {
    setIsDisabled(SPECIAL_TAGS.includes(selectedTag));

    if (selectedProvider && !!providers) {
      let _provider = [];

      if (typeof selectedProvider === 'string') {
        _provider = (selectedProvider as string).split(',');
      } else {
        _provider = selectedProvider.filter((f) => f !== '') ?? [];
      }
      if (selectedProvider.includes('all_providers')) {
        _provider = ['all_providers'];
      }
      if (_provider.length) {
        setSelectedProvidersList(_provider);
      }
    }
  }, [selectedProvider, selectedTag, providers]);

  useEffect(() => {
    if (selectedCategory) {
      // setSelectedProvidersList([]);
      setSearchVal('');
      setProvidersList(providers);
    }
    return () => {
      setSelectedProvidersList([]);
    };
  }, [selectedCategory]);

  useEffect(() => {
    isDisabled && setIsActive(!isDisabled);
  }, [isDisabled]);

  useEffect(() => {
    if (selectedProvidersList.length === 0) {
      setSelectedProvidersList(['all_providers']);
    }
  }, [selectedProvidersList]);

  useOutsideClick(ref, () => closeDropdown(), null, [
    'checkbox-dropdown-list',
    'provider_dropdown',
    'checkbox-dropdown-list_item',
    'search_wrapper',
    'search_input',
  ]);

  return (
    <div className="provider_dropdown_view" ref={ref}>
      <div className={cn('checkbox-dropdown', { ['is-active']: isActive })}>
        <div className="checkbox-dropdown-icon" onClick={() => !isDisabled && setIsActive((prev) => !prev)}>
          {selectedProvidersList.length > 0 ? (
            <div className="selected_providers_wrapper">
              {selectedProvider.length >= 1 && selectedProvider[0] !== 'all_providers' ? (
                <div className="selected_providers_title">
                  <Typography className="provider_title" variant="h6">
                    {t('selected')}
                  </Typography>
                  <div className="count_badge">{selectedProvidersList.length}</div>
                </div>
              ) : (
                <Typography className="provider_title" variant={'h5'}>
                  {t('providers')}
                </Typography>
              )}
              {!selectedProvidersList.includes('all_providers') && (
                <SvgIcon
                  src={icons.close}
                  className={'input_close_icon'}
                  onClick={() => !isDisabled && onSelecteProvidersReset()}
                />
              )}
            </div>
          ) : (
            <Typography className="provider_title" variant="h6">
              {t('providers')}
            </Typography>
          )}
        </div>
        <ul className="checkbox-dropdown-list">
          <li className="search_wrapper">
            <div className="search_icon">
              <SvgIcon src="/images/icons/svg/search.svg" />
            </div>
            <Input onChange={onChange} value={searchVal} name={''} placeholder={t('search')} />
            {searchVal.length ? (
              <SvgIcon src={icons.close} className="input_close_icon" onClick={onSearchInputValueRemove} />
            ) : null}
          </li>
          {providersList?.map((provider: string) => {
            return (
              <li key={provider} className="checkbox-dropdown-list_item" onClick={() => selectProviderList(provider)}>
                <Checkbox
                  onChange={() => selectProviderList(provider)}
                  value={selectedProvidersList.includes(provider)}
                  name={provider}
                  id={provider}
                  label={t(provider)}
                  defaultChecked={selectedProvidersList.includes(provider)}
                  className="checkbox_label"
                />
                {provider !== 'all_providers' && (
                  <div
                    className={`provider_image_wrapper
                `}
                  >
                    <SvgIcon
                      src={MediaManager.getSrcFromGeneralMediaAssets(
                        `${provider.toLowerCase()}.svg`,
                        `/providerIcons/BlackMode/`
                      )}
                      name={provider}
                    />
                  </div>
                )}
              </li>
            );
          })}
          {isSubmited && !providersList.length && (
            <div className="provider_search_no_result">
              <Typography className="provider_title" variant="h6">
                {t('no_results_found')}
              </Typography>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};
export default ProviderDropdownView;
