import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { MediaManager } from 'src/manager/MediaManager';
import '../styles.scss';

type ScrollableViewCardProps = {
  provider: string;
  onProviderChange: (p: string[]) => void;
};
const ScrollableViewCard: FC<ScrollableViewCardProps> = ({ provider, onProviderChange }) => {
  const { t }: Translation = useTranslation();
  const getSelectedProvider = localStorage.getItem('selectedProvider');
  const providerArray = getSelectedProvider?.split(',');
  return (
    <Button
      onClick={() => onProviderChange([provider])}
      className={cn('provider_card_wrapper', 'provider_button', 'tab-image-btn', {
        selected: Array.isArray(providerArray) && providerArray.includes(provider),
      })}
    >
      {provider === 'all_providers' ? (
        <>{t('all')}</>
      ) : (
        <SvgIcon
          src={MediaManager.getSrcFromGeneralMediaAssets(`${provider.toLowerCase()}.svg`, `/providerIcons/BlackMode/`)}
          className="providers_icons"
          name={provider}
        />
      )}
    </Button>
  );
};
export default ScrollableViewCard;
