import { FC, useEffect, useState } from 'react';

interface IProps {
  settings: {
    withoutMeasurement: boolean;
  };
}
const Time: FC<IProps> = ({ settings: withoutMeasurement }) => {
  const [time, setTime] = useState<any>(new Date());

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div>
      {withoutMeasurement ? (
        <span>
          {`${time.hours.toString().padStart(2, '0')}${withoutMeasurement ? ':' : 'h:'}`}
          {`${time.minutes.toString().padStart(2, '0')}${withoutMeasurement ? ':' : 'm:'}`}
          {`${time.seconds.toString().padStart(2, '0')}${withoutMeasurement ? '' : 's'}`}
        </span>
      ) : (
        time
      )}
    </div>
  );
};
export default Time;
