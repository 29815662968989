import cn from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import GameCard from 'src/components/common/GameCard';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { RootState } from 'src/types/store-types';
import { GAME_CARD_SIZES, SPECIAL_TAGS, TEMPLATES_WITH_DETAILS } from 'src/utils/constants';
import './styles.scss';

type GameCardPageProps = {
  tagGames: any;
  gamesTotalCount: number;
  changeVisibleGamesCount: (arg: boolean) => void;
  gamesMinVisibleCount: number;
};
const GameCardPage: FC<GameCardPageProps> = ({
  tagGames,
  changeVisibleGamesCount,
  gamesMinVisibleCount,
  gamesTotalCount,
}) => {
  const { t }: Translation = useTranslation();
  const gameContainerRef = useRef<HTMLDivElement>(null);

  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const { selectedTag } = useSelector((state: RootState) => state.games);
  const { sideBarMenuIsOpen } = useSelector((state: RootState) => state.app);

  const [gamesCountInARow, setGamesCountInARow] = useState<number>(0);

  const showDetails = useMemo(() => {
    return TEMPLATES_WITH_DETAILS.includes(generalConfigs?.template);
  }, []);

  useEffect(() => {
    !!gameContainerRef?.current?.clientWidth &&
      setGamesCountInARow(
        (gameContainerRef?.current?.clientWidth as number) / GAME_CARD_SIZES[isMobileOnly ? 'sm' : 'md_l']
      );
  }, [gameContainerRef?.current?.clientWidth, sideBarMenuIsOpen]);

  if (!tagGames?.length && tagGames !== null) {
    return (
      <div className="games_no_data_wrapper">
        <SvgIcon src={icons.noResult} />
        <Typography className="text" variant={'h4'}>
          {t('no_results_found')}
        </Typography>
      </div>
    );
  }

  return (
    <div className="games_wrapper">
      <div
        className={cn(
          'games_container',
          generalConfigs?.template ?? 'template_noFullScreen',
          showDetails ? 'has-details' : ''
        )}
        style={{
          gridTemplateColumns: isMobileOnly
            ? `repeat(auto-fill,minmax(28vw,1fr))`
            : `repeat(${Math.floor(gamesCountInARow)}, 1fr)`,
        }}
        ref={gameContainerRef}
      >
        {!!tagGames &&
          tagGames?.map((game: GameDataType, i: number) => {
            return (
              <GameCard
                key={game.name + i.toString()}
                data={game}
                showDetails={showDetails}
                effects={{
                  hoverType: 'fullWidthImage',
                  src: '',
                  text: '',
                }}
                useGif={generalConfigs?.useGifAsGameLoader}
                size={isMobileOnly ? 'sm' : 'md_l'}
              />
            );
          })}
      </div>
      {!SPECIAL_TAGS.includes(selectedTag) &&
        gamesTotalCount > tagGames?.length &&
        // TO DO fix this logic
        tagGames?.length >= gamesMinVisibleCount && (
          <Button className="show_more_btn" onClick={() => changeVisibleGamesCount(true)}>
            {t('button_show_more')}
          </Button>
        )}
      {gamesTotalCount === tagGames?.length && tagGames?.length > gamesMinVisibleCount && (
        <Button className="show_more_btn" onClick={() => changeVisibleGamesCount(false)}>
          {t('button_show_less')}
        </Button>
      )}
    </div>
  );
};

export default GameCardPage;
