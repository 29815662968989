import { createAction } from 'deox';
import { GET_MESSAGES_SUCCESS, READ_MESSAGE_REQUEST, READ_MESSAGE_SUCCESS } from './types';

export const getMessagesSuccess = createAction(
  GET_MESSAGES_SUCCESS,
  (resolve) => (arrMessages: any[]) => resolve(arrMessages)
);

export const readMessageRequest = createAction(
  READ_MESSAGE_REQUEST,
  (resolve) => (messageId: string | number) => resolve(messageId)
);

export const readMessageSuccess = createAction(
  READ_MESSAGE_SUCCESS,
  (resolve) => (messageId: string | number) => resolve(messageId)
);
