import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Typography } from 'src/components/common/Typography';
import { replaceImgPath } from 'src/helpers/transformers';
import './styles.scss';

type Props = {
  cardInfo: any;
  onBtnClick: (bonus: Bonus) => void;
};

const CardWithInfoPage: FC<Props> = ({ cardInfo, onBtnClick }) => {
  const { t }: Translation = useTranslation();

  const promoUrl = useMemo(() => {
    if (!!cardInfo?.thumbnail) {
      return cardInfo.thumbnail;
    }

    return <></>;
  }, [cardInfo]);

  const convertIamgeUrl = useCallback(
    (thumbnail: string, width: string): string => {
      if (process.env.REACT_APP_CONVERT_IMAGE_PATH_WITH_IMIGIX) {
        const convertConfigs = JSON.parse(process.env.REACT_APP_CONVERT_IMAGE_PATH_WITH_IMIGIX as any);
        if (convertConfigs?.promoItem) {
          return replaceImgPath(thumbnail, width);
        }
      }
      return thumbnail;
    },
    [cardInfo]
  );

  return (
    <div className="promotions__bonus-item">
      <div className="promotions__bonus-item-image-wrapper">
        {promoUrl && <img src={convertIamgeUrl(promoUrl, '390')} alt="Promotion" />}
      </div>
      <div className="promotions__item-title-description-button-wrapper">
        {(!!cardInfo?.title || !!cardInfo?.shortDescription) && (
          <div className="text_content_wrapper">
            {!!cardInfo?.title && (
              <Typography
                variant={'h6'}
                children={
                  <span className="promotions__item-title" dangerouslySetInnerHTML={{ __html: cardInfo?.title }} />
                }
              />
            )}
            {!!cardInfo?.shortDescription && (
              <Typography
                variant={'h6'}
                children={
                  <span
                    className="promotions__item-description"
                    dangerouslySetInnerHTML={{ __html: cardInfo?.shortDescription }}
                  />
                }
              />
            )}
          </div>
        )}
        <Button
          onClick={() => onBtnClick({ id: cardInfo?.id, attributes: cardInfo })}
          className="promotions__button promotions__button--item  success-filled-btn"
        >
          {t('readMore')}
        </Button>
      </div>
    </div>
  );
};

export default CardWithInfoPage;
