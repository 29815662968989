import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
  };
};
const OperatorBlock: FC<Props> = ({ settings }) => {
  const { styles } = settings;

  const { customI18n } = useSelector((state: RootState) => state.app);

  return (
    <Typography className="textBlock_wrapper" variant={'h6'} style={styles}>
      <p>{customI18n?.businessActivityInfo || 'BUSINESS_ACTIVITY_INFO'}</p>
    </Typography>
  );
};
export default OperatorBlock;
