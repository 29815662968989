import cn from 'classnames';
import { FC, Fragment } from 'react';
import { Typography } from 'src/components/common/Typography';
import '../styles.scss';
import ArticleTitle from './ArticleTitle';

type Props = {
  t: Translation;
  article: any;
  expandedArticle: any;
  setExpandedArticle: (article: any) => void;
  selectedArticle: any;
  setSelectedArticle: (article: any) => void;
  selectedSubSection: any;
};
const ArticleDropdown: FC<Props> = ({
  t,
  article,
  expandedArticle,
  setExpandedArticle,
  selectedSubSection,
  setSelectedArticle,
}) => {
  return (
    <div
      className={cn('help-center__article', {
        'help-center__article--expanded': article === expandedArticle,
      })}
    >
      <div
        className="help-center__article__header"
        onClick={() => setExpandedArticle(article === expandedArticle ? -1 : article)}
      >
        <Typography variant={'h5'}>{t(`helpCenter.${article?.category.toLowerCase()}`)}</Typography>
        <i className={`icon-angle-down ${article === expandedArticle ? 'angle-up' : 'angle-down'}`}></i>
      </div>
      {article === expandedArticle && (
        <div className="help-center__article__content">
          {article.subCategories.map((item: any) => (
            <Fragment key={item}>
              <Typography variant={'h5'} className="help-center__article__title">
                {Object.keys(item)[0]}
              </Typography>
              {item[Object.keys(item)[0]].map((el: any) => (
                <div key={el.title} onClick={() => setSelectedArticle(el)}>
                  <ArticleTitle
                    iconClass="arrow-right"
                    selected={[selectedSubSection?.subsection, selectedSubSection?.data?.title].includes(el.title)}
                    article={el}
                  />
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
export default ArticleDropdown;
