import cn from 'classnames';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFromQueryParams } from 'src/helpers/utils';
import { loginFormValidation, loginWithOptionFormValidation } from 'src/helpers/validationScheme';
import { setSelectedCategory } from 'src/store/app/actions';
import { makeSignInPayload } from '../../../../../../helpers/transformers';
import { useConnectedAction } from '../../../../../../hooks/use-connected-action';
import { setError, signIn } from '../../../../../../store/user/actions';
import { ISignInPayload, SignInPayload } from '../../../../../../types/req-dto/authorization';
import { ISignInRes } from '../../../../../../types/res-dto/authorization';
import { RootState } from '../../../../../../types/store-types';
import { Button } from '../../../../../common/Button';
import { Checkbox } from '../../../../../common/Checkbox';
import { Input } from '../../../../../common/Input';
import RadioInput from '../../../../../common/RadioInput';
import { Typography } from '../../../../../common/Typography';
import styles from './styles.module.scss';

interface IProps extends ISignInRes {
  close: () => void;
  setContent: (action: 'open' | 'close', content: 'login' | 'registration' | 'forgot' | null) => void;
}

interface IForm extends ISignInPayload {
  rememberMe: boolean;
}
const LoginContent: FC<IProps> = ({
  close,
  username,
  password,
  email,
  cpf,
  radio,
  rememberMe,
  forgotLink,
  signUp,
  setContent,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t }: Translation = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = getFromQueryParams(search);

  const { errors: signInErrors, authLoading } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const _signInReq = useConnectedAction(signIn.req);
  const _setErrorReq = useConnectedAction(setError);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const [loginOption, setLoginOption] = useState<string>('E-Mail');

  const successCb = (values: IForm): void => {
    if (values.rememberMe) {
      localStorage.setItem(
        'userCredentials',
        JSON.stringify({
          username: values.username,
          password: values.password,
          email: values.email,
          cpf: values.cpf,
          rememberMe: true,
        })
      );
    } else if (localStorage.getItem('userCredentials')) {
      localStorage.removeItem('userCredentials');
    }

    setContent('close', 'login');
    if (generalConfigs?.redirectToSport) {
      navigate('/category/betting');
      _setSelectedCategory('betting');
    }
    close();
  };

  const initialValues = {
    username: '',
    email: '',
    cpf: undefined,
    password: '',
    rememberMe: Boolean(localStorage.getItem('userCredentials')),
  };

  if (Boolean(localStorage.getItem('userCredentials'))) {
    const userCredentials = JSON.parse(localStorage.getItem('userCredentials') || '');
    initialValues.username = userCredentials.username || '';
    initialValues.email = userCredentials.email || '';
    initialValues.cpf = userCredentials.cpf || '';
    initialValues.password = userCredentials.password || '';
  }

  const validationSchema = Boolean(cpf) && Boolean(email) ? loginWithOptionFormValidation(t) : loginFormValidation(t);

  const formik = useFormik<IForm>({
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues,
    onSubmit: (values) => {
      const data: SignInPayload = makeSignInPayload({ ...values, authClientAppId: params.authClientAppId });

      _signInReq({
        data,
        onSuccessCb: () => {
          successCb(values);
        },
        dispatch,
      });
    },
  });

  const handleChange = (): void => {
    setFieldValue('rememberMe', !values.rememberMe);
  };

  const changeContent = (content: 'login' | 'registration' | 'forgot') => () => {
    setContent('open', content);
  };

  const changeInput = (e: { target: { value: string } }): void => {
    setFieldValue('email', '');
    setFieldValue('cpf', '');
    setLoginOption(e.target.value);
  };

  useEffect(() => {
    _setErrorReq({});
  }, []);

  const { values, getFieldProps, setFieldValue, handleSubmit, errors } = formik;

  return (
    <form className={styles.loginModal__content} onSubmit={handleSubmit}>
      {Boolean(radio) && (
        <>
          <span className={styles.login_text}>{t('chooseType')}</span>
          <RadioInput data={['E-Mail', 'CPF']} onChange={changeInput} required value={loginOption} />
        </>
      )}
      {Boolean(email) && loginOption === 'E-Mail' && (
        <Input
          {...getFieldProps('email')}
          error={email?.errorMessage && errors.email}
          label={email?.label ? t('email') : ''}
          value={values.email?.trim() as string}
        />
      )}
      {Boolean(cpf) && loginOption === 'CPF' && (
        <Input
          type={'number'}
          {...getFieldProps('cpf')}
          error={cpf?.errorMessage && errors.cpf}
          label={cpf?.label ? t('cpf') : ''}
          value={values.cpf as number}
        />
      )}
      {Boolean(username) && (
        <Input
          {...getFieldProps('username')}
          value={values.username?.trim() as string}
          label={username?.label ? t('user_name') : ''}
          error={username?.errorMessage && errors.username}
          inputHeight={40}
        />
      )}
      {Boolean(password) && (
        <Input
          {...getFieldProps('password')}
          type="password"
          value={values.password?.trim()}
          label={password?.label ? t('password') : ''}
          error={password?.errorMessage && errors.password}
          inputHeight={40}
          iconPosition="right"
          height="md"
        />
      )}
      {Boolean(rememberMe) && (
        <Checkbox
          name="rememberMe"
          value={values.rememberMe}
          onChange={handleChange}
          id="login_checkbox"
          label={rememberMe ? t('rememberMe') : ''}
        />
      )}
      {signInErrors.error && (
        <Typography variant={'body3'} color="error" className={styles.signin_error}>
          {t(signInErrors.error)}
        </Typography>
      )}
      <Button
        className={cn(styles.loginButton, 'success-filled-btn')}
        variant={'text'}
        onClick={handleSubmit}
        type={'submit'}
        loading={authLoading}
      >
        {t('button_signIn')}
      </Button>
      {forgotLink && (
        <div className={styles.forgotPass} onClick={changeContent('forgot')}>
          <Typography>{t('forgotPassword')}</Typography>
        </div>
      )}
      {!process.env.REACT_APP_HIDE_REG && signUp && (
        <Button className={styles.signUpButton} variant={'text'} onClick={changeContent('registration')}>
          <span>{t('no_account')}</span> {t('registartion')}
        </Button>
      )}
    </form>
  );
};

export default LoginContent;
