import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { detectDevice, setBodyOverflow } from 'src/helpers/utils';
import { gameListRequest } from 'src/services/gameApi';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import {
  setGamesTotalCount,
  setSelectedGame,
  setSelectedProvider,
  setSelectedTag,
  setTags,
} from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import { EGT_GAME_MAP } from 'src/utils/constants';
import { useConnectedAction } from './use-connected-action';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useBannerActions = (): any => {
  const device = detectDevice();
  const navigate = useNavigate();

  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setTags = useConnectedAction(setTags);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);
  const _setGamesTotalCount = useConnectedAction(setGamesTotalCount);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setSelectedGame = useConnectedAction(setSelectedGame);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { user } = useSelector((state: RootState) => state.user);
  const { selectedTag } = useSelector((state: RootState) => state.games);
  const { generalConfigs, navigation } = useSelector((state: RootState) => state.configs);

  const gamesCount =
    device === 'mobile' ? generalConfigs?.gamesCountPerPageMobile : generalConfigs?.gamesCountPerPageDesktop;

  const getGameList = (provider: string, offset: number, tagList: any[]): void => {
    gameListRequest(device, [provider], offset, selectedCategory, [selectedTag], gamesCount).then((res: any) => {
      const newGames = res.games.map((game: any) => {
        if (game.providerName === 'EGT Digital') {
          return {
            ...game,
            gameKey: EGT_GAME_MAP[game.launchId],
          };
        } else {
          return game;
        }
      });
      _setGamesTotalCount(res.totalCount);
      _setTags(provider === 'all_providers' ? tagList : newGames);
    });
  };

  const onCallProviderAction = (params: any): void => {
    const { provider, tags, tagList } = params;

    _setSelectedProvider(provider);
    selectedTag === 'home' && _setSelectedTag('allGames');
    const offset = Math.floor(tags.length / 24) <= 1 ? 0 : Math.floor(tags.length / 24);
    getGameList(provider, offset, tagList);
  };

  const onCallLoginAction = (): void => {
    if (!user) {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }
  };

  const onCallRegistrAction = (): void => {
    !user && _openAuthModal('registration');
  };

  const onCallPromotionAction = (promotinId: string | number): void => {
    _setSelectedCategory('');
    navigate(`/promotions?promo=${promotinId}`);
  };

  const onCallExternalUrlAction = (url: string): void => {
    window.open(url, '_blank', 'noreferrer');
  };

  const onCallGame = (gameId: string): void => {
    if (user) {
      _setSelectedGame({ id: gameId, gameWalletId: null });
    } else {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }
  };

  const onCallCategoryAction = (category: string): void => {
    const selectedCategory = category === 'sport' ? 'betting' : category;
    const routeExisting = navigation.filter((f) => f.name === selectedCategory).length > 0;

    if (routeExisting) {
      if (category === 'sport') {
        _setSelectedCategory('betting');
      } else {
        _setSelectedCategory(category);
      }
      if (selectedCategory === 'poker') {
        navigate(`/content/${selectedCategory}`);
      } else {
        navigate(`/category/${selectedCategory}`);
      }
    } else {
      console.error('Category doesn"t exist');
    }
  };

  return {
    providerAction: (params: any) => onCallProviderAction(params),
    loginAction: () => onCallLoginAction(),
    registrAction: () => onCallRegistrAction(),
    promotionAction: (promotionId: string | number) => onCallPromotionAction(promotionId),
    externalUrlAction: (url: string) => onCallExternalUrlAction(url),
    gameAction: (gameId: string) => onCallGame(gameId),
    categoryAction: (category: string) => onCallCategoryAction(category),
  };
};
