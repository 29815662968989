import React, { FC } from 'react';
import { Button } from 'src/components/common/Button';

type Props = {
  data: any;
  handleTitleClick: (data: any) => void;
};
const HelpCenterSearchResultItem: FC<Props> = ({ data, handleTitleClick }) => {
  return (
    <div className="help-center__search-result__row">
      <Button className="title" onClick={() => handleTitleClick(data)}>
        {data.title}
      </Button>
      <div dangerouslySetInnerHTML={{ __html: data.content }} className="content" />
    </div>
  );
};
export default HelpCenterSearchResultItem;
