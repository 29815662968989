import React from 'react';
import Input from './components/paymentMethods/Input';
import RadioInput from './components/paymentMethods/RadioGroup';
import Select from './components/paymentMethods/Select';

export class Constructor {
  public static PaymentComponents = {
    string: Input,
    number: Input,
    radiogroup: RadioInput,
    dropdown: Select,
    array: Select,
    requestList: Select,
    date: Input,
  };

  public static renderInputs = (
    payment: PaymentMethod,
    handleFieldChange: (value: any, field: string) => void,
    paymentError: { [keys: string]: string } | null,
    setPaymentError: (error: any) => void,
    paymentType: string,
    paymentFields: any
  ): (null | React.JSX.Element)[] => {
    const keys = payment?.fields ? Object.keys(payment?.fields) : [];
    return keys.map((key, i) => {
      const field = payment?.fields[key];
      const Component = (Constructor as any).PaymentComponents[field?.type];
      const isNumeric = payment?.fields[keys[i]]?.type === 'number';
      const _fieldKey = typeof field === 'object' && field ? Object.keys(field)[0] : field;

      if (!Component) return null;

      return (
        <Component
          data={{
            payment: payment,
            paymentType: paymentType,
            onchange: handleFieldChange,
            paymentField: key,
            error: paymentError,
            setError: setPaymentError,
            isNumeric,
            value: paymentFields?.amount,
          }}
          key={`${_fieldKey}_${i}`}
        />
      );
    });
  };

  public static renderAdditionalInputs = (
    fields: { [key: string]: any }[],
    handleFieldChange: (value: any, field: string) => void,
    paymentError: { [keys: string]: string } | null,
    setPaymentError: (error: any) => void,
    selectedPaymentId: number,
    paymentFields: Record<string, string>
  ): (null | React.JSX.Element)[] => {
    const keys = fields.length > 0 ? Object.keys(fields[0]) : [];

    return fields.map((field, i) => {
      const Component = (Constructor as any).PaymentComponents[field[keys[i]]?.type];
      const isNumeric = field[keys[i]]?.type === 'number';
      const _fieldKey = typeof field === 'object' && field ? Object.keys(field)[0] : field;

      if (!Component) return null;

      return (
        <Component
          data={{
            payment: { fields: fields[0] },
            onchange: handleFieldChange,
            paymentField: keys[i],
            error: paymentError,
            setError: setPaymentError,
            paymentFields: paymentFields,
            isNumeric,
          }}
          key={`${_fieldKey}_${i}_${selectedPaymentId}`}
        />
      );
    });
  };
}
