/* eslint-disable max-len */
import React, { FC, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Components } from 'src/configs/components/components';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setEgtGamesStats } from 'src/store/games/actions';
import '../styles.scss';

type Props = {
  settings: {
    firstSection: { [key: string]: any };
    secondSection: { [key: string]: any };
    thirdSection: { [key: string]: any };
  };
};

const apiHost = 'https://timelesstecheu-api-prod-bgsp.egtd-hr.com';
const CURRENCY = process.env.REACT_APP_CURRENCY;

const CategoryPageConstructor: FC<Props> = ({ settings }) => {
  const _setEgtGamesStats = useConnectedAction(setEgtGamesStats);

  const getStats = async (): Promise<void> => {
    await fetch(`${apiHost}/api/jackpot/stats`)
      .then((res) => res.json())
      .then((res) => _setEgtGamesStats({ data: res, currency: CURRENCY }));
  };

  useEffect(() => {
    getStats();
  }, []);

  const renderCategoryPageWidgets = (line: any): JSX.Element => {
    const orderIndex: any = {};

    Object.keys(line).forEach((key: string) => (orderIndex[`${key}`] = line[key].priority));

    const widgetLine = Object.keys(line)
      .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
      .filter((key: string) => key !== 'styles')
      .map((key: string, index: number) => {
        return React.createElement(Components[key], {
          settings: line[key],
          key: `${key}_${index}`,
        });
      });

    return <>{widgetLine}</>;
  };

  return (
    <div className={'page_container'}>
      {process.env.REACT_APP_WEBSITENAME === 'PapiGames' && (
        <HelmetProvider>
          <Helmet>
            <title>Jogue Slots e Ganhe Grandes Prêmios Diários | Até $22.000</title>
            <meta
              name="description"
              content="Jogue seus jogos de slot favoritos como Fortune Tiger, Gates of Olympus, Sweet Bonanza e mais para ter a chance de ganhar grandes prêmios todos os dias. Inscreva-se agora e comece a ganhar"
            />
            <meta
              name="keywords"
              content="Jogue seus jogos de slot favoritos como Fortune Tiger, Gates of Olympus, Sweet Bonanza e mais para ter a chance de ganhar grandes prêmios todos os dias. Inscreva-se agora e comece a ganhar"
            />
          </Helmet>
        </HelmetProvider>
      )}
      <div className={'page_container_first_section'}>{renderCategoryPageWidgets(settings?.firstSection)}</div>
      <div className={'page_container_second_section'} style={settings?.secondSection.styles}>
        {renderCategoryPageWidgets(settings?.secondSection)}
      </div>
      <div className={'page_container_third_section'}>{renderCategoryPageWidgets(settings?.thirdSection)}</div>
    </div>
  );
};
export default CategoryPageConstructor;
