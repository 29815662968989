import { Pagination } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { formatCurrency } from 'src/helpers/utils';
import { RAF_LIMIT } from 'src/utils/constants';
import _styles from '../../styles.module.scss';
import './../../styles.scss';

type Props = {
  friendsList: FriendsList | null;
  setShowFriendsList: (show: boolean) => void;
  setOffset: any;
};
const ReferredFriendsList: FC<Props> = ({ friendsList, setShowFriendsList, setOffset }) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const handlePaginate = (p: any): void => {
    setPage(p);
    setOffset((p - 1) * RAF_LIMIT);
  };

  const setEmptyList = (): JSX.Element => {
    return (
      <>
        <div className={_styles.not_existing_friend__name}>
          <SvgIcon src="/images/refer_a_friend/notExistingUser.svg" />
        </div>
        <div className={_styles.not_existing_friend__info}>
          <span>{t('refer_a_friend.not_invited_yet')}</span>
        </div>
      </>
    );
  };

  const setReferredFriendList = (friend: Friend): JSX.Element => {
    return (
      <>
        <div className={_styles.existing_friend__name}>
          <Typography variant={'h6'}>{friend.username}</Typography>
        </div>
        <div className={_styles.existing_friend__info}>
          <div className={_styles.level_info}>
            <div className={_styles.bottomLine}>
              <div className={_styles.dot}></div>
              <span>
                {t('refer_a_friend.level')}
                <span>
                  {friend.currentMission.level} {t('refer_a_friend.of')} {friend.currentMission.totalLevels}
                </span>
              </span>
            </div>
            <div className={_styles.topLine}>
              <p>
                {friend.currentMission.progress}
                <span>/</span> {friend.currentMission.goal}
              </p>
              <div className={_styles.progress}>
                <div
                  className={_styles.passed}
                  style={{ width: `${(friend.currentMission.progress * 100) / friend.currentMission.goal}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className={_styles.prize}>
            {isMobileOnly && t('refer_a_friend.prize')}
            <span>
              {formatCurrency(friend?.currentMission?.prize?.amount, friend?.currentMission?.prize?.currency)}
            </span>
          </div>
        </div>
      </>
    );
  };

  const emptyListItem = useMemo(() => {
    const notExisting = [];
    let i = 0;

    if (friendsList) {
      while (i < 10 - friendsList?.friendsCount) {
        notExisting.push(i);
        i++;
      }
    }

    return notExisting;
  }, [friendsList]);

  const closeFriendsList = (): void => {
    setShowFriendsList(false);
  };

  useEffect(() => {
    return () => {
      setPage(1);
      setOffset(0);
    };
  }, []);

  return (
    <div className={_styles.referredFriendsList_modal}>
      <div className={_styles.header}>
        <div className={_styles.title_wrapper}>
          <SvgIcon src="/images/refer_a_friend/friend.svg" />
          <div className={_styles.title_inner_wrapper}>
            <Typography variant={'h4'} className={_styles.title}>
              {t('refer_a_friend.friends_list')}
            </Typography>
            <span className={_styles.text}>
              {t(`refer_a_friend.${isMobileOnly ? 'mobile_friends_list_text' : 'friends_list_text'}`)}
            </span>
          </div>
        </div>
        <div className={_styles.close}>
          <SvgIcon src={icons.close} onClick={closeFriendsList} />
        </div>
      </div>
      <div className={_styles.content}>
        <div className={_styles.caption}>
          {['username', 'level', 'prize'].map((caption: string) => {
            return <span className={_styles.caption_col}>{t(`refer_a_friend.${caption}`)}</span>;
          })}
        </div>
        <div className={_styles.friendList}>
          {friendsList &&
            friendsList.friendsList?.map((friend: Friend) => {
              return (
                <div className={_styles.friend} key={friend.accountId}>
                  {setReferredFriendList(friend)}
                </div>
              );
            })}
          {emptyListItem.map((i: number) => {
            return (
              <div className={_styles.friend} key={Symbol(i).toString()}>
                {setEmptyList()}
              </div>
            );
          })}
        </div>
        {friendsList?.friendsCount && friendsList?.friendsCount > RAF_LIMIT && (
          <div className="entries_table_pagination">
            <Pagination
              defaultCurrent={1}
              pageSize={RAF_LIMIT}
              total={friendsList?.friendsCount}
              showSizeChanger={false}
              current={page}
              onChange={handlePaginate}
              className="pagination"
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ReferredFriendsList;
