import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'src/components/common/Alert';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';

type Props = {
  transactionNotification: any;
  closeNotification: () => void;
};
const SuccessMessage: FC<Props> = ({ transactionNotification, closeNotification }) => {
  const { t } = useTranslation();
  const messageText = useMemo(() => {
    if (transactionNotification?.status) {
      return t(transactionNotification?.message);
    }
    return t(transactionNotification?.message.key || 'unhandledError');
  }, [transactionNotification]);

  return (
    <Alert className={`notif ${transactionNotification?.status ? 'success' : 'error'}_notification_class`}>
      <SvgIcon
        src="/icons/svg/circle-exclamation-solid.svg"
        className="notification"
        color={`${transactionNotification?.status ? 'green' : 'red'}`}
      />
      <Typography component="p">{messageText}</Typography>
      <div className="btn-wrapper">
        <Button className="secondary-btn" onClick={closeNotification}>
          {t('ok')}
        </Button>
      </div>
    </Alert>
  );
};
export default SuccessMessage;
