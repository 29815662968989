import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import _styles from './styles.module.scss';

type Props = {
  data: {
    payment: PaymentMethod;
    paymentField: any;
    onchange: (ev: any, field: string) => void;
    error: { [keys: string]: string } | null;
    setError: (error: any) => void;
  };
};
const RadioInput: FC<Props> = ({ data }) => {
  const { payment, onchange, paymentField, error, setError } = data;
  const { t } = useTranslation();
  const handleInputChange = (field: string) => () => {
    const errors = { ...error };
    delete errors[paymentField];

    setError(errors);
    onchange(field, paymentField);
  };

  useEffect(() => {
    payment.fields[paymentField].list.length > 0 && onchange(payment.fields[paymentField].list[0], paymentField);
  }, [payment, paymentField]);

  return (
    <form className={_styles.radio_group_container}>
      <label>{t(paymentField)}</label>
      {payment.fields[paymentField].list?.map((field: string) => {
        return (
          <div className={_styles.radio_input_container} key={field}>
            <input type="radio" name={paymentField} id={paymentField} onChange={handleInputChange(field)} />
            <label htmlFor={paymentField}>{t(field) ?? field}</label>
            {error && <ErrorMessage error={error} paymentField={paymentField} />}
          </div>
        );
      })}
    </form>
  );
};
export default RadioInput;
