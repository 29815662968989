import cn from 'classnames';
import { FC, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { icons } from 'src/configs/icons';
import { Button } from '../../Button';
import { SvgIcon } from '../../SvgIcon';
import ReactPortal from './ReactPortal';
import './style.scss';

type ModalProps = {
  isOpen: boolean;
  hasOverlay?: boolean;
  handleClose?: () => void;
  handleOK?: () => void;
  handleCancel?: () => void;
  title?: string;
  description?: string;
  disabled?: boolean;
  children?: any;
  childerHasImage?: string;
  fullSize?: boolean;
  type?: 'default' | 'fullSize' | 'withImage' | 'small';
  icon?: any;
  id?: number | string;
  confirmText?: string;
  zIndex?: number;
  wrapperId?: number | string;
  className?: string;
  hideFooter?: boolean;
};
const PopUpContainer: FC<ModalProps> = ({
  isOpen,
  handleClose,
  handleOK,
  handleCancel,
  title = '',
  disabled,
  children = <></>,
  hasOverlay = false,
  childerHasImage = '',
  description = '',
  type = 'default',
  icon,
  id = title,
  confirmText = 'ok',
  wrapperId = id || title,
  zIndex = 150,
  className = '',
  hideFooter = false,
}) => {
  const { t }: Translation = useTranslation();

  const header = useMemo(() => {
    if (title) {
      return (
        <div
          className={cn('popup_heading_with_title', {
            ['withImage']: childerHasImage === 'withImage',
            ['withoutImage']: childerHasImage === 'withoutImage',
          })}
        >
          <h3 className={'popup_title'}>{title}</h3>
          {handleClose && (
            <div className={'popup_close'}>
              <SvgIcon src={icons.close} onClick={handleClose} />
            </div>
          )}
        </div>
      );
    }
    return (
      <div
        className={cn('popup_heading_without_title', {
          ['withImage']: childerHasImage === 'withImage',
          ['withoutImage']: childerHasImage === 'withoutImage',
        })}
      >
        {icon && <div className="popup_icon">{icon}</div>}
        {handleClose && (
          <div className={'popup_close'}>
            <SvgIcon src={icons.close} onClick={handleClose} />
          </div>
        )}
      </div>
    );
  }, [childerHasImage]);

  if (isOpen) {
    const resolvedWrapperId = typeof wrapperId === 'number' ? wrapperId.toString() : wrapperId;

    return (
      <ReactPortal wrapperId={resolvedWrapperId}>
        <div className="popup_container" style={{ zIndex: zIndex }}>
          <div className={cn('popup', type, className)}>
            {header}
            <div className="popup_body">
              <p className="popup_description" dangerouslySetInnerHTML={{ __html: description }} />
              {children}
            </div>
            {!hideFooter && (handleOK || handleCancel) && (
              <div className="popup_footer">
                {handleOK && (
                  <Button className={'popup-btn success-filled-btn'} onClick={handleOK} disabled={disabled}>
                    {t(confirmText)}
                  </Button>
                )}
                {handleCancel && (
                  <Button className="popup-btn secondary-btn" onClick={handleCancel} disabled={disabled}>
                    {t('cancel')}
                  </Button>
                )}
              </div>
            )}
          </div>
          {(hasOverlay || isMobileOnly) && <div className="popup_container_backdrop" />}
        </div>
      </ReactPortal>
    );
  }
  return <></>;
};
export default PopUpContainer;
