import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import './styles.scss';

interface IProps {
  data?: any;
}
const OpenNewTab: FC<IProps> = ({ data }) => {
  const { t }: Translation = useTranslation();

  setBodyOverflow('unset');

  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.DINHU_ANALIZER });
  };
  const onDialogOk = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.DINHU_ANALIZER });
    window.open(data);
  };

  return (
    <PopUpContainer
      isOpen
      handleClose={removeDialog}
      handleOK={onDialogOk}
      hasOverlay={false}
      title={t('redirectToAnalyzer')}
      className="game-url-failure-popup"
    ></PopUpContainer>
  );
};

export default OpenNewTab;
