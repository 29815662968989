import { FC } from 'react';
import { MediaManager } from 'src/manager/MediaManager';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
    data: { src: string }[];
  };
};
const ImageWidgetLiga: FC<Props> = ({ settings }) => {
  const { styles, data } = settings;

  return (
    <div className="awards_container_liga" style={styles}>
      {data?.map((d) => {
        return <img key={d.src} src={MediaManager.getSrcFromMediaAssets(d.src, '/footerIcons/')} alt={d.src} />;
      })}
    </div>
  );
};
export default ImageWidgetLiga;
