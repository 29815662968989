import { FirstDepositToWallet } from 'src/types/res-dto/first_deposit_to_wallet';
import { IUserBalance, LoginHistory } from 'src/types/store-types';
import { APP_API_VERSION } from 'src/utils/constants';
import HttpClient from './http';

const balanceRequest = (): Promise<IUserBalance> => {
  return HttpClient.get({
    path: `${APP_API_VERSION}/getBalance`,
    baseURL: process.env.REACT_APP_REMOTE as string,
  });
};

const firstDepositToBonusRequest = (params: any): Promise<FirstDepositToWallet> => {
  return HttpClient.post(
    {
      path: `${APP_API_VERSION}/firstDepositToBonusWallet`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    params
  );
};

const loginHistoryRequest = (params: Record<string, any>): Promise<LoginHistory> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/getLoginHistory`, baseURL: process.env.REACT_APP_REMOTE as string },
    true,
    params
  );
};

const verifyEmail = (payload: { key: string }): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/confirmEmail`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const transactionRequest = (params: any, subSection: string): Promise<any> => {
  if (subSection === 'casino_history') {
    params.groupByGame = true;
  }

  return HttpClient.get(
    { path: `${APP_API_VERSION}/bc/getMovements`, baseURL: process.env.REACT_APP_REMOTE as string },
    true,
    params
  );
};

const changePasswordRequest = (params: Record<string, any>): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/bc/changePassword`, baseURL: process.env.REACT_APP_REMOTE as string },
    params
  );
};

const checkOldPasswordRequest = (params: Record<string, any>): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/authenticatePlayer`, baseURL: process.env.REACT_APP_REMOTE as string },
    params
  );
};

const paymentMethodsRequest = (params: any): Promise<PaymentMethod[]> => {
  return HttpClient.get(
    {
      path: `${APP_API_VERSION}/getAccountPaymentMethodsV2`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    true,
    params
  );
};

const bankRequest = (url: string, methodId: string): Promise<any> => {
  return HttpClient.get({ path: url, baseURL: process.env.REACT_APP_REMOTE as string }, true, { methodId: methodId });
};

const paymentTransactionRequest = (data: any): Promise<any> => {
  return HttpClient.post(
    { path: `/${data.method}/${data.transactionType.toLowerCase()}`, baseURL: process.env.REACT_APP_REMOTE as string },
    data
  );
};

const getBankListRequest = (payment: any): Promise<any> => {
  return HttpClient.get(
    { path: `/${payment.method}/getBankList`, baseURL: process.env.REACT_APP_REMOTE as string },
    true,
    {
      methodId: payment.methodId,
    }
  );
};

export {
  firstDepositToBonusRequest,
  balanceRequest,
  loginHistoryRequest,
  verifyEmail,
  transactionRequest,
  changePasswordRequest,
  checkOldPasswordRequest,
  paymentMethodsRequest,
  bankRequest,
  paymentTransactionRequest,
  getBankListRequest,
};
