import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setPalette } from 'src/store/configs/actions';
import { RootState } from 'src/types/store-types';
import { useConnectedAction } from './use-connected-action';

/**
 * Get hook-function to use for persist application palette
 */
export const usePersistPaletteLocalStorage = (): void => {
  const _setPalette = useConnectedAction(setPalette.req);

  const palette = useSelector((state: RootState) => state.configs.palette);

  useEffect(() => {
    if (palette) {
      document.getElementsByTagName('HTML')[0].setAttribute('data-palette', palette);
    }

    _setPalette({ palette });
  }, [_setPalette, palette]);
};
