import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { icons } from 'src/configs/icons';
import { EnumRoutePaths } from 'src/configs/routes';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
  };
};
const HelpCenterWidget: FC<Props> = ({ settings }) => {
  const { styles } = settings;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToHelpCenter = (): void => {
    navigate(EnumRoutePaths.HELP_CENTER);
  };

  return (
    <div className="help-center-widget_container">
      <Button
        style={styles}
        className="help-center-widget primary-filled-btn"
        onClick={navigateToHelpCenter}
        icon={icons.helpCenter}
        iconPosition="left"
      >
        {t('helpCenter.helpCenter')}
      </Button>
    </div>
  );
};
export default HelpCenterWidget;
