import { call, put, select, takeLatest } from 'redux-saga/effects';
import { firstDepositToBonusRequest } from 'src/services/userApi';
import { RootState } from 'src/types/store-types';
import { firstDepositToBonus } from './actions';
import { FIRST_DEPOSIT_TO_BONUS_REQUEST } from './types';

function* firstDepositToBonusWalletRequest({ payload }: any): any {
  const { data } = payload;

  try {
    const user = yield select((store: RootState) => store.user.user);
    if (user) {
      const response = yield call(firstDepositToBonusRequest, data);
      if (response.success) {
        yield put(firstDepositToBonus.success());
      }
    } else {
      console.log('Wrong firstDepositToBonusRequest');
    }
  } catch (e: any) {
    //TO DO show some popup for inform abour force logout
    console.log('Wrong firstDepositToBonusRequest');
  }
}

export function* watchFirstDepositToBonus(): any {
  yield takeLatest(FIRST_DEPOSIT_TO_BONUS_REQUEST, firstDepositToBonusWalletRequest);
}
