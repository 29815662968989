import React, { FC, useEffect, useState } from 'react';
import { Typography } from 'src/components/common/Typography';
import { filterData } from 'src/helpers/utils';
import '../styles.scss';
import HelpCenterNavigation from './HelpCenterNavigation';
import HelpCenterSearchResultItem from './HelpCenterSearchResultItem';

type Props = {
  t: Translation;
  articles: any[];
  setSelectedSubSection: (section: any) => void;
};
const HelpCenterSearchResult: FC<Props> = ({ t, articles, setSelectedSubSection }) => {
  const urlParams = new URLSearchParams(window.location.search);

  const [searched, setSearched] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleTitleClick = (article: any): void => {
    setSelectedSubSection(article);
  };

  useEffect(() => {
    setFilteredData(filterData(articles, searched));
  }, [searched]);

  useEffect(() => {
    const query = urlParams.get('query');
    query && setSearched(query);
  }, [urlParams]);

  return (
    <div className="help-center__search-result">
      <HelpCenterNavigation
        setSelectedSubSection={setSelectedSubSection}
        showSearchbar={false}
        t={t}
        articles={articles}
      />
      <div className="help-center__search-result__container">
        <div className="help-center__search-result-left">
          <Typography className="label" variant={'h6'}>
            {t('byCategory')}
          </Typography>
          <ul>
            <li>{t('allCategories')}</li>
          </ul>
        </div>
        <div className="help-center__search-result-right">
          <Typography className="help-center__search-result__heade" variant={'h6'}>
            {t('helpCenterSearchResult', { foundLength: filteredData.length, searchedWord: searched })}
          </Typography>
          {filteredData.map((filteredItem) => (
            <HelpCenterSearchResultItem data={filteredItem} handleTitleClick={handleTitleClick} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default HelpCenterSearchResult;
