import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCssObject } from 'src/helpers/utils';
import {
  BACKGROUND,
  BORDER_SECTION,
  BUTTON_SECTION,
  ICON_SECTION,
  INPUT_SECTION,
  SEARCH_SECTION,
  TAG_SECTION,
  TEXT_SECTION,
} from 'src/utils/constants';
import { Button } from '../../common/Button';
import './styles.scss';

type ColorPickerProps = {
  name: string;
  value: string;
  defaultState: string;
  key?: string;
};

type DescriptionValueProps = {
  name: string;
  value: string;
};

const ColorPicker: React.FC<ColorPickerProps> = ({ key, name, value, defaultState }) => {
  const [state, updateState] = useState<string>(defaultState);

  const changeColor = (changeKey: string, color: string): void => {
    const el = document?.documentElement;
    const stylesString = el.getAttribute('style');
    const stylesArray = stylesString?.split(';');

    const updatedStylesArray = stylesArray?.map((style) => {
      const temp = style.split(':');
      if (temp[0] === changeKey) {
        return `${changeKey}:${color}`;
      }
      return style;
    });

    const updatedStylesString = updatedStylesArray?.join(';');
    if (updatedStylesString) {
      el.removeAttribute('style');
      el.setAttribute('style', updatedStylesString);
    }
  };

  const handleInput = (value: string, name: string): void => {
    updateState(value);
    changeColor(name, value);
  };

  return (
    <div className="color-picker-container" key={key}>
      <input type="color" value={state} onChange={(e) => handleInput(e.target.value, value)} />
      <input type="text" value={state} onChange={(e) => handleInput(e.target.value, value)} />
      <div className="color-picker__name">{name}</div>
    </div>
  );
};

const createItems = (array: DescriptionValueProps[], name: string, cssObject: any): CollapseProps['items'] => {
  const itemik: CollapseProps['items'] = [
    {
      key: name,
      label: name,
      children: array?.map((item) => (
        <ColorPicker
          key={item.name}
          name={item?.name}
          value={item?.value}
          defaultState={cssObject[item?.value]}
        ></ColorPicker>
      )),
    },
  ];

  return itemik;
};

const SkinBuilder: FC<any> = () => {
  const { t }: Translation = useTranslation();
  const el = document?.documentElement;
  const [cssObject, setCssObject] = useState<object>({});
  const [adminPanel, toggleAdminPanel] = useState(false);

  useEffect(() => {
    const storagePalette = localStorage.getItem('websitePalette') || null;
    if (storagePalette) {
      el.removeAttribute('style');
      el.setAttribute('style', storagePalette);
    }
  }, []);

  useEffect(() => {
    const string = el?.getAttribute('style');
    const storagePalette = localStorage.getItem('websitePalette') || null;
    if (string) {
      if (storagePalette) {
        const data = getCssObject(storagePalette);
        setCssObject(data);
      } else {
        const data = getCssObject(string);
        setCssObject(data);
      }
    }
  }, [el]);

  const copyMyCssObj = useCallback((): void => {
    const myStyle = el?.getAttribute('style');
    if (myStyle) {
      navigator.clipboard.writeText(myStyle);
    }
  }, [el]);

  const saveMycssObj = useCallback((): void => {
    const myStyle = el?.getAttribute('style');
    if (myStyle) {
      localStorage.setItem('websitePalette', myStyle);
    }
  }, [el]);

  const removeSkinBuilder = useCallback((): void => {
    localStorage.setItem('dontShowSkinBuilderTool', 'true');
    localStorage.removeItem('websitePalette');
    window?.location?.reload();
  }, []);

  const classes = `${classNames('skin-builder', { ['opened']: adminPanel, ['closed']: !adminPanel })}`;

  return (
    <div className={classes}>
      <div className="skin-builder-panel">
        <div className="skin-builder__toggle" onClick={() => toggleAdminPanel((state) => !state)}>
          {adminPanel ? t('close') : t('open')}
        </div>
        {adminPanel ? (
          <div className="skin-builder__remove" onClick={() => removeSkinBuilder()}>
            {t('remove')}
          </div>
        ) : null}
      </div>
      <div className="skin-builder-content">
        <title>Admin panel</title>
        <div className="skin-builder-section">
          {<Collapse items={createItems(BACKGROUND, 'Background', cssObject)} />}
        </div>
        <div className="skin-builder-section">
          {<Collapse items={createItems(INPUT_SECTION, 'Input', cssObject)} />}
        </div>
        <div className="skin-builder-section">
          {<Collapse items={createItems(BORDER_SECTION, 'Border', cssObject)} />}
        </div>
        <div className="skin-builder-section">
          {<Collapse items={createItems(SEARCH_SECTION, 'Search', cssObject)} />}
        </div>
        <div className="skin-builder-section">{<Collapse items={createItems(TEXT_SECTION, 'Text', cssObject)} />}</div>
        <div className="skin-builder-section">
          {<Collapse items={createItems(BUTTON_SECTION, 'Button', cssObject)} />}
        </div>
        <div className="skin-builder-section">{<Collapse items={createItems(TAG_SECTION, 'Tag', cssObject)} />}</div>
        <div className="skin-builder-section">{<Collapse items={createItems(ICON_SECTION, 'Icon', cssObject)} />}</div>
        <div className="btn-builder-container">
          <Button
            className="primary-filled-btn builder-btn"
            variant="contained"
            type="button"
            onClick={() => copyMyCssObj()}
          >
            {t('copy')}
          </Button>
          <Button
            className="success-filled-btn builder-btn"
            variant="contained"
            type="button"
            onClick={() => saveMycssObj()}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SkinBuilder;
