import * as FS from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import { Component, ErrorInfo, ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { reportProblem } from 'src/services/dataApi';
import ErrorMessage from './ErrorMessage';

const generalConfigs = sessionStorage.getItem('generalConfigs')
  ? JSON.parse(sessionStorage.getItem('generalConfigs') || '')
  : null;

if (process.env.REACT_APP_SENTRY_ENABLE === 'true' && generalConfigs) {
  const { sentryKey, fsKey } = generalConfigs;

  if (sentryKey) {
    Sentry.init({
      dsn: sentryKey,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      // This sets the sample rate at 10%.
      // You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: 1.0,
      // If you're not already sampling the entire session,
      // change the sample rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    });
  }
  if (fsKey) {
    FS.init({
      orgId: fsKey,
    });
  }
}

interface IProps {
  children: ReactNode;
}

interface IState {
  hasError: boolean;
  errorInfo: any;
}
class ErrorBoundaryComponent extends Component<IProps, IState> {
  public state: IState = {
    hasError: false,
    errorInfo: null,
  };

  public static getDerivedStateFromError(): IState {
    return { hasError: true, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Uncaught error:', error, errorInfo);

    // TO DO send email
    if (process.env.REACT_APP_CRASH_EMAIL_REPORT_ENABLE === 'true') {
      reportProblem(error.stack);
    }
  }

  public render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorMessage />;
    }

    return children;
  }
}

export default withTranslation()(ErrorBoundaryComponent);
