import cn from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { MediaManager } from 'src/manager/MediaManager';
import { RootState } from 'src/types/store-types';
import '../styles.scss';

type ListViewCardProps = {
  t: any;
  provider: string;
  selectedProvider: string[];
  onProviderChange: (p: string) => void;
};
const ListViewCard: FC<ListViewCardProps> = ({ t, provider, selectedProvider, onProviderChange }) => {
  const { generalConfigs } = useSelector((state: RootState) => state?.configs);

  const isThemeLight = generalConfigs?.template.split(' ').includes('light_theme');

  return (
    <div className={cn('provider_card_wrapper', { provider_card_wrapperForLightTheme: isThemeLight })}>
      <Button
        onClick={() => onProviderChange(provider)}
        className={cn('provider_button', {
          selected: Array.isArray(selectedProvider) && selectedProvider.includes(provider),
        })}
      >
        {provider === 'all_providers' ? (
          <>{t(provider)}</>
        ) : (
          <SvgIcon
            src={MediaManager.getSrcFromGeneralMediaAssets(
              `${provider.toLowerCase()}.svg`,
              `/providerIcons/BlackMode/`
            )}
            name={provider}
          />
        )}
      </Button>
    </div>
  );
};
export default ListViewCard;
