/* eslint-disable max-len */
import React, { FC, useCallback } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Components } from 'src/configs/components/components';
import '../styles.scss';

type Props = {
  settings: any;
};
const ContentPageConstructor: FC<Props> = ({ settings }) => {
  const renderContentPageWidgets = useCallback((): JSX.Element => {
    const orderIndex: any = {};
    Object.keys(settings).forEach((key: string) => (orderIndex[`${key}`] = settings[key].priority));

    const widgetLine = Object.keys(settings)
      .sort((a: string, b: string) => orderIndex[a] - orderIndex[b])
      .filter((key: string) => key !== 'styles')
      .map((key: string, index: number) => {
        return React.createElement(Components[key], {
          settings: settings[key],
          key: `${key}_${index}`,
        });
      });

    return <>{widgetLine}</>;
  }, []);

  return (
    <div className="content_container">
      {process.env.REACT_APP_WEBSITENAME === 'PapiGames' && (
        <HelmetProvider>
          <Helmet>
            <title>Papi Games Pôquer ao vivo</title>
            <meta
              name="description"
              content="Jogue pôquer online na Papi Games e compita com jogadores do mundo todo. Entre nas mesas para jogos a dinheiro, torneios e eventos exclusivos!"
            />
            <meta
              name="keywords"
              content="Jogue pôquer online na Papi Games e compita com jogadores do mundo todo. Entre nas mesas para jogos a dinheiro, torneios e eventos exclusivos!"
            />
          </Helmet>
        </HelmetProvider>
      )}
      {renderContentPageWidgets()}
    </div>
  );
};
export default ContentPageConstructor;
