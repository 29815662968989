import { FC } from 'react';
import { MediaManager } from 'src/manager/MediaManager';
import './styles.scss';

type Props = {
  title: string;
  src: string;
  description: string;
};
const Card: FC<Props> = ({ title, src, description }) => {
  return (
    <div className="cards_container_item">
      <p className="cards_container_item_title">{title}</p>
      <img key={src} src={MediaManager.getSrcFromMediaAssets(src, '/cards/')} alt={src} />
      <p className="cards_container_item_description">{description}</p>
    </div>
  );
};
export default Card;
