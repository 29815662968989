import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setSelectedCategory } from 'src/store/app/actions';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';

interface IProps {
  data?: any;
}

const SpinAndGoStarted: FC<IProps> = ({ data }) => {
  const { tournamentId } = data;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _closeDialog = useConnectedAction(closeDialog);

  const onClose = (): void => {
    _closeDialog({ dialogType: EnumDialogsKeys.SPIN_AND_GO_STARTED });
  };

  const onConfirmHandler = (): void => {
    const casinoTournamentIframe: any = document.querySelector('iframe#tournament');

    if (casinoTournamentIframe) {
      _setSelectedCategory('casinoTournament');
      navigate('/category/casinoTournament');
      casinoTournamentIframe?.contentWindow.postMessage({ type: 'openSipinAndGo', tournamentId }, '*');
    } else {
      _setSelectedCategory('casinoTournament');
      navigate('/category/casinoTournament');
      setTimeout(() => {
        const casinoTournamentIframe: any = document.querySelector('iframe#tournament');
        casinoTournamentIframe?.contentWindow.postMessage({ type: 'openSipinAndGo', tournamentId }, '*');
      }, 0);
    }

    onClose();
  };

  return (
    <PopUpContainer
      isOpen
      handleClose={onClose}
      hasOverlay
      handleOK={onConfirmHandler}
      confirmText={'join'}
      title={t(`${'spinAndGoStartedDialog'}.title`, data ?? {})}
      description={t(`${'spinAndGoStartedDialog'}.text`, data ?? {})}
    />
  );
};
export default SpinAndGoStarted;
