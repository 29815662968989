import { FC } from 'react';
import { MediaManager } from 'src/manager/MediaManager';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
    src: string;
  };
};
const ImageWidget: FC<Props> = ({ settings }) => {
  const { styles, src } = settings;

  return (
    <div className="imageWidget_container" style={styles}>
      <img src={MediaManager.getSrcFromMediaAssets(src, '/footerIcons/')} />
    </div>
  );
};
export default ImageWidget;
