import { FC } from 'react';
import ZendeskChat from 'src/components/base-components/Chat/ZendeskChat';
import LiveChat from './LiveChat/LiveChat';
import LiveChatButton from './LiveChatButton';
import TawkChat from './TawkChat';

type Prop = {
  configs: {
    type: string | number; // liveChat | zendesk
    key: any;
    imgSrc?: string;
    styles?: Record<string, string>;
  };
  placement?: 'sidebar' | 'mobileFixedMenu';
};
const Chat: FC<Prop> = (props) => {
  const { placement = 'mobileFixedMenu', configs } = props;

  const chats = {
    tawkChat: <TawkChat />,
    zendesk: <ZendeskChat secretKey={configs?.key} imgSrc={configs?.imgSrc} styles={configs?.styles} />,
    liveChatButton: (
      <LiveChatButton
        settings={{
          license: configs.key,
          imgSrc: configs?.imgSrc,
        }}
        placement={placement}
      />
    ),

    liveChat: (
      <LiveChat
        settings={{
          license: configs.key,
          title: '',
          needLogin: false,
        }}
      />
    ),
  };

  return chats[configs?.type];
};
export default Chat;
