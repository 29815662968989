import { FC } from 'react';
import { Typography } from 'src/components/common/Typography';
import { ArticlesRes } from 'src/types/res-dto/page-res';
import '../styles.scss';
import ArticleTitle from './ArticleTitle';
import HelpCenterSearch from './HelpCenterSearch';

type Props = {
  articles: any[];
  t: Translation;
  setSelectedSection: (section: any) => void;
  setSelectedSubSection: (section: any) => void;
  selectedSubSection: string;
};
const HelpCenterMainPage: FC<Props> = ({
  articles = [],
  t,
  setSelectedSection,
  setSelectedSubSection,
  selectedSubSection,
}) => {
  return (
    <div className="help-center">
      <section className="help-center__search__holder" style={{ backgroundImage: 'url(/images/help-center/bg.jpg)' }}>
        <HelpCenterSearch changeSelectedSubSection={setSelectedSubSection} suggestions t={t} articles={articles} />
      </section>
      <section className="help-center__items__holder">
        {articles?.map((item: ArticlesRes, i: number) => (
          <div key={item.category} onClick={() => setSelectedSection(item)} className="help-center__item">
            <img src={`/images/help-center/${i + 1}.png`} alt="Help Center Image" />
            <Typography variant={'h6'}>{t(`helpCenter.${item.category.toLowerCase()}`)}</Typography>
          </div>
        ))}
      </section>
      <section className="help-center--popular__articles">
        <h3>{t('helpCenter.popularArticles')}</h3>
        <div className="help-center--popular__articles__holder">
          {articles?.map((item: ArticlesRes) =>
            item.subCategories.map((el) =>
              el[Object.keys(el)[0]].map((elem: any) => (
                <ArticleTitle
                  key={elem.title}
                  changeSelectedSubSection={setSelectedSubSection}
                  iconClass="diamond-icon"
                  article={elem}
                  selected={selectedSubSection}
                />
              ))
            )
          )}
        </div>
      </section>
    </div>
  );
};
export default HelpCenterMainPage;
