import cn from 'classnames';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { scrollToTop } from 'src/helpers/utils';
import './styles.scss';

type Props = {
  settings: {
    priority: number;
    styles: Styles;
    classes: string;
  };
};
const BackToTop: FC<Props> = ({ settings }) => {
  const { t } = useTranslation();

  const { styles, classes } = settings;

  const goToTop = (): void => {
    scrollToTop();
  };

  return (
    <div className="backToTopBtn_wrapper">
      {isMobileOnly ? (
        <Button
          ariaLabelledby="submitButtonLabel"
          style={styles}
          className={cn('backToTopBtn', classes)}
          onClick={goToTop}
        >
          <SvgIcon src={icons.arrowDown} />
        </Button>
      ) : (
        <Button
          id="backToTopBtn_button"
          style={styles}
          className={cn('backToTopBtn', classes)}
          onClick={goToTop}
          icon={icons.arrowDown}
          iconPosition="right"
        >
          {t('backToTop')}
        </Button>
      )}
    </div>
  );
};
export default BackToTop;
