import { createReducer } from 'deox';
import produce from 'immer';
import { removeTlTWidgetConfig, setTlTWidgetConfig } from './actions';
import { tltWidgetState } from './types';

export const tltWidgetInitialState: tltWidgetState = {
  tltWidgetConfig: {
    key: null,
    op: null,
    ts: null,
    player: null,
    currency: null,
  },
};
export const tltWidgetReducer = createReducer(tltWidgetInitialState, (handle) => [
  handle(setTlTWidgetConfig, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.tltWidgetConfig = payload;
    })
  ),
  handle(removeTlTWidgetConfig, (state, {}: any) =>
    produce(state, (draft) => {
      draft.tltWidgetConfig = tltWidgetInitialState.tltWidgetConfig;
    })
  ),
]);
