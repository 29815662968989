import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import _styles from '../../styles.module.scss';

type Props = {
  setShowTermsAndConditions: (show: boolean) => void;
};
const TermsAndConditions: FC<Props> = ({ setShowTermsAndConditions }) => {
  const { t } = useTranslation();

  const closeTermsAndConditions = (): void => {
    setShowTermsAndConditions(false);
  };

  return (
    <div className={_styles.terms_and_condition_modal}>
      <div className={_styles.header}>
        <Typography variant={'h4'} className={_styles.title}>
          {t('refer_a_friend.terms_and_conditions_title')}
        </Typography>
        <div className={_styles.close}>
          <SvgIcon src={icons.close} onClick={closeTermsAndConditions} />
        </div>
      </div>
      <div className={_styles.content}>
        <p>{t('refer_a_friend.terms_and_conditions_text')}</p>
      </div>
    </div>
  );
};
export default TermsAndConditions;
