import { useEffect } from 'react';

export const usePostMesssage = (cb: (d: string) => void, type: string): void => {
  const handleFrameMessages = (e: any): void => {
    if (e.data.type === type) {
      cb(JSON.parse(e.data.value));
    }
  };

  useEffect(() => {
    if (window?.parent) {
      window.parent.postMessage({ name: 'messageFromWebsiteV5toSkinBuilder' }, '*');
    }

    window.addEventListener('message', handleFrameMessages);

    return () => window.removeEventListener('message', handleFrameMessages);
  }, []);
};
