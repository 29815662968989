import { isMobile } from 'react-device-detect';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTransactionSuccessNotification } from 'src/helpers/transformers';
import { paymentTransactionRequest } from 'src/services/userApi';
import { setTransactions, setTrasactionNotification } from './actions';
import { TRANSACTION_SET_TRANSACTION_REQ } from './types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function* makeTransaction({ payload }: any): any {
  try {
    yield put(setTransactions.load(true));
    payload.method === 'hemenode' && (payload.method = `${payload.method}724`);
    payload?.withdrawUseHomeUrl && (payload.redirectURL = window.origin);

    const response = yield call(paymentTransactionRequest, payload);

    if (response) {
      if (response.success) {
        if (Boolean(response?.result?.redirect || response?.result?.redirectUrl)) {
          window?.open(
            response?.result?.redirect || response?.result?.redirectUrl,
            `${isMobile ? '_self' : '_blank'}`,
            'location=yes,height=570,width=520,scrollbars=yes,status=yes'
          );
        } else {
          yield put(
            setTrasactionNotification({ status: true, message: getTransactionSuccessNotification(response, payload) })
          );
        }
      } else {
        // yield put(
        //   setTrasactionNotification({ status: false, message: getTransactionErrorNotification(response, payload) })
        // );
      }

      yield put(setTransactions.success(response));
      yield put(setTransactions.load(false));
    } else {
      yield put(setTransactions.load(false));
    }
  } catch (e: any) {
    console.log(e);

    yield put(setTransactions.fail());
    yield put(setTransactions.load(false));
  }
}

export function* watchTransactions(): any {
  yield takeLatest(TRANSACTION_SET_TRANSACTION_REQ, makeTransaction);
}
