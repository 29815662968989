import { createAction } from 'deox';
import {
  TRANSACTION_SET_TRANSACTION_FAIL,
  TRANSACTION_SET_TRANSACTION_LOAD,
  TRANSACTION_SET_TRANSACTION_NOTIOFICATION,
  TRANSACTION_SET_TRANSACTION_REQ,
  TRANSACTION_SET_TRANSACTION_SUCCESS,
} from './types';

export const setTransactions = {
  req: createAction(TRANSACTION_SET_TRANSACTION_REQ, (res) => (params) => res(params)),
  success: createAction(TRANSACTION_SET_TRANSACTION_SUCCESS, (res) => (params) => res(params)),
  fail: createAction(TRANSACTION_SET_TRANSACTION_FAIL),
  load: createAction(TRANSACTION_SET_TRANSACTION_LOAD, (res) => (params) => res(params)),
};

export const setTrasactionNotification = createAction(
  TRANSACTION_SET_TRANSACTION_NOTIOFICATION,
  (res) => (params) => res(params)
);
