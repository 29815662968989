export class ArrayTransformationHelper {
  public static convertIntoSelectOptionModel = (array: any[], keys: [string, string, string]): SelectOptionType[] => {
    return array.map((arrayItem: any) => {
      const obj: SelectOptionType = {
        label: arrayItem[keys[0]],
        value: arrayItem[keys[1]],
      };

      if (arrayItem[keys[2]]) {
        obj.iconSrc = arrayItem[keys[2]];
      }

      return obj;
    });
  };
}
