import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { getAuth } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';
import { Button } from '../../../components/common/Button';
import { IForgotRes, ISignInRes, ISignUpRes } from '../../../types/res-dto/authorization';
import AuthModal from '../Modals/AuthModal';
import './styles.scss';

type Props = {
  settings: {
    styles: Styles;
  };
};
const Auth: FC<Props> = ({ settings }) => {
  const { styles } = settings;

  const { t }: Translation = useTranslation();

  const _openAuthModal = useConnectedAction(openAuthModal);

  const { modalContent } = useSelector((state: RootState) => state.authModal);
  const { showMenu } = useSelector((state: RootState) => state.mobile);

  const [loginModalData, setLoginModalData] = useState<ISignInRes>({});
  const [forgotModalData, setForgotModalData] = useState<IForgotRes>({});
  const [signUpModalData, setSignUpModalData] = useState<ISignUpRes>({});
  const [buttonCutomStyles, setButtonCutomStyles] = useState<{ login: Styles | any; registration: Styles | any }>({
    login: {},
    registration: {},
  });

  const toggleModal = (action: 'close' | 'open', content?: 'login' | 'registration' | 'forgot' | null): void => {
    const menuItem = localStorage.getItem('menuItem');
    if (PersisterService.hasPersistValue('navigation') && menuItem) {
      const res = PersisterService.getPersistValue('navigation');
      const _navItemData = res.find((f: { name: string | null }) => f.name === menuItem);
      if (_navItemData.needLogIn) {
        setBodyOverflow('unset');
        _openAuthModal(null);
        localStorage.removeItem('menuItem');
      }
    } else {
      !showMenu && setBodyOverflow(action === 'open' ? 'unset' : 'set');
      _openAuthModal(action === 'open' ? content : null);
    }
  };

  const fetchAuthData = (): void => {
    getAuth().then((res: any) => {
      if (res.success) {
        setLoginModalData(res.result.signIn);
        setForgotModalData(res.result.forgotPassword);
        setButtonCutomStyles({ login: res.result.login, registration: res.result.registration });
        setSignUpModalData(res.result.signUp);
        sessionStorage.setItem(
          'authConfigs',
          JSON.stringify([
            res.result.signIn,
            res.result.forgotPassword,
            res.result.signUp,
            { login: res.result.login, registration: res.result.registration },
          ])
        );
      }
    });
  };

  const isAuthVisiable = useMemo(() => {
    return Boolean(modalContent) && !!loginModalData && !!forgotModalData && !!signUpModalData;
  }, [!!modalContent, !!loginModalData, !!forgotModalData, !!signUpModalData]);

  usePostMesssage((v: any) => setLoginModalData(v), 'signIn');
  usePostMesssage((v: any) => setForgotModalData(v), 'forgot');
  usePostMesssage((v: any) => setSignUpModalData(v), 'signUp');
  usePostMesssage((v: any) => setButtonCutomStyles(v), 'auth');

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    let result = null;

    if (PersisterService.hasPersistValue('authConfigs')) {
      result = PersisterService.getPersistValue('authConfigs');

      if (result[0]) setLoginModalData(result[0]);
      if (result[1]) setForgotModalData(result[1]);
      if (result[2]) setSignUpModalData(result[2]);
      if (result[3]) setButtonCutomStyles(result[3]);
    } else {
      fetchAuthData();
    }
  }, []);

  return (
    <div className="auth_container" style={styles}>
      <div className="button_row">
        <Button
          style={buttonCutomStyles?.login?.styles}
          className={cn('loginButton', buttonCutomStyles?.login?.classes)}
          onClick={() => toggleModal('open', 'login')}
        >
          {t('button_signIn')}
        </Button>
        {!process.env.REACT_APP_HIDE_REG && (
          <Button
            style={buttonCutomStyles?.registration?.styles}
            className={cn('registerButton', buttonCutomStyles?.registration?.classes)}
            onClick={() => toggleModal('open', 'registration')}
          >
            {t('button_signUp')}
          </Button>
        )}
      </div>

      <AuthModal
        visible={isAuthVisiable}
        close={() => toggleModal('close')}
        loginModalData={loginModalData}
        forgotModalData={forgotModalData}
        signUpModalData={signUpModalData}
        content={modalContent}
        setModalContent={toggleModal}
        hasTitle
      />
    </div>
  );
};
export default Auth;
