import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import gitVersion from 'src/_git_commit';
import _styles from './styles.module.scss';

const gitInfo = (gitVersion as any)?.logMessage ?? null;

const Version: FC = () => {
  const { t }: Translation = useTranslation();

  const storedVersion = localStorage.getItem('app_version');

  if (gitInfo && storedVersion) {
    if (gitInfo !== storedVersion) {
      localStorage.clear();
      sessionStorage.clear();
      if (window.location.href.includes('affiliateId')) {
        window.location.replace(window.location.href);
      } else {
        window.location.replace('/');
      }
    }
  } else if (!storedVersion) {
    localStorage.setItem('app_version', gitInfo);
  }

  if (gitInfo) {
    return (
      <p className={_styles.version}>
        {t('version')}: {gitInfo}
      </p>
    );
  }

  return null;
};
export default Version;
