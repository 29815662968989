import cn from 'classnames';
import { FC } from 'react';
import { icons } from '../../../../configs/icons';
import { SvgIcon } from '../../SvgIcon';
import { Typography } from '../../Typography';
import styles from './styles.module.scss';

type Props = {
  onClose?: () => void;
  title?: string;
  iconName?: string;
  closeIcon?: boolean;
};

export const ModalHeader: FC<Props> = ({ onClose, title = '', iconName, closeIcon }) => {
  return onClose ? (
    <div className={styles.modalHeader}>
      <Typography
        variant="h3"
        noWrap
        className={cn(styles.modalHeader_title, { [styles.modalHeader_title_close]: closeIcon })}
      >
        {title}
      </Typography>
      {iconName && <SvgIcon pointer src={icons[iconName]} className={styles.modalHeader_icon} />}

      {closeIcon && <SvgIcon pointer src={icons.close} onClick={onClose} className={styles.modalHeader_icon} />}
    </div>
  ) : null;
};
