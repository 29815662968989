import 'moment/min/locales';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { getSkinId } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { validateNationalIdRequest } from 'src/services/authorizationApi';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import _styles from './styles.module.scss';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required?: boolean;
};
const NationalId_input: FC<IProps> = (props) => {
  const { t }: Translation = useTranslation();
  const skinId = getSkinId();

  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();

  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  const [validatedNationalId, setValidatedNationalId] = useState<string>('');

  const nationalIdRegExp = useMemo(() => {
    return props.regexp ? new RegExp(props.regexp) : new RegExp('^\\d{11}$');
  }, [props.regexp]);

  const validateNationalIdReq = (value: any): void => {
    validateNationalIdRequest({ field: 'nationalId', value: value, skinId: skinId })
      .then((res) => {
        if (res.success) {
          setValidatedNationalId(value);
          resetSignUpError('nationalId');
        } else {
          _setErrorReq({ ...signUpErrors, nationalId: t('invalid_national_id') });
        }
      })
      .catch(() => {
        _setErrorReq({ ...signUpErrors, nationalId: t('invalid_national_id') });
      });
  };

  const validationSchema = Yup.object().shape({
    nationalId: Yup.string().required(t('requiredField')).matches(nationalIdRegExp, t('invalid_nationalId_length')),
  });

  const handleValidater = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ nationalId: value }, { abortEarly: false });
      if (value !== validatedNationalId) {
        validateNationalIdReq(value);
      }
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, nationalId: error.errors[0] });
      }
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (signUpErrors.nationalId) {
      resetSignUpError('nationalId');
    }
    props.onChange(e);
  };

  const handleBlur = (): void => {
    handleValidater(props.value);
  };

  return (
    <Input
      error={signUpErrors.nationalId}
      {...props}
      onChange={handleOnChange}
      onBlur={handleBlur}
      type="number"
      disableNumberSymbols
      className={_styles.national_id__input}
    />
  );
};

export default NationalId_input;
