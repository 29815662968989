import cn from 'classnames';
import { FC } from 'react';
import _styles from './styles.module.scss';

type Props = {
  date: string;
  status: string;
  ipAddress: string;
  dotStatus: boolean;
  t: (arg: string) => string;
};
const LoginHistoryItem: FC<Props> = ({ date, status, dotStatus, ipAddress, t }) => {
  return (
    <div className={cn(_styles.login_history_container, 'login_history_container')}>
      <span className={_styles.login_history_container__item}>{date}</span>
      <div className={_styles.login_history_container__item}>
        <div className={cn(_styles.login_history_container__item__dot, { [_styles.success]: dotStatus })}></div>
        <span>{t(status)}</span>
      </div>
      <span className={_styles.login_history_container__item}>{ipAddress}</span>
    </div>
  );
};
export default LoginHistoryItem;
