import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { getStaticPagesFromCms } from 'src/services/dataApi';
import { setBottomNavigation } from 'src/store/configs/actions';
import { useConnectedAction } from '../../../../hooks/use-connected-action';
import { CMSManager } from '../../../../manager/CMSManager';
import _styles from './styles.module.scss';

type Props = {
  changeSelectedMenuItem: (menuItem: string) => void;
  selectMenuItem: string;
  openInfoAndRules: (ev: { section: string; subSection: string; subTitle?: string }) => void;
};
const StaticLists: FC<Props> = ({ changeSelectedMenuItem, selectMenuItem, openInfoAndRules }) => {
  const { t }: Translation = useTranslation();
  const { locale } = useI18n();
  const _setBottomNavigation = useConnectedAction(setBottomNavigation);
  const [infoData, setInfoData] = useState<Record<string, string>[]>([]);

  const openAccountModal = (subSection: string, subTitle: string): void => {
    openInfoAndRules({ section: 'info_and_rules', subSection: subSection, subTitle: subTitle });
  };

  const getStaticPageData = (): void => {
    getStaticPagesFromCms(locale).then((res) => {
      if (res?.data?.[0]) {
        const _ = CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string].getInfoAndRulesData(res.data);
        _setBottomNavigation(_);
        setInfoData(
          res.data[0].attributes.info_pages.data.map((f: { attributes: { Name: string; Title: string } }) => {
            return {
              name: f.attributes.Name,
              title: f.attributes.Title,
            };
          })
        );
      } else {
        setInfoData([]);
      }
    });
  };

  useEffect(() => {
    getStaticPageData();
  }, [locale]);

  if (infoData.length > 0) {
    return (
      <>
        <Button
          className={cn(_styles.accordion, _styles['lg-item'], 'mobile_menu_accordion')}
          onClick={() => changeSelectedMenuItem('info_and_rules')}
          justifyContentValue="space-between"
        >
          <div>
            <span className="icon-info" />
            {t('info_and_rules')}
          </div>
          <div className={cn(_styles.arrow, { [_styles.rotateArrow]: selectMenuItem === 'info_and_rules' })}>
            <SvgIcon src={icons.arrowDown} />
          </div>
        </Button>
        <ul
          className={_styles.panel}
          style={{
            display: selectMenuItem === 'info_and_rules' ? 'block' : 'none',
          }}
        >
          {infoData?.map((page, j) => (
            <li key={j + page?.title} onClick={() => openAccountModal(page?.name, page?.title)}>
              {t(page?.title)}
            </li>
          ))}
        </ul>
      </>
    );
  }
  return <></>;
};
export default StaticLists;
