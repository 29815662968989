import { FC } from 'react';
import GroupedPayment from './GroupedPayment';

type Props = {
  groupedPayments: { [key: string]: any[] };
  selectedGroupOfPayment: string;
  selectPaymentGroup: (group: string) => void;
};
const GroupedPayments: FC<Props> = ({ groupedPayments, selectedGroupOfPayment, selectPaymentGroup }) => {
  return (
    <div className="group_container">
      <div className="inner_container">
        {Object.keys(groupedPayments).map((group: string) => (
          <GroupedPayment
            key={group}
            group={group}
            selectedGroupOfPayment={selectedGroupOfPayment}
            selectPaymentGroup={selectPaymentGroup}
            groupedPayments={groupedPayments}
          />
        ))}
      </div>
    </div>
  );
};
export default GroupedPayments;
