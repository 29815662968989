import cn from 'classnames';
import { FC } from 'react';
import { MediaManager } from 'src/manager/MediaManager';
import './styles.scss';

type Props = {
  imgUrl: string;
  name: string;
  paymentItem: any;
  selectedPaymentID: any;
  handlePaymentTabClick: (payment: any) => void;
};
const TabItemTemplate1: FC<Props> = ({ imgUrl, name, paymentItem, selectedPaymentID, handlePaymentTabClick }) => {
  return (
    <div
      onClick={() => handlePaymentTabClick(paymentItem.id)}
      className={cn('tabs_section__item_1', {
        ['selected']: selectedPaymentID === paymentItem.id,
      })}
    >
      <div className={'tab_image_wrapper'}>
        <img
          src={imgUrl || MediaManager.getSrcFromMediaAssets('logo.svg', '/')}
          alt={name || 'logo'}
          className={'tab_image'}
        />
      </div>
      <div className={'tab_name'}>{name}</div>
    </div>
  );
};
export default TabItemTemplate1;
