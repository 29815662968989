import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { GENERATE_LINK_SOCIAL_ICONS } from 'src/utils/constants';
import _styles from '../../styles.module.scss';

type Props = {
  closeLinkModal: (show: boolean) => void;
  openSocilMedia: (media: string) => void;
  link: string;
  copiedLink: boolean;
  copyLink: () => void;
};
const InviteAFriend: FC<Props> = ({ closeLinkModal, openSocilMedia, copyLink, copiedLink, link }) => {
  const { t } = useTranslation();

  return (
    <div className={_styles.invite_a_friend_modal}>
      <div className={_styles.header}>
        <Typography variant={'h4'} className={_styles.title}>
          {t('refer_a_friend.link_generated')}
        </Typography>
        <div className={_styles.close}>
          <SvgIcon src={icons.close} onClick={() => closeLinkModal(false)} />
        </div>
      </div>
      <div className={_styles.content}>
        <div className={_styles.input}>
          <Typography variant={'h6'}>{t(`refer_a_friend.shareByLink`)}</Typography>
          <div className={_styles.inner_input}>
            <p>{link}</p>
          </div>
          <Button className={_styles.copy} onClick={copyLink}>
            {copiedLink && (
              <span className={_styles.copied}>
                {t('refer_a_friend.copied')}
                <span className={_styles.arrow_down} />
              </span>
            )}
            <SvgIcon src="/images/refer_a_friend/copy.svg" className={_styles.copy_icon} />
            {t('refer_a_friend.copy')}
          </Button>
        </div>
        <div className={_styles.socials}>
          <p>{t('refer_a_friend.or_share')}</p>
          <div className={_styles.social_icons}>
            {GENERATE_LINK_SOCIAL_ICONS.map((src: string) => (
              <div className={_styles.icon} key={src} onClick={() => openSocilMedia(src)}>
                <SvgIcon src={`/images/refer_a_friend/${src}.svg`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default InviteAFriend;
