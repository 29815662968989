import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import { filterData } from 'src/helpers/utils';
import '../styles.scss';

type Props = {
  t: Translation;
  small?: boolean;
  suggestions: boolean;
  articles: any[];
  changeSelectedSubSection: (subSection: any) => void;
};
const HelpCenterSearch: FC<Props> = ({ t, small = false, suggestions, articles, changeSelectedSubSection }) => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const handleSearch = (event: any): void => {
    setSearchText(event.target.value);
    setShowSuggestions(true);
  };

  const handleKeyDown = (event: any): void => {
    if (event.key === 'Enter') {
      navigate(`/helpCenter?query=${searchText}`);
    }
  };

  const selectSuggestion = (article: any): void => {
    changeSelectedSubSection(article);
  };

  useEffect(() => {
    setFilteredData(filterData(articles, searchText));
  }, [searchText]);

  return (
    <div className="help-center__search">
      <Input
        value={searchText}
        onChange={handleSearch}
        placeholder={t('search')}
        className={cn({
          'help-center__search__input': true,
          'help-center__search__input--small': small,
        })}
        keyDown={handleKeyDown}
        name={''}
        isLightInp
      />
      {showSuggestions && suggestions && searchText?.length > 2 && (
        <>
          <Button className="help-center__search-input-suggestions-mask" onClick={() => setShowSuggestions(false)} />
          <div className="help-center__search-input-suggestions">
            {filteredData?.map((item, index) => (
              <Button className="search-suggestions--row" key={index} onClick={() => selectSuggestion(item)}>
                {item.title}
              </Button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default HelpCenterSearch;
