import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { closeAccountModal, openMyAccountModal } from 'src/store/account-modal/actions';
import { RootState } from 'src/types/store-types';
import Views from './components/Views';
import DesktopAccountModal from './DesktopAccountModal';
import MobileAccountModal from './MobileAccountModal';

const AccountModal: FC = () => {
  const { locale } = useI18n();

  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _closeModal = useConnectedAction(closeAccountModal);

  const { accountModal } = useSelector((state: RootState) => state);
  const { modalContent } = useSelector((state: RootState) => state.authModal);
  const { showUserSetting } = useSelector((state: RootState) => state.app);
  const { showMenu } = useSelector((state: RootState) => state.mobile);

  const section = accountModal.section === 'info_and_rules' ? accountModal.section : accountModal.subSection;
  const View = Views[section];

  const closeModal = (): void => {
    _closeModal();
    if (isMobileOnly && (showUserSetting || showMenu)) {
      setBodyOverflow('unset');
    } else {
      if (isMobileOnly && modalContent === 'registration') {
        setTimeout(() => setBodyOverflow('unset'), 0);
      } else {
        !modalContent && setBodyOverflow('set');
      }
    }
  };

  if (accountModal.section && locale) {
    if (isMobileOnly) {
      return <MobileAccountModal accountModal={accountModal} View={<View language={locale} />} close={closeModal} />;
    }

    return (
      <DesktopAccountModal
        accountModal={accountModal}
        View={<View language={locale} />}
        open={_openAccountModal}
        close={closeModal}
      />
    );
  }

  return <></>;
};
export default AccountModal;
