import { Modal } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { EnumRoutePaths } from 'src/configs/routes';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeAccountModal } from 'src/store/account-modal/actions';
import { setSelectedCategory } from 'src/store/app/actions';
import { setPageConfigs } from 'src/store/configs/actions';
import { setSelectedGame } from 'src/store/games/actions';
import { resetForceLogout, setLogout } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
}
const ForceLogout: FC<IProps> = ({ onClose, open }) => {
  const { t }: Translation = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const _setPageConfigs = useConnectedAction(setPageConfigs.req);
  const _logout = useConnectedAction(setLogout);
  const _resetForceLogout = useConnectedAction(resetForceLogout);
  const _closeModal = useConnectedAction(closeAccountModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _selectedGame = useConnectedAction(setSelectedGame);

  const { forceLogout } = useSelector((state: RootState) => state.user);
  const { navigation } = useSelector((state: RootState) => state.configs);

  const onCloseFn = (): void => {
    _selectedGame(null);
    if (pathname.includes('category')) {
      const _route = pathname.split('/category/')[1];
      const _selectedRoute = navigation.find((n) => n.name === _route);

      if (_selectedRoute?.needLogIn) {
        navigate(EnumRoutePaths.DEFAULT);
        _setSelectedCategory('home');
      }
    }

    _logout({
      onSuccessCb: () => {
        _setPageConfigs();
        _closeModal();
        _resetForceLogout();

        onClose();

        setBodyOverflow('set');
      },
    });
  };

  return (
    <Modal footer={null} open={open} className={'inactivity-modal-container'} onCancel={onCloseFn} zIndex={1000000000}>
      <div className="inactivity-modal-container__content">
        <div className={'inactivity-modal-container__title'}>
          <Typography variant={'subtitle1'}>{t('forceLogoutTitle')}</Typography>
          <img src={icons.subtract} />
        </div>
        <Typography variant={'subtitle3'} className="inactivity-modal-container__text">
          {t('forceLogoutMessage')}
          {forceLogout?.message}
        </Typography>
        <div className="inactivity-modal__button-wrapper">
          <Button className={'inactivity-modal__button success-filled-btn'} onClick={onCloseFn}>
            <Typography variant={'subtitle3'}>{t('ok')}</Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ForceLogout;
