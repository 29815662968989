import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'src/components/common/Button';
import { EnumRoutePaths } from 'src/configs/routes';
import HelpCenterSearch from './HelpCenterSearch';

type Props = {
  t: Translation;
  articles: any;
  showSearchbar: boolean;
  setSelectedSubSection: (subSection: any) => void;
};

const HelpCenterNavigation: FC<Props> = ({ t, articles, showSearchbar = true, setSelectedSubSection }) => {
  const navigate = useNavigate();

  const redirectToHelpCenterMainPage = (): void => {
    navigate(EnumRoutePaths.HELP_CENTER);
  };

  return (
    <div className="help-center__nav">
      <Button className="help-center__nav__link" onClick={redirectToHelpCenterMainPage}>
        {t('helpCenter.helpCenter')}
      </Button>
      {showSearchbar && (
        <HelpCenterSearch
          changeSelectedSubSection={setSelectedSubSection}
          t={t}
          suggestions={false}
          articles={articles}
        />
      )}
    </div>
  );
};
export default HelpCenterNavigation;
