import codes from 'country-calling-code';
import moment from 'moment';
import { FC, useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ThemeSwitcher from 'src/components/base-components/ThemeSwitcher';
import { Button } from 'src/components/common/Button';
import { RootState } from 'src/types/store-types';
import { PERSONAL_INFO_FIELD_DESKTOP, PERSONAL_INFO_FIELD_MOBILE } from 'src/utils/constants';
import AccountDetail from './AccountDetail';
import _styles from './styles.module.scss';

const AccountDetails: FC = () => {
  const { t }: Translation = useTranslation();

  const { user } = useSelector((state: RootState) => state.user);

  const userCountry = useMemo(() => {
    const result = codes.find((c) => c.isoCode3 === user?.countryCode);

    return result ? result.country : null;
  }, [user]);

  const birthDateArr = useCallback((el: string) => {
    if (el) {
      return {
        day: moment(el).format('DD'),
        month: moment(el).format('MM'),
        year: moment(el).format('YYYY'),
      };
    }

    return { day: null, month: null, year: null };
  }, []);

  const content = useCallback(
    (el: string) => {
      if (el === 'birthDate') {
        return (
          <>
            {Object.keys(birthDateArr(user?.personalInfo?.[el])).map((info: string) => {
              return (
                <div key={info} className={_styles.container__birthDate_item}>
                  <AccountDetail key={el} value={birthDateArr(user?.personalInfo?.[el])?.[info]} title={t(info)} />
                </div>
              );
            })}
          </>
        );
      } else if (el === 'countryCode') {
        return <AccountDetail value={userCountry} title={t(`personalInfo_${el}`)} />;
      } else if (el === 'smartico') {
        // work only in dev
        return (
          <Button className="secondary-btn" size="lg" onClick={() => (window as any)?._smartico.dp('dp:gf')}>
            My Space
          </Button>
        );
      } else {
        return <AccountDetail value={user?.personalInfo?.[el]} title={t(`personalInfo_${el}`)} />;
      }
    },
    [user]
  );

  return (
    <div className={_styles.container}>
      <p className={_styles.container__support}>{t('customer_support')}</p>
      {(isMobile ? PERSONAL_INFO_FIELD_MOBILE : PERSONAL_INFO_FIELD_DESKTOP).map((el: string) => {
        if ((el === 'theme' || el === 'smartico') && process.env.REACT_APP_MODE !== 'DEV') {
          return null;
        }
        return (
          <div key={el} className={_styles.container__item}>
            {content(el)}
          </div>
        );
      })}
      {process.env.REACT_APP_THEME_MODE && (
        <div className={_styles.container__item}>
          <ThemeSwitcher />
        </div>
      )}
    </div>
  );
};
export default AccountDetails;
