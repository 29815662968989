import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MediaManager } from 'src/manager/MediaManager';
import { scrollToTop } from '../../../helpers/utils';
import { useConnectedAction } from '../../../hooks/use-connected-action';
import { setSelectedCategory } from '../../../store/app/actions';
import './styles.scss';

type Props = {
  settings: {
    styles: Styles;
    priority: number;
    data: { src: string; link: string }[];
  };
};
const FooterLogos: FC<Props> = ({ settings }) => {
  const navigate = useNavigate();
  const { styles, data } = settings;
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const handleClick = (to: string): void => {
    if (to) {
      _setSelectedCategory('home');
      scrollToTop();
      navigate(to);
    }
  };

  return (
    <div className="footer_logos_container" style={styles}>
      {data?.map((d, i) => {
        return (
          <div onClick={() => handleClick(d.link)} key={d.link + i}>
            <img key={d.src} src={MediaManager.getSrcFromMediaAssets(d.src, '/footerIcons/')} alt={d.src} />
          </div>
        );
      })}
    </div>
  );
};
export default FooterLogos;
