import { FC, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { SliderDataTransforamtionHelper } from 'src/helpers/SliderDataTransformationHelper';
import { detectDevice, scrollToTop } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { gameListRequest } from 'src/services/gameApi';
import { setSelectedCategory } from 'src/store/app/actions';
import { setSelectedTag } from 'src/store/games/actions';
import Template1 from '../components/Template1';
import Template2 from '../components/Template2';
import Template3 from '../components/Template3';
import Template4 from '../components/Template4';
import '../styles.scss';

type Props = {
  category: string;
  tagName: string;
  template: string;
  showViewAllLink?: boolean;
  showArrows?: boolean;
  trTagName?: string;
  limit: number;
};
const HomePageGamesSlider: FC<Props> = ({
  limit,
  category,
  tagName,
  trTagName,
  template,
  showViewAllLink = true,
  showArrows,
}) => {
  const device = detectDevice();
  const navigate = useNavigate();

  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const [activeGames, setActiveGames] = useState<any[]>([]);

  const viewAllGamesOfTag = (): void => {
    scrollToTop();
    _setSelectedTag(trTagName || tagName);
    _setSelectedCategory(category);
    navigate(`/category/${category}`);
  };

  const getTemplate = (): any => {
    if (isMobileOnly && template !== 'template_4') {
      return (
        <Template2
          activeGames={activeGames}
          viewAllGamesOfTag={viewAllGamesOfTag}
          tagName={trTagName || tagName}
          showArrows={showArrows}
          showViewAllLink={showViewAllLink}
        />
      );
    } else {
      if (template === 'template_1')
        return (
          <Template1
            activeGames={activeGames}
            viewAllGamesOfTag={viewAllGamesOfTag}
            tagName={trTagName || tagName}
            showArrows={showArrows}
            showViewAllLink={showViewAllLink}
          />
        );
      if (template === 'template_2')
        return (
          <Template2
            activeGames={activeGames}
            viewAllGamesOfTag={viewAllGamesOfTag}
            tagName={trTagName || tagName}
            showArrows={showArrows}
            showViewAllLink={showViewAllLink}
          />
        );
      if (template === 'template_3')
        return <Template3 activeGames={activeGames} viewAllGamesOfTag={viewAllGamesOfTag} tagName={tagName} />;
      if (template === 'template_4') return <Template4 activeGames={activeGames} />;
    }
  };

  const _device = useMemo(() => {
    return device === 'tablet' ? 'mobile' : device;
  }, []);

  const getGames = (): void => {
    const tag = tagName ? [tagName] : null;

    gameListRequest(_device, [], 0, category, tag, limit).then((res: any) => {
      if (res.success && !!res.games.length) {
        if (isMobileOnly && template !== 'template_4') {
          setActiveGames(SliderDataTransforamtionHelper.setGameSliderTemplate2Mobile(res.games));
        } else {
          setActiveGames(res.games);
        }
      }
    });
  };

  useEffect(() => {
    !!category && !!limit && getGames();
  }, [category, limit]);

  if (!!activeGames.length) {
    return getTemplate();
  }

  return <></>;
};
export default HomePageGamesSlider;
