import { Carousel } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import cn from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { SliderDataTransforamtionHelper } from 'src/helpers/SliderDataTransformationHelper';
import { getBiggestWins, getLatestWins } from 'src/services/winApi';
import { RootState } from 'src/types/store-types';
import { LeftArrow, RightArrow } from '../Slider/components/Arrows';
import './styles.scss';
import WinCard from './WinCrad';

type Props = {
  styles?: { [key: string]: string };
};
const BL_WINs: FC<Props> = ({ styles = {} }) => {
  const { t }: Translation = useTranslation();
  const location = useLocation();
  const ref = useRef<CarouselRef | null>(null);

  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const [index, setIndex] = useState<number>(0);
  const [activeGames, setActiveGames] = useState<Win[][]>([]);
  const [selectedWinType, setSelectedWinType] = useState<'biggest' | 'latest'>('biggest');
  const [effectType, setEffectType] = useState<'fade' | 'scrollx'>('scrollx');
  const [isSkeleton, setIsSkeleton] = useState<boolean>(false);

  const changeScrollEffect = (): void => {
    setEffectType('fade');

    setTimeout(() => {
      setEffectType('scrollx');
    }, 1000);
  };

  const onPrevClick = (): void => {
    setIndex((prevState: number) => prevState - 1);
    (ref as any).current.prev();
  };

  const onNextClick = (): void => {
    setIndex((prevState: number) => prevState + 1);
    (ref as any).current.next();
  };

  const getBiggestWinsData = (): void => {
    changeScrollEffect();
    setSelectedWinType('biggest');
    setIsSkeleton(true);

    getBiggestWins(generalConfigs?.hierarchyId).then((res) => {
      if (res.success) {
        setActiveGames(SliderDataTransforamtionHelper.setWinSliderTemplate(res.result, isMobileOnly));
        setIsSkeleton(false);
      }
    });
  };

  const getLatestWinsData = (): void => {
    changeScrollEffect();
    setSelectedWinType('latest');
    setIsSkeleton(true);

    getLatestWins(generalConfigs?.hierarchyId).then((res) => {
      if (res.success) {
        setActiveGames(SliderDataTransforamtionHelper.setWinSliderTemplate(res.result, isMobileOnly));
        setIsSkeleton(false);
      }
    });
  };

  useEffect(() => {
    getBiggestWinsData();
  }, []);

  if (!location.pathname.includes('casino')) {
    return null;
  }

  return (
    <div className="wins_container" style={styles}>
      <div className="wins_container_header">
        <div className="wins_types_container">
          <Button
            type="button"
            onClick={getBiggestWinsData}
            className={cn('win_tab', { ['active']: selectedWinType === 'biggest' })}
          >
            {t('biggest_winners')}
          </Button>
          <Button
            type="button"
            onClick={getLatestWinsData}
            className={cn('win_tab', { ['active']: selectedWinType === 'latest' })}
          >
            {t('latest_winners')}
          </Button>
        </div>
        {!!activeGames?.length && (
          <div className="arrows">
            <LeftArrow disabled={index === 0} placement="center" type="squaredDark" slide={onPrevClick} />
            <RightArrow
              disabled={index === activeGames.length}
              placement="center"
              type="squaredDark"
              slide={onNextClick}
            />
          </div>
        )}
      </div>

      <Carousel className="game_carousel_conatiner" ref={ref} dots={false} autoplay effect={effectType}>
        {isSkeleton ? (
          <>
            {/* TO DO add skeleton */}
            <WinCard isSkeleton />
            <WinCard isSkeleton />
            <WinCard isSkeleton />
          </>
        ) : (
          activeGames?.map((win: Win[], i: number) => {
            return (
              <div key={`carousel_item_${i}`} className="inner_carousel">
                {win?.map((w: Win, j: number) => (
                  <WinCard key={w.gameName + j} win={w} />
                ))}
              </div>
            );
          })
        )}
      </Carousel>
    </div>
  );
};
export default BL_WINs;
