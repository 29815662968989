import { LiveChatWidget } from '@livechat/widget-react';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { icons } from 'src/configs/icons';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { getHeaderConfigs } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { setMobileLiveChatVisibility } from 'src/store/mobile/actions';
import { MobileFixedMenuItem } from 'src/types/res-dto/mobile-res';
import { RootState } from 'src/types/store-types';
import { Button } from '../../../common/Button';
import './styles.scss';

type Props = {
  settings: {
    license: number;
    title: string;
    needLogin: false;
    priority?: number;
    styles?: Styles;
    classes?: string;
    iconPosition?: any;
    hasIcon?: boolean;
  };
};
const LiveChat: FC<Props> = ({ settings }) => {
  const { license, title, classes, styles, hasIcon } = settings;
  const { t }: Translation = useTranslation();

  const _setMobileLiveChatVisibility = useConnectedAction(setMobileLiveChatVisibility);

  const { showMobileLiveChat } = useSelector((state: RootState) => state.mobile);

  const [chatVisibility, setChatVisibility] = useState<'hidden' | 'maximized' | 'minimized'>('minimized');
  const [hasButton, setHasButton] = useState<boolean>(false);

  const openChat = (): void => {
    setChatVisibility('maximized');
  };

  const onVisibilityChange = (ev: any): void => {
    if (isMobileOnly) {
      setChatVisibility(ev?.visibility === 'maximized' ? 'maximized' : 'hidden');
    } else {
      const _hidden = hasButton ? 'hidden' : 'minimized';

      setChatVisibility(ev?.visibility === 'maximized' ? 'maximized' : _hidden);
    }

    _setMobileLiveChatVisibility(ev?.visibility !== 'hidden');
  };

  const getSections = async (): Promise<any> => {
    let res: MobileFixedMenuItem[] | any = null;
    if (isMobileOnly) {
      if (PersisterService.hasPersistValue('mobiledFixedMenu')) {
        res = PersisterService.getPersistValue('mobiledFixedMenu');
      }
    } else {
      if (PersisterService.hasPersistValue('desktopHeader')) {
        res = PersisterService.getPersistValue('desktopHeader');
      } else {
        const result = await getHeaderConfigs('desktop');

        PersisterService.setPersistValue('desktopHeader', result.result);
        res = result.result;
      }
    }
    return res;
  };

  useEffect(() => {
    if (isMobileOnly) {
      showMobileLiveChat ? openChat() : setChatVisibility('hidden');
    }
  }, [showMobileLiveChat]);

  useEffect(() => {
    getSections().then((res) => {
      const sections = [
        ...Object.values(res?.middleLine?.sections ?? {}),
        ...Object.values(res?.topLine?.sections ?? {}),
        ...Object.values(res?.bottomLine?.sections ?? {}),
      ];

      sections.forEach((section: any) => {
        if (Object.keys(section).includes('liveChat' || 'chat')) {
          setHasButton(true);
          setChatVisibility('hidden');
        }
      });
    });
  }, []);

  return (
    <>
      <Button
        onClick={openChat}
        className={cn('live_chat_btn', classes)}
        style={styles}
        icon={hasIcon ? icons.liveChat : ''}
        iconPosition={settings?.iconPosition || ''}
      >
        {t(title)}
      </Button>
      <LiveChatWidget
        license={license.toString()}
        visibility={chatVisibility}
        onVisibilityChanged={onVisibilityChange}
      />
    </>
  );
};
export default LiveChat;
