import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { EnumRoutePaths } from 'src/configs/routes';
import useNavigation from 'src/hooks/use-navigation';
import './styles.scss';

type Props = {
  settings: {
    needLogin: false;
    priority: number;
    styles: Styles;
    classes?: string;
  };
};
const Promotion: FC<Props> = ({ settings }) => {
  const { styles, classes } = settings;

  const navigationManager = useNavigation();
  const { t }: Translation = useTranslation();
  const { pathname } = useLocation();

  const PATH = EnumRoutePaths.PROMOTIONS;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const openPromotion = (): void => {
    navigationManager.selectPromotion();
  };

  useEffect(() => {
    setIsSelected(pathname.includes(PATH));
  }, [pathname]);

  return (
    <div className="promotion_wrapper">
      <Button
        fontWeight="bold"
        variant="contained"
        color="transparent"
        className={cn(classes, { ['selected']: isSelected })}
        onClick={openPromotion}
        icon={'/images/promos.svg'}
        style={styles}
      >
        {isDesktop && t('button_promos')}
      </Button>
    </div>
  );
};
export default Promotion;
