import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from 'src/helpers/utils';
import { getHelpCenterArticles } from 'src/services/dataApi';
import { ArticlesRes } from 'src/types/res-dto/page-res';
import HelpCenterArticles from './components/HelpCenterArticles';
import HelpCenterMainPage from './components/HelpCenterMainPage';
import HelpCenterSearchResult from './components/HelpCenterSearchResult';
import './styles.scss';

const HelpCenter: FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  const [articles, setArticles] = useState<ArticlesRes[]>([]);
  const [selectedSection, setSelectedSection] = useState<any>(null);
  const [selectedSubSection, setSelectedSubSection] = useState<any>(null);

  const changeSection = (section: any): void => {
    setSelectedSection(section);
    setSelectedSubSection({
      subsection: Object.keys(section.subCategories[0])[0],
      data: (Object.values(section.subCategories[0])[0] as any)[0],
    });

    navigate(`/helpCenter?articles&section=${section.category}`);
  };

  const changeSubsection = (subSection: any): void => {
    setSelectedSection(articles.filter((sub: any) => sub.category === subSection.section)[0]);
    setSelectedSubSection({
      subsection: subSection.title,
      data: { title: subSection.title, content: subSection.content },
    });
    navigate(`/helpCenter?articles&section=${selectedSection.category}`);
  };

  useEffect(() => {
    getHelpCenterArticles().then((res: ArticlesRes[]) => {
      setArticles(res);
      setSelectedSection(res[0]);
    });
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [search]);

  if (search.includes('section=')) {
    return (
      <HelpCenterArticles
        t={t}
        articles={articles}
        selectedSection={selectedSection}
        selectedSubSection={selectedSubSection}
        setSelectedSubSection={changeSubsection}
      />
    );
  }

  if (search.includes('query=')) {
    return <HelpCenterSearchResult t={t} articles={articles} setSelectedSubSection={changeSubsection} />;
  }

  return (
    <HelpCenterMainPage
      t={t}
      articles={articles}
      setSelectedSection={changeSection}
      setSelectedSubSection={changeSubsection}
      selectedSubSection={selectedSubSection}
    />
  );
};
export default HelpCenter;
